import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs-plugin-utc";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Pagination from "@mui/material/Pagination";
import TextField from "@mui/material/TextField";
import TableSortLabel from "@mui/material/TableSortLabel";

import ReportService from "../../services/component/Reports";
import Loader from "../../components/common/Loader";
import { validateNumber } from "../../utils/Helper";

dayjs.extend(dayjsPluginUTC);

export default function Trades() {
  const [loader, setLoader] = useState(true);
  const [trades, setTrades] = useState([]);
  const [limit, setLimit] = useState(10);
  const [active, setActive] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [download, setDownload] = useState(false);
  const [direction, setDirection] = useState("");
  const [firstRender, setFirstRender] = useState(true);

  useEffect(() => {
    const queryParam = `?page=${page}&&limit=${limit}`;
    getTrades(queryParam);
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!firstRender) {
      let queryParam = `?page=${page}`;
      if (limit) {
        queryParam = `${queryParam}&&limit=${limit}`;
      }
      if (direction) {
        queryParam = `${queryParam}&&sortBy=updatedAt:${
          direction === "asc" ? "inc" : "desc"
        }`;
      }
      getTrades(queryParam);
    }
  }, [page, limit, direction]); //eslint-disable-line react-hooks/exhaustive-deps

  const getTrades = (queryParam) => {
    setLoader(true);
    ReportService.getTrades(queryParam)
      .then((res) => {
        if (res.status === 200) {
          setLoader(false);
          setTotalPages(res?.data?.response?.trades?.totalPages);
          setTrades(res?.data?.response?.trades?.results);
          setTotalRecords(res?.data?.response?.trades?.totalResults);
          if (firstRender) {
            setFirstRender(false);
          }
        }
      })
      .catch((error) => {
        setLoader(false);
        console.log("getOpenAppError");
      });
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleLimitChange = (event) => {
    const value = event.target.value;
    if (value === "" || validateNumber(value)) {
      setLimit(value);
    }
  };

  const createSortHandler = () => {
    setActive(true);
    if (direction) {
      direction === "asc" ? setDirection("desc") : setDirection("asc");
    } else {
      setDirection("asc");
    }
  };

  const handleDownload = () => {
    try {
      let queryParam;
      setDownload(true);
      if (direction) {
        queryParam = `?sortBy=updatedAt:${
          direction === "asc" ? "inc" : "desc"
        }`;
      }
      ReportService.downloadTrades(queryParam)
        .then((res) => {
          if (res.status === 200) {
            const url = res.data.response.Location;
            const link = document.createElement("a");
            link.href = url;
            document.body.appendChild(link);
            link.click();
            setDownload(false);
          }
        })
        .catch((error) => {
          setDownload(false);
          console.log("downloadTradesError");
        });
    } catch (error) {
      setDownload(false);
      console.log("downloadTradesError,error");
    }
  };

  return (
    <>
      <div className='justify-end mb-20'>
        <button
          className='btn btn-success'
          disabled={download}
          onClick={handleDownload}
        >
          {download ? "Loading..." : "Download"}
        </button>
      </div>

      <div className='table-cover'>
        <Table aria-label='simple table' stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell> User ID</TableCell>
              <TableCell>Trade Event ID</TableCell>
              <TableCell>Trade Initiator</TableCell>
              <TableCell>
                Trade Execute Timestamp
                <TableSortLabel
                  active={active}
                  direction={direction || "asc"}
                  onClick={createSortHandler}
                  className='sorting-icon'
                ></TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!loader &&
              trades.length > 0 &&
              trades.map((trade, index) => (
                <TableRow
                  key={`${trade._id}-${index}`}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>
                    {trade?.type === 3 ? trade?.tradedBy : trade?.tradedWith}
                  </TableCell>
                  <TableCell>{trade?._id}</TableCell>
                  <TableCell>
                    {trade?.type === 3 ? trade?.tradedWith : trade?.tradedBy}
                  </TableCell>
                  <TableCell>
                    {trade?.updatedAt
                      ? dayjs(trade.updatedAt).format("YYYY-MM-DDTHH:mm:ssZ")
                      : "-"}
                  </TableCell>
                </TableRow>
              ))}

            {!loader && trades.length === 0 && (
              <TableRow>
                <TableCell
                  colSpan={9}
                  style={{
                    padding: "120px 0px",
                    color: "#333",
                    fontSize: "24px",
                  }}
                >
                  No Records Found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {loader && <Loader />}
      </div>
      <div className='pagination-cover'>
        <Pagination
          count={totalPages}
          variant='outlined'
          shape='rounded'
          onChange={handlePageChange}
        />
        <TextField
          name='limit'
          variant='standard'
          value={limit}
          onChange={handleLimitChange}
          style={{ width: "80px", margin: "0px 20px" }}
        />
        <h3>
          {trades?.length !== 0 ? trades?.length : "0"} of {totalRecords}
        </h3>
      </div>
    </>
  );
}
