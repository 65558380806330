import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import BrokerListing from "./broker-listing/BrokerListing";
import TotalComps from "./total-comps/TotalComps";
import BrokerDetail from "./broker-detail/BrokerDetail";
import AddBroker from "./add-broker/AddBroker";
import TradeListing from "./trade-completed/trade-listing/TradeListing";

export default function BrokerRoute() {
  return (
    <Routes>
      <Route path="/" element={<BrokerListing />} />
      <Route exact path="/:id/total-comps" element={<TotalComps />} />
      <Route exact path="/:id/trade-completed" element={<TradeListing />} />
      <Route exact path="/:id" element={<BrokerDetail />} />
      <Route path="/add-broker" element={<AddBroker />} />
      <Route path="*" element={<Navigate to="/notfound" replace />} />
    </Routes>
  );
}
