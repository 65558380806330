import React, { useState, useEffect } from "react";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";
import { toast } from "react-toastify";
import FilterListIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Pagination from "@mui/material/Pagination";
import Dialog from "@mui/material/Dialog";
import FormControlLabel from "@mui/material/FormControlLabel";

import "../../Listing/listing.css";
import BrokerService from "../../../services/component/Broker";
import BrokerFilter from "../broker-filter/BrokerFilter";
import { useNavigate } from "react-router-dom";
import Loader from "./../../../components/common/Loader";
import { validateNumber, valideWhiteSpace } from "../../../utils/Helper";
import { brokerStatus } from "../../../utils/SwitchCase";

export default function BrokerListing() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState(true);
  const [brokers, setBrokers] = useState([]);
  const [limit, setLimit] = useState(10);
  const [query, setQuery] = useState("");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);

  const [company, setCompany] = useState("");
  const [address, setAddress] = useState("");
  const [status, setStatus] = useState(0);
  const [approved, setApproved] = useState(false);

  useEffect(() => {
    let queryParam = `?page=${page}&&limit=${limit}`;
    getBrokers(queryParam);
    getApprovalStatus();
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setQueryParams();
  }, [query]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setQueryParams();
  }, [limit]); //eslint-disable-line react-hooks/exhaustive-deps

  const setQueryParams = () => {
    let queryParam = `?page=${page}&&limit=${limit}&&search=${query}`;
    if (query) {
      getBrokers(queryParam);
    } else {
      queryParam = `?page=${page}&&limit=${limit}`;
      getBrokers(queryParam);
    }
  };

  const getApprovalStatus = () => {
    BrokerService.getApprovalStatus()
      .then((res) => {
        if (res.status === 200) {
          setApproved(res.data.response.approvalStatus);
        }
      })
      .catch((error) => {
        setLoader(false);
        console.log("getApprovalStatus");
      });
  };

  const getBrokers = (queryParam) => {
    setLoader(true);

    if (company) {
      queryParam = queryParam + `&&companyName=${company}`;
    }

    if (address) {
      queryParam = queryParam + `&&fullAddress=${address}`;
    }

    if (status !== 0) {
      queryParam = queryParam + `&&approvalStatus=${status}`;
    }

    BrokerService.getBrokerList(queryParam)
      .then((res) => {
        if (res.status === 200) {
          setLoader(false);
          setTotalPages(res?.data?.response?.totalPages);
          setBrokers(res.data.response.results);
          setTotalRecords(res?.data?.response?.totalResults);
        }
      })
      .catch((error) => {
        setLoader(false);
        console.log("getBrokersError");
      });
  };

  const getFilteredBrokers = (value) => {
    setLoader(true);
    BrokerService.getBrokerList(value, limit, query)
      .then((res) => {
        if (res.status === 200) {
          setTotalPages(res?.data?.response?.totalPages);
          setTotalRecords(res?.data?.response?.totalResults);
          setLoader(false);
          setBrokers(res.data.response.results);
        }
      })
      .catch((error) => {
        setLoader(false);
        console.log("getFilteredBrokersError");
      });
  };

  const handleApproved = (e) => {
    const status = e.target.checked ? 1 : 0;
    BrokerService.addApprovalStatus({ status: status })
      .then((res) => {
        if (res.status === 200) {
          toast.success(res.data.message);
          setApproved(status);
        }
      })
      .catch((error) => {
        setLoader(false);
        console.log("addApprovalStatus");
      });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
    let queryParam = "";

    if (query) {
      queryParam = `?page=${value}&&limit=${limit}&&search=${query}`;
      getBrokers(queryParam);
    } else {
      queryParam = `?page=${value}&&limit=${limit}`;
      getBrokers(queryParam);
    }
  };

  const handleLimitChange = (event) => {
    const value = event.target.value;
    if (value === "" || validateNumber(value)) {
      setLimit(value);
    }
  };

  const handleClear = () => {
    setCompany("");
    setStatus(0);
    setAddress("");
    //setType(0);
    handleClose();

    let queryParam = `?page=${page}&&limit=${limit}`;
    if (query) {
      queryParam = `?page=${page}&&limit=${limit}&&search=${query}`;
    }
    getFilteredBrokers(queryParam);
  };

  const handleQuery = (event) => {
    const { value } = event.target;
    if (!valideWhiteSpace(value)) {
      setQuery(value);
    }
  };

  const handleSearch = () => {
    setQuery("");
  };

  const goToBrokerDetail = (id) => {
    navigate(`../${id}`);
  };

  const handleAddBroker = () => {
    // navigate(`../add-broker?approved=${approved}`);
    navigate(`../add-broker`);
  };

  const handleFilter = (values) => {
    setCompany(values.company);
    setStatus(values.status);
    //setType(values.type);
    setAddress(values.address);

    let queryParam = `?page=${page}&&limit=${limit}`;
    if (query) {
      queryParam = `?page=${page}&&limit=${limit}&&search=${query}`;
    }
    if (values.company) {
      queryParam = queryParam + `&&companyName=${values.company}`;
    }
    if (values.address) {
      queryParam = queryParam + `&&fullAddress=${values.address}`;
    }

    if (values.status !== 0) {
      queryParam = queryParam + `&&approvalStatus=${values.status}`;
    }

    getFilteredBrokers(queryParam);
  };

  return (
    <div className="page-listing">
      <div className="space-between">
        <h1 className="bold">Broker Management</h1>
        <button className="btn btn-primary" onClick={handleAddBroker}>
          Add
        </button>
      </div>
      <div className="listing-filter space-between mt-20 mb-20">
        <div className="justify-start">
          <div className="search mr-20">
            <TextField
              className="search-box"
              fullWidth
              id="outlined-search"
              type="search"
              onChange={handleQuery}
              placeholder="Search by Name"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon
                      className="search-icon pointer"
                      onClick={handleSearch}
                    ></SearchIcon>
                  </InputAdornment>
                ),
              }}
            />
          </div>

          <FormControlLabel
            value="start"
            control={<Switch color="primary" checked={approved} />}
            label={<p>Manual</p>}
            onChange={handleApproved}
            labelPlacement="start"
          />
          <p style={{ paddingLeft: "10px" }}> User Approval (Auto)</p>
        </div>
        <button
          className="btn btn-primary btn-filter"
          onClick={handleClickOpen}
        >
          <FilterListIcon fontSize="large" />
        </button>
      </div>

      <div className="table-cover">
        <Table aria-label="simple table" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Mobile Number</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Company</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!loader &&
              brokers &&
              brokers.length > 0 &&
              brokers.map((broker, index) => (
                <TableRow
                  key={broker._id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  onClick={() => goToBrokerDetail(broker._id)}
                >
                  {/* <TableCell>{serialNumber(index + 1, page, limit)}</TableCell> */}
                  <TableCell>{broker?._id || ""}</TableCell>
                  <TableCell>{broker?.name || ""}</TableCell>
                  <TableCell>{broker?.mobile || ""}</TableCell>
                  <TableCell>{broker?.email || ""}</TableCell>
                  <TableCell>{broker?.companyName || ""}</TableCell>
                  <TableCell>
                    {broker?.approvalStatus
                      ? brokerStatus(broker.approvalStatus)
                      : ""}
                  </TableCell>
                </TableRow>
              ))}

            {!loader && brokers && brokers.length === 0 && (
              <TableRow>
                <TableCell
                  colSpan={9}
                  style={{
                    padding: "120px 0px",
                    color: "#333",
                    fontSize: "24px",
                  }}
                >
                  No Records Found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {loader && <Loader />}
      </div>
      <div className="pagination-cover">
        <Pagination
          count={totalPages}
          variant="outlined"
          shape="rounded"
          onChange={handlePageChange}
        />
        <TextField
          name="limit"
          variant="standard"
          value={limit}
          onChange={handleLimitChange}
          style={{ width: "80px", margin: "0px 20px" }}
        />
        <h3>
          {brokers && brokers?.length !== 0 ? brokers?.length : "0"} of{" "}
          {totalRecords}
        </h3>
      </div>

      <Dialog open={open}>
        <BrokerFilter
          handleFilter={handleFilter}
          company={company}
          status={status}
          address={address}
          handleClose={handleClose}
          handleClear={handleClear}
        />
      </Dialog>
    </div>
  );
}
