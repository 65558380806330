import React, { useState } from "react";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import CloseIcon from "@mui/icons-material/Close";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import "../../Listing/Filter/filter.css";

export default function CompFilter({
  handleFilter,
  handleClose,
  handleClear,
  category,
  status,
  property,
  level,
  type,
  show,
}) {
  const [formValues, setFormValues] = useState({
    category: category,
    property: property,
    level: level,
    type: type,
    status: status,
  });

  const handleChange = (e) => {
    const { value, name } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleApply = () => {
    handleFilter(formValues);
    handleClose();
  };
  const handleClearButton = () => {
    setFormValues({
      category: 0,
      property: 0,
      level: 0,
      type: 0,
      status: 0,
    });
    handleClear();
  };

  return (
    <div className="filter-cover">
      <div className="filter-header">
        <h2>Filter</h2>
        <CloseIcon className="close-icon" title="Close" onClick={handleClose} />
      </div>
      <div className="filter-body">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <h4 className="subtext mb-5">Category</h4>
            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="category"
                value={formValues?.category}
                onChange={handleChange}
              >
                <MenuItem value={0}>ALL</MenuItem>
                <MenuItem value={1}>LEASE</MenuItem>
                <MenuItem value={2}>SALE</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <h4 className="subtext mb-5">Status</h4>
            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                name="status"
                value={formValues?.status}
                onChange={handleChange}
              >
                <MenuItem value={0}>ALL</MenuItem>
                <MenuItem value={1}>UNLOCKED/ACTIVE</MenuItem>
                <MenuItem value={2}>LOCKED/ACTIVE</MenuItem>
                <MenuItem value={3}>UNLOCKED/UNACTIVE</MenuItem>
                <MenuItem value={4}>LOCKED/UNACTIVE</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <h4 className="subtext mb-5">Property Type</h4>
            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="property"
                value={formValues?.property}
                onChange={handleChange}
              >
                <MenuItem value={0} selected>
                  ALL
                </MenuItem>
                <MenuItem value={1}>OFFICE</MenuItem>
                <MenuItem value={2}>INDUSTRIAL</MenuItem>
                <MenuItem value={3}>RETAIL</MenuItem>
                <MenuItem value={4}>MEDICAL</MenuItem>
                <MenuItem value={5}>FLEX</MenuItem>
                <MenuItem value={6}>LAND</MenuItem>
                <MenuItem value={5}>MULTI-FAMILY</MenuItem>
                <MenuItem value={6}>OTHER</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <h4 className="subtext mb-5">Level</h4>
            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="level"
                value={formValues?.level}
                onChange={handleChange}
              >
                <MenuItem value={0} selected>
                  ALL
                </MenuItem>
                <MenuItem value={1}>GOLD</MenuItem>
                <MenuItem value={2}>SILVER</MenuItem>
                <MenuItem value={3}>BRONZE</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {show && (
            <Grid item xs={12}>
              <h4 className="subtext mb-5"> Type</h4>
              <FormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="type"
                  value={formValues?.type}
                  onChange={handleChange}
                >
                  <MenuItem value={0} selected>
                    ALL
                  </MenuItem>
                  <MenuItem value={1}>TRADABLE</MenuItem>
                  <MenuItem value={2}>NON-TRADABLE</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          )}
        </Grid>
      </div>
      <div className="filter-footer">
        <button
          className="btn btn-primary-border mr-30"
          onClick={handleClearButton}
        >
          Clear All
        </button>
        <button className="btn btn-primary" onClick={handleApply}>
          Apply
        </button>
      </div>
    </div>
  );
}
