import { currencyFormat } from "./Helper";

const brokerStatus = (value) => {
  let status = "";
  if (value) {
    switch (value) {
      case 1:
        status = "Pending";
        break;
      case 2:
        status = "Approved";
        break;
      default:
        status = "Rejected";
    }
    return status;
  }
};

const recipientStatus = (value) => {
  let status = "";
  if (value) {
    switch (value) {
      case 1:
        status = "All Users";
        break;
      case 2:
        status = "iOS";
        break;
      case 3:
        status = "Android";
        break;
      default:
        status = "Selected Users";
    }
    return status;
  }
};

const tradeType = (value) => {
  let status = "";
  if (value) {
    switch (value) {
      case 1:
        status = "Request";
        break;
      default:
        status = "Trade";
    }
    return status;
  }
};

const propertyType = (value) => {
  let type = "";
  if (value) {
    switch (value) {
      case 1:
        type = "OFFICE";
        break;
      case 2:
        type = "INDUSTRIAL";
        break;
      case 3:
        type = "RETAIL";
        break;
      case 4:
        type = "MEDICAL";
        break;
      case 5:
        type = "FLEX";
        break;
      case 6:
        type = "LAND";
        break;
      case 7:
        type = "MULTI-FAMILY";
        break;

      default:
        type = "OTHER";
    }
    return type;
  }
};

const levelType = (value) => {
  let level = "";
  if (value) {
    switch (value) {
      case 1:
        level = "Gold";
        break;
      case 2:
        level = "Silver";
        break;
      default:
        level = "Bronze";
    }
    return level;
  }
};
const rentType = (value) => {
  let type = "";
  if (value) {
    switch (value) {
      case 1:
        type = "FS";
        break;
      case 2:
        type = "NNN";
        break;
      default:
        type = "MG";
    }
    return type;
  }
};

const escalationUnit = (value) => {
  let type = "";
  if (value) {
    switch (value) {
      case 1:
        type = "MONTH";
        break;
      default:
        type = "YEAR";
    }
    return type;
  }
};

// const tisUnit = (value) => {
//   let type = "";
//   if (value) {
//     switch (value) {
//       case 1:
//         type = "SQFT";
//         break;

//       default:
//         type = "TURN KEY";
//     }
//     return type;
//   }
// };
const tisUnit = (comp) => {
  if (comp?.TISUnit) {
    switch (comp?.TISUnit) {
      case 1:
        return `$ ${currencyFormat(comp?.TIS)} / SQFT`;
      case 2:
        return "Turn Key";
      default:
        return "$ 0 / SF";
    }
  }
};

const leaseType = (value) => {
  let type = "";
  if (value) {
    switch (value) {
      case 1:
        type = "DIRECT";
        break;

      case 2:
        type = "SUBLEASE";
        break;
      case 3:
        type = "RENEW";
        break;
      case 4:
        type = "EXPANSION";
        break;

      default:
        type = "DOWN SIZE";
    }
    return type;
  }
};

export {
  brokerStatus,
  recipientStatus,
  tradeType,
  propertyType,
  escalationUnit,
  tisUnit,
  levelType,
  rentType,
  leaseType,
};
