import React from "react";
import {
  useNavigate,
  Navigate,
  Routes,
  Route,
  Link,
  useLocation,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./layout.css";
import logo from "../../assets/images/LIVIA.svg";
import profile from "../../assets/images/profile.svg";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import NotificationsIcon from "@mui/icons-material/Notifications";
import CastForEducationIcon from "@mui/icons-material/CastForEducation";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import DomainIcon from "@mui/icons-material/Domain";
import GroupIcon from "@mui/icons-material/Group";
import Dashboard from "../dashboard/Dashboard";
import LogoutIcon from "@mui/icons-material/Logout";
import Comp from "../comp/index";
import Broker from "../broker/index";
import Notification from "../notification/index";
import Subscription from "../subscription/index";
import Trade from "../trade-management/index";
import Coupon from "../coupon/index";
import Reports from "../../modules/reports/Reports";
import Profile from "../profile/Profile";
import { CONFIG } from "../../utils/Constant";
import { userSignOut } from "../../redux/Action";
import { addDefaultSrc } from "../../utils/Helper";

export default function Layout() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.auth?.userInfo);
  const path = location?.pathname?.split("/")[2] || "";

  const handleLogOut = () => {
    const token = localStorage.getItem("tokenInfo");
    if (token) {
      localStorage.clear();
      dispatch(userSignOut());
    }
    navigate("../../");
  };

  return (
    <>
      <header>
        <div className="website-logo">
          <figure>
            <img src={logo} className="websiteLogo" alt="website-logo" />
          </figure>
        </div>
        <div className="right-header">
          <div className="profile-detail">
            <Link to="/admin/profile">
              {" "}
              <img
                onError={(e) => addDefaultSrc(e, profile)}
                src={
                  !user?.profilePic ? profile : CONFIG.S3_URL + user?.profilePic
                }
                alt="profile-img"
                className="profile-icon"
                title="My Profile"
              />
              {/* <img src={profile} className="websiteLogo" alt="profile" /> */}
            </Link>
          </div>
          <div className="logout" onClick={handleLogOut}>
            <LogoutIcon></LogoutIcon> &nbsp;
            <h2>Logout</h2>
          </div>
        </div>
      </header>

      <div className="main-wrapper">
        <aside>
          <ul>
            <li className={path === "dashboard" ? "active" : ""}>
              <Link to="dashboard">
                <DashboardIcon className="mr-20" />
                View Dashboard
              </Link>
            </li>
            <li className={path === "broker" ? "active" : ""}>
              <Link to="broker">
                <GroupIcon className="mr-20" />
                Broker Management
              </Link>
            </li>
            <li className={path === "comp" ? "active" : ""}>
              <Link to="comp">
                <DomainIcon className="mr-20" />
                Comp Management
              </Link>
            </li>
            <li className={path === "notification" ? "active" : ""}>
              <Link to="notification">
                <NotificationsIcon className="mr-20" />
                Notification Management
              </Link>
            </li>
            <li className={path === "trade" ? "active" : ""}>
              <Link to="trade">
                <CastForEducationIcon className="mr-20" />
                Trade Management
              </Link>
            </li>
            <li className={path === "reports" ? "active" : ""}>
              <Link to="reports">
                <AnalyticsIcon className="mr-20"></AnalyticsIcon>
                Reports and Analytic
              </Link>
            </li>
            <li className={path === "subscription" ? "active" : ""}>
              <Link to="subscription">
                <SubscriptionsIcon className="mr-20"></SubscriptionsIcon>
                Subscription Management
              </Link>
            </li>
            <li className={path === "coupon" ? "active" : ""}>
              <Link to="coupon">
                <SubscriptionsIcon className="mr-20"></SubscriptionsIcon>
                Coupon Management
              </Link>
            </li>

            <li className="disable">
              <a href="javascript:void(0)">
                <CurrencyExchangeIcon className="mr-20" />
                Earning Management
              </a>
            </li>
          </ul>
        </aside>

        <main>
          <div className="main-content">
            <Routes>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="comp/*" element={<Comp />} />
              <Route path="broker/*" element={<Broker />} />
              <Route path="notification/*" element={<Notification />} />
              <Route path="trade/*" element={<Trade />} />
              <Route path="reports" element={<Reports />} />
              <Route path="subscription/*" element={<Subscription />} />
              <Route path="coupon/*" element={<Coupon />} />
              <Route path="*" element={<Navigate to="/notfound" replace />} />
            </Routes>
          </div>
        </main>
      </div>
    </>
  );
}
