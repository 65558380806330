import React, { useState } from "react";
import OpenApp from "./OpenApp";
import UserInfo from "./UserInfo";
import Trade from "./Trade";
import Comp from "./Comp";
import "../reports/reports.css";

export default function AddNotification() {
  const [appTab, setAppTab] = useState(false);
  const [userTab, setUserTab] = useState(false);
  const [tradeTab, setTradeTab] = useState(false);
  const [compTab, setCompTab] = useState(false);

  const handleAppTab = () => {
    setAppTab(true);
    setUserTab(false);
    setTradeTab(false);
    setCompTab(false);
  };

  const handleUserTab = () => {
    setUserTab(true);
    setAppTab(false);
    setTradeTab(false);
    setCompTab(false);
  };

  const handleTradeTab = () => {
    setTradeTab(true);
    setUserTab(false);
    setAppTab(false);
    setCompTab(false);
  };

  const handleCompTab = () => {
    setCompTab(true);
    setTradeTab(false);
    setUserTab(false);
    setAppTab(false);
  };

  return (
    <div className="page-reports-analytics">
      <div className="space-between">
        <button
          className={`btn ${appTab ? "btn-primary" : ""}`}
          onClick={handleAppTab}
       //   title={"App Opens"}
        >
          App Opens
        </button>
        <button
          className={`btn ${userTab ? "btn-primary" : ""}`}
          onClick={handleUserTab}
        >
          User Info
        </button>
        <button
          className={`btn ${tradeTab ? "btn-primary" : ""}`}
          onClick={handleTradeTab}
        >
          Trades
        </button>
        <button
          className={`btn ${compTab ? "btn-primary" : ""}`}
          onClick={handleCompTab}
        >
          Comps
        </button>
      </div>

      {appTab && <OpenApp />}
      {userTab && <UserInfo />}
      {tradeTab && <Trade />}
      {compTab && <Comp />}
    </div>
  );
}
