import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import InputAdornment from "@mui/material/InputAdornment";
import FilterListIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import Pagination from "@mui/material/Pagination";

import Loader from "../../../components/common/Loader";
import { brokerStatus } from "../../../utils/SwitchCase";

export default function BrokerList({
  query,
  page,
  limit,
  totalPages,
  loderExist,
  totalRecords,
  handleQuery,
  handleClearQuery,
  handleClickOpen,
  users,
  loader,
  brokers,
  handleUserChange,
  handleLimitChange,
  handlePageChange,
}) {
  return (
    <div className="notification-table">
      <div className="listing-filter space-between mt-20 mb-20">
        <div className="justify-start">
          <div className="search mr-20">
            <TextField
              className="search-box"
              fullWidth
              id="outlined-search"
              type="search"
              value={query}
              onChange={handleQuery}
              placeholder="Search by Name"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon
                      className="search-icon pointer"
                      onClick={handleClearQuery}
                    ></SearchIcon>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div>
        <button
          className="btn btn-primary btn-filter"
          onClick={handleClickOpen}
        >
          <FilterListIcon fontSize="large" />
        </button>
      </div>

      <div className="table-cover">
        <Table aria-label="simple table" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell
                style={{ width: "70px", minWidth: "70px" }}
              ></TableCell>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Mobile Number</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Company</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!loader &&
              users &&
              users.length > 0 &&
              users.map((user, index) => (
                <>
                  <TableRow
                    key={user._id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell style={{ width: "70px", minWidth: "70px" }}>
                      <input
                        type="Checkbox"
                        onChange={(e) => handleUserChange(e, user._id)}
                        style={{
                          width: "20px",
                          height: "20px",
                          cursor: "pointer",
                        }}
                        checked={
                          brokers &&
                          brokers.length > 0 &&
                          brokers.includes(user._id)
                        }
                      />
                    </TableCell>
                    <TableCell>{user?._id || ""}</TableCell>
                    <TableCell>{user?.name || ""}</TableCell>
                    <TableCell>{user?.mobile || ""}</TableCell>
                    <TableCell>{user?.email || ""}</TableCell>
                    <TableCell>{user?.companyName || ""}</TableCell>
                    <TableCell>
                      {user?.approvalStatus
                        ? brokerStatus(user.approvalStatus)
                        : ""}
                    </TableCell>
                  </TableRow>
                </>
              ))}

            {!loader && users && users.length === 0 && (
              <TableRow>
                <TableCell
                  colSpan={9}
                  style={{
                    padding: "120px 0px",
                    color: "#333",
                    fontSize: "24px",
                  }}
                >
                  No Records Found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>

        {loderExist && loader && <Loader />}
      </div>
      <div className="pagination-cover">
        <Pagination
          count={totalPages}
          variant="outlined"
          shape="rounded"
          onChange={handlePageChange}
        />
        <TextField
          name="limit"
          variant="standard"
          value={limit}
          onChange={handleLimitChange}
          style={{ width: "80px", margin: "0px 20px" }}
        />
        <h3>
          {users && users?.length !== 0 ? users?.length : "0"} of {totalRecords}
        </h3>
      </div>
    </div>
  );
}
