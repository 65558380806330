import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import SubscriptionListing from "./subscription-listing/SubscriptionListing";
import AddSubscription from "./add-subscription/AddSubscription";
import SubscriptionDetail from "./subscription-detail/SubscriptionDetail";
import EditSubscription from "./edit-subscription/EditSubscription";

export default function SubscriptionRoute() {
  return (
    <Routes>
      <Route path="/" element={<SubscriptionListing />} />
      <Route exact path="/:id" element={<SubscriptionDetail />} />
      <Route exact path="/edit/:id" element={<EditSubscription />} />
      <Route path="/add-subscription" element={<AddSubscription />} />
      <Route path="*" element={<Navigate to="/notfound" replace />} />
    </Routes>
  );
}
