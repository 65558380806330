import { httpClient } from "../../utils/Apis";
import { DASHBOARD } from "../Constant";

class DashboardService {
  getDashboard() {
    return httpClient.get(DASHBOARD.GET_DASHBOARD);
  }
}

export default new DashboardService();
