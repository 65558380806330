export const AUTH = {
  SIGNIN: "/admin/login",
  FORGOT_PASSWORD: "/admin/forgot-password",
  RESET_PASSWORD: "/admin/reset-password?token={token}",
  REFRESH_TOKEN: "/auth/refresh-tokens",
  CHANGE_PASSWORD: "/users/change-password",
};

export const COMP = {
  COMP_LIST: `/admin/comps`,
  COMPS_BY_BROKERID: `/users/{userId}/comps`,
  GET_COMP: `admin/comps/{compId}`,
  GET_COMPS_BY_LOCATION: `/comps/maps/{location}`,
  GET_TRADED_COMPS: `/users/{userId}/comps/traded`,
  ACTIVE_COMP: `/admin/comp/approve`,
  CSV_UPLOAD: `admin/comps/csv`,
};

export const BROKER = {
  BROKER_LIST: `/users`,
  GET_BROKER: `/users/details/{userId}`,
  ADD_BROKER: `/admin/user`,
  USER_BLOCK: `/admin/user/block`,
  USER_STATUS: `/admin/user/approve`,
  APPROVAL_STATUS: "admin/broker/approval-status",
};

export const NOTIFICATION = {
  GET_NOTIFICATION: `/admin/notification/{id}`,
  GET_NOTIFICATION_USERS: `/admin/notification/users`,
  GET_NOTIFICATIONS: `/admin/notification`,
  ADD_NOTIFICATION: `/admin/notification`,
};

export const TRADE = {
  GET_TRADES: `/trades`,
  GET_TRADES_COMPLETED: "/trades/users/{userId}",
  GET_TRADES_BY_COMPID: "/trades/comps/{compId}",
};

export const DASHBOARD = {
  GET_DASHBOARD: `/admin/dashboard`,
};

export const REPORTS = {
  GET_OPEN_APP: `/admin/report/open-app`,
  GET_USERS: `/admin/report/users`,
  GET_TRADES: `/admin/report/trades`,
  GET_COMPS: `/admin/report/comps`,

  DOWNLOAD_OPEN_APP: `/admin/report/open-app/download`,
  DOWNLOAD_USER_INFO: `/admin/report/users/download`,
  DOWNLOAD_TRADES: `/admin/report/trades/download`,
  DOWNLOAD_COMPS: `/admin/report/comps/download`,
};

export const SUBSCRIPTION = {
  GET_SUBSCRIPTION_LIST: `/subscription/plans`,
  GET_SUBSCRIPTION: `/subscription/plans/{id}`,
  UPDATE_SUBSCRIPTION: `/subscription/plans/{id}`,
  GENERATE_SUBSCRIPTION_COUPON: "/subscription/coupon/generate",
  SUBSCRIPTION_COUPON_LIST: "/subscription/coupon/list",
};
