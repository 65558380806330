import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { toast } from "react-toastify";
//import InputAdornment from "@mui/material/InputAdornment";

import Loader from "../../../components/common/Loader";
import "../edit-subscription/edit-subscription.css";
import backIcon from "../../../assets/images/back-icon.svg";
import { valideWhiteSpace } from "../../../utils/Helper";
import SubscriptionService from "../../../services/component/Subscription";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

export default function EditSubscription() {
  const navigate = useNavigate();
  const params = useParams();

  const [loader, setLoader] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [formValues, setFormValues] = useState({
    _id: "",
    planName: "",
    planDuration: "",
    price: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [inputList, setInputList] = useState([{ benefit: "" }]);
  const [fieldSetErrors, setFieldSetErrors] = useState([]);
  useEffect(() => {
    if (formValues) {
      if (
        Object.values(formValues).every((x) => x) &&
        Object.values(inputList).every((x) => x.benefit) &&
        Object.values(formErrors).every((x) => !x)
      ) {
        setDisabled(false);
      }
    }
  }, [formErrors, formValues, inputList]);

  useEffect(() => {
    getPlan();
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  const getPlan = () => {
    setLoader(true);
    SubscriptionService.getSubscription(params?.id)
      .then((res) => {
        if (res.status === 201) {
          const result = res?.data?.response;
          const { _id, name, duration, price } = result;
          setLoader(false);
          setFormValues({
            _id,
            planName: name,
            planDuration: duration,
            price,
          });

          const valArr = result?.benefits.map((str) => {
            return {
              benefit: str,
            };
          });
          const errorArr = result?.benefits.map(() => {
            return {
              benefit: null,
            };
          });

          setInputList(valArr);
          setFieldSetErrors(errorArr);
        }
      })
      .catch((error) => {
        setLoader(false);
        toast.error("somethning went wrong");
        navigate(`/admin/subscription`);
        console.log("getPlanError", error);
      });
  };

  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    if (!valideWhiteSpace(value)) {
      const list = [...inputList];
      list[index][name] = value;
      setInputList(list);
      validateFieldSet(index, value, name);
    }
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);

    const values = [...fieldSetErrors];
    values.splice(index, 1);
    setFieldSetErrors(values);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setDisabled(true);
    setInputList([...inputList, { benefit: "" }]);
    setFieldSetErrors([...fieldSetErrors, { benefit: null }]);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (!valideWhiteSpace(value)) {
      setFormValues({ ...formValues, [name]: value });
      validateField(value, name);
    }
  };

  const validateField = (value, name) => {
    if (!Boolean(value)) {
      setDisabled(true);
      setFormErrors({
        ...formErrors,
        [name]: "Required Field",
      });
    } else {
      setFormErrors({ ...formErrors, [name]: "" });
    }
  };

  const validateFieldSet = (index, value, name) => {
    if (!Boolean(value)) {
      setDisabled(true);
      const values = [...fieldSetErrors];
      values[index][name] = "Required";
      setFieldSetErrors(values);
    } else {
      const values = [...fieldSetErrors];
      values[index][name] = null;
      setFieldSetErrors(values);
    }
  };

  const handleSubmit = () => {
    const { _id, planName } = formValues;
    const benefits = inputList.map((x) => x.benefit);

    const body = {
      name: planName,
      benefits: benefits,
    };
    setLoader(true);

    SubscriptionService.updateSubscription(_id, body)
      .then((res) => {
        setLoader(false);
        navigate("../");
        toast.success(res.data.message);
      })
      .catch((error) => {
        setLoader(false);
        toast.error(error?.response?.data?.message);
      });
  };

  const handleBack = () => {
    navigate(`/admin/subscription`);
  };
  return (
    <div className='dashboard-container'>
      {loader && <Loader />}
      <div className='dashboard-title back-link mb-20'>
        <img src={backIcon} alt='back-icon' onClick={handleBack} />
        <h2>Edit Subscription</h2>
      </div>

      <div className='formItem add-details-info add-subs'>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <h3 className='required'>Name</h3>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={8}>
            <TextField
              fullWidth
              name='planName'
              value={formValues.planName || ""}
              onChange={handleChange}
              variant='outlined'
              label='Enter Name'
              inputProps={{
                maxLength: 40,
              }}
              error={Boolean(formErrors?.planName)}
              helperText={formErrors?.planName}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={4}>
            <h3>Tenure</h3>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={8}>
            <TextField
              fullWidth
              disabled
              name='planDuration'
              value={formValues.planDuration || ""}
              variant='outlined'
              label='Enter Tenure'
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={4}>
            <h3>Total Price ($)</h3>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={8}>
            <TextField
              fullWidth
              name='price'
              disabled
              value={formValues.price || ""}
              variant='outlined'
              label='Enter Price'
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <h3 className='required'>Description</h3>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={8}>
            <div className='add-link-cover'>
              <p
                className='underline bold flex-end'
                title='Add Description'
                onClick={handleAddClick}
              >
                <AddCircleOutlineIcon /> &nbsp; Add
              </p>
            </div>
            <div className='item'>
              {inputList.map((x, i) => {
                return (
                  <div className='text-plus-button space-between'>
                    <TextField
                      fullWidth
                      name='benefit'
                      value={x.benefit}
                      onChange={(e) => handleInputChange(e, i)}
                      variant='outlined'
                      label='Enter Description'
                      inputProps={{
                        maxLength: 100,
                      }}
                      error={Boolean(fieldSetErrors[i]?.benefit)}
                      helperText={fieldSetErrors[i]?.benefit}
                    />

                    <button
                      className='btn btn-danger'
                      disabled={inputList.length === 1}
                      onClick={() => handleRemoveClick(i)}
                      title='Remove'
                    >
                      <RemoveCircleOutlineIcon />
                    </button>
                  </div>
                );
              })}
            </div>
          </Grid>
        </Grid>
      </div>
      <div className='dashboard-btn'>
        <button
          className='btn btn-primary'
          disabled={disabled}
          onClick={handleSubmit}
        >
          Save
        </button>
      </div>
    </div>
  );
}
