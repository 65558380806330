import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs-plugin-utc";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import FilterListIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Pagination from "@mui/material/Pagination";
import Dialog from "@mui/material/Dialog";

import TradeService from "../../../../services/component/Trade";
import TradeFilter from "../../../trade-management/trade-filter/TradeFilter";
import Loader from "../../../../components/common/Loader";
import {
  validateNumber,

  valideWhiteSpace,
} from "../../../../utils/Helper";
import { tradeType } from "../../../../utils/SwitchCase";
import backIcon from "../../../../assets/images/back-icon.svg";
import "../../../Listing/listing.css";
dayjs.extend(dayjsPluginUTC);

export default function TradeListing() {
  const navigate = useNavigate();
  const params = useParams();
  const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState(true);
  const [comps, setComps] = useState([]);
  const [limit, setLimit] = useState(10);
  const [query, setQuery] = useState("");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);

  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);

  const [type, setType] = useState(0);

  useEffect(() => {
    let queryParam = `?page=${page}&&limit=${limit}`;
    getTradedComps(queryParam);
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setQueryParams();
  }, [limit, query]); //eslint-disable-line react-hooks/exhaustive-deps

  const setQueryParams = () => {
    let queryParam = `?page=${page}&&limit=${limit}&&search=${query}`;
    if (query) {
      getTradedComps(queryParam);
    } else {
      queryParam = `?page=${page}&&limit=${limit}`;
      getTradedComps(queryParam);
    }
  };

  const getTradedComps = (queryParam) => {
    setLoader(true);

    if (to && from) {
      const fromDate = dayjs(from).format("MM/DD/YYYY");
      const toDate = dayjs(to).format("MM/DD/YYYY");
      queryParam =
        queryParam + `&&tradedDateMin=${fromDate}&&tradedDateMax${toDate}`;
    }
    if (type !== 0) {
      queryParam = queryParam + `&&type=${type}`;
    }

    TradeService.getTradesByCompId(params.id, queryParam)
      .then((res) => {
        if (res.status === 200) {
          setLoader(false);
          setTotalPages(res?.data?.response?.totalPages);
          setComps(res.data.response.results);
          setTotalRecords(res?.data?.response?.totalResults);
        }
      })
      .catch((error) => {
        setLoader(false);
        console.log("getTadeCompletedComps");
      });
  };

  const getFilteredComps = (value) => {
    setLoader(true);
    TradeService.getTradesByCompId(params.id, value)
      .then((res) => {
        if (res.status === 200) {
          setTotalPages(res?.data?.response?.totalPages);
          setTotalRecords(res?.data?.response?.totalResults);
          setLoader(false);
          setComps(res.data.response.results);
        }
      })
      .catch((error) => {
        setLoader(false);
        console.log("getFilteredComps");
      });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
    let queryParam = "";

    if (query) {
      queryParam = `?page=${value}&&limit=${limit}&&search=${query}`;
      getTradedComps(queryParam);
    } else {
      queryParam = `?page=${value}&&limit=${limit}`;
      getTradedComps(queryParam);
    }
  };

  const handleLimitChange = (event) => {
    const value = event.target.value;
    if (value === "" || validateNumber(value)) {
      setLimit(value);
    }
  };

  const handleClear = () => {
    setFrom(null);
    setTo(null);
    setType(0);
    handleClose();

    let queryParam = `?page=${page}&&limit=${limit}`;
    if (query) {
      queryParam = `?page=${page}&&limit=${limit}&&search=${query}`;
    }
    getFilteredComps(queryParam);
  };

  const handleQuery = (event) => {
    const { value } = event.target;
    if (!valideWhiteSpace(value)) {
      setQuery(value);
    }
  };

  const handleSearch = () => {
    setQuery("");
  };

  const handleFilter = (values) => {
    setTo(values.to);
    setFrom(values.from);
    setType(values.type);

    let queryParam = `?page=${page}&&limit=${limit}`;
    if (query) {
      queryParam = `?page=${page}&&limit=${limit}&&search=${query}`;
    }
    if (values.to && values.from) {
      queryParam =
        queryParam +
        `&&tradedDateMin=${values.from}&&tradedDateMax=${values.to}`;
    }
    if (values.type !== 0) {
      queryParam = queryParam + `&&type=${values.type}`;
    }

    getFilteredComps(queryParam);
  };

  const handleBack = () => {
    if (params?.id) {
      navigate(`/admin/comp/${params?.id}`);
    }
  };

  return (
    <div className="page-listing">
      <div className="dashboard-title back-link">
        <img
          src={backIcon}
          alt="back-icon"
          style={{ marginRight: "14px" }}
          onClick={handleBack}
        />
        <h2>Total Trades</h2>
      </div>

      <div className="listing-filter space-between mt-20 mb-20">
        <div className="justify-start">
          <div className="search mr-20">
            <TextField
              className="search-box"
              fullWidth
              id="outlined-search"
              type="search"
              value={query}
              onChange={handleQuery}
              placeholder="Search by user,company or address"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon
                      className="search-icon pointer"
                      onClick={handleSearch}
                    ></SearchIcon>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div>
        <button
          className="btn btn-primary btn-filter"
          onClick={handleClickOpen}
        >
          <FilterListIcon fontSize="large" />
        </button>
      </div>

      <div className="table-cover">
        <Table aria-label="simple table" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Send By</TableCell>
              <TableCell>Send To</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Offered Comp</TableCell>
              <TableCell>Date & Time</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!loader &&
              comps.length > 0 &&
              comps.map((comp, index) => (
                <TableRow
                  key={comp._id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>{comp?._id || ""}</TableCell>
                  <TableCell>{comp?.tradedByDetails?.name || ""}</TableCell>
                  <TableCell>{comp?.tradedWithDetails?.name || ""}</TableCell>
                  <TableCell>
                    {" "}
                    {comp?.type ? tradeType(comp?.type) : ""}
                  </TableCell>

                  <TableCell title={comp?.tradedWithComp?.fullAddress || ""}>
                    {comp?.tradedWithComp?.fullAddress || ""}
                  </TableCell>
                  <TableCell>
                    {" "}
                    {comp?.updatedAt
                      ? dayjs(comp?.updatedAt).format("MM/DD/YYYY | h:mm A")
                      : ""}
                  </TableCell>
                </TableRow>
              ))}

            {!loader && comps.length === 0 && (
              <TableRow>
                <TableCell
                  colSpan={9}
                  style={{
                    padding: "120px 0px",
                    color: "#333",
                    fontSize: "24px",
                  }}
                >
                  No Records Found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {loader && <Loader />}
      </div>
      <div className="pagination-cover">
        <Pagination
          count={totalPages}
          variant="outlined"
          shape="rounded"
          onChange={handlePageChange}
        />
        <TextField
          name="limit"
          variant="standard"
          value={limit}
          onChange={handleLimitChange}
          style={{ width: "80px", margin: "0px 20px" }}
        />
        <h3>
          {comps?.length !== 0 ? comps?.length : "0"} of {totalRecords}
        </h3>
      </div>

      <Dialog open={open}>
        <TradeFilter
          handleFilter={handleFilter}
          from={from}
          to={to}
          type={type}
          handleClose={handleClose}
          handleClear={handleClear}
        />
      </Dialog>
    </div>
  );
}
