import React from "react";
import Geocode from "react-geocode";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { CONFIG } from "../../../utils/Constant";

Geocode.setApiKey(CONFIG.GOOGLE_MAPS_API_KEY);
//const google = window.google;

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

export default function ClusteredCompList({
  open,
  handleClose,
  comps,
  goToCompDetail,
  propertyType,
  levelType,
}) {
  return (
    <BootstrapDialog open={open} className="list-dialog">
      <CloseIcon className="close-icon" title="Close" onClick={handleClose} />
      <DialogContent>
        <div className="table-cover">
          <Table aria-label="simple table" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Category</TableCell>
                <TableCell>Address</TableCell>
                <TableCell>Company Name</TableCell>
                <TableCell>Owner</TableCell>
                <TableCell>Property Type</TableCell>
                <TableCell>Level</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Type</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {comps.length > 0 &&
                comps.map((comp, index) => (
                  <TableRow
                    key={comp._id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    onClick={() => goToCompDetail(comp._id)}
                  >
                    <TableCell>{comp?._id || ""}</TableCell>
                    <TableCell>{comp.type === 1 ? "Lease" : "Sale"}</TableCell>
                    <TableCell title={comp?.fullAddress || ""}>
                      {comp?.fullAddress || ""}
                    </TableCell>
                    <TableCell>{comp?.tenantCompanyName || ""}</TableCell>
                    <TableCell>{comp?.ownerDetail?.name || ""}</TableCell>
                    <TableCell>
                      {comp?.propertyType
                        ? propertyType(comp.propertyType)
                        : ""}
                    </TableCell>
                    <TableCell>
                      {comp?.level ? levelType(comp.level) : ""}
                    </TableCell>
                    <TableCell>
                      {`${comp?.isLocked === true ? "Locked" : "Unlocked"}/${
                        comp?.isActive === true ? "Active" : "Inactive"
                      }`}
                    </TableCell>
                    <TableCell>
                      {comp?.isConfidential === true
                        ? "Non-Tradable"
                        : "Tradable"}
                    </TableCell>
                  </TableRow>
                ))}

              {/* {!loader && comps.length === 0 && (
                <TableRow>
                  <TableCell
                    colSpan={9}
                    style={{
                      padding: "120px 0px",
                      color: "#333",
                      fontSize: "24px",
                    }}
                  >
                    No Records Found
                  </TableCell>
                </TableRow>
              )} */}
            </TableBody>
          </Table>
          {/* {loader && <Loader />} */}
        </div>
      </DialogContent>
    </BootstrapDialog>
  );
}
