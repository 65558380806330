import { httpClient } from "../../utils/Apis";
import { REPORTS } from "../Constant";

class ReportService {
  getOpenApp(query) {
    return httpClient.get(`${REPORTS.GET_OPEN_APP}${query}`);
  }

  getUsers(query) {
    return httpClient.get(`${REPORTS.GET_USERS}${query}`);
  }

  getTrades(query) {
    return httpClient.get(`${REPORTS.GET_TRADES}${query}`);
  }

  getComps(query) {
    return httpClient.get(`${REPORTS.GET_COMPS}${query}`);
  }

  downloadOpenApp(query) {
    if (query) {
      return httpClient.get(`${REPORTS.DOWNLOAD_OPEN_APP}${query}`);
    } else {
      return httpClient.get(`${REPORTS.DOWNLOAD_OPEN_APP}`);
    }
  }

  downloadUserInfo(query) {
    if (query) {
      return httpClient.get(`${REPORTS.DOWNLOAD_USER_INFO}${query}`);
    } else {
      return httpClient.get(`${REPORTS.DOWNLOAD_USER_INFO}`);
    }
  }

  downloadTrades(query) {
    if (query) {
      return httpClient.get(`${REPORTS.DOWNLOAD_TRADES}${query}`);
    } else {
      return httpClient.get(`${REPORTS.DOWNLOAD_TRADES}`);
    }
  }

  downloadComps(query) {
    if (query) {
      return httpClient.get(`${REPORTS.DOWNLOAD_COMPS}${query}`);
    } else {
      return httpClient.get(`${REPORTS.DOWNLOAD_COMPS}`);
    }
  }
}

export default new ReportService();
