import { httpClient } from "../../utils/Apis";
import { SUBSCRIPTION } from "../Constant";

class SubscriptionService {
  getSubscriptions() {
    return httpClient.get(SUBSCRIPTION.GET_SUBSCRIPTION_LIST);
  }

  getSubscription(id) {
    return httpClient.get(SUBSCRIPTION.GET_SUBSCRIPTION.replace("{id}", id));
  }

  updateSubscription(id, body) {
    return httpClient.put(
      SUBSCRIPTION.UPDATE_SUBSCRIPTION.replace("{id}", id),
      body
    );
  }

  generateCoupon(body) {
    return httpClient.post(SUBSCRIPTION.GENERATE_SUBSCRIPTION_COUPON, body);
  }

  getCouponList(query) {
    return httpClient.get(`${SUBSCRIPTION.SUBSCRIPTION_COUPON_LIST}${query}`);
  }
}

export default new SubscriptionService();
