import { httpClient } from "../../utils/Apis";
import { NOTIFICATION } from "../Constant";

class NotificationService {
  getNotifications(query) {
    return httpClient.get(`${NOTIFICATION.GET_NOTIFICATIONS}${query}`);
  }

  addNotification(body) {
    return httpClient.post(NOTIFICATION.ADD_NOTIFICATION, body);
  }

  getNotification(id, query) {
    if (query) {
      return httpClient.get(
        `${NOTIFICATION.GET_NOTIFICATION.replace("{id}", id)}${query}`
      );
    } else {
      return httpClient.get(
        `${NOTIFICATION.GET_NOTIFICATION.replace("{id}", id)}`
      );
    }
  }
  getNotificationUsers(query) {
    return httpClient.get(`${NOTIFICATION.GET_NOTIFICATION_USERS}${query}`);
  }
}

export default new NotificationService();
