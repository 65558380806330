import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Auth from "../modules/auth/index";
import Layout from "../modules/layout/Layout";
import PageNotFound from "../components/common/PageNotFound";

const PrivateRoute = ({ children }) => {
  const token = JSON.parse(localStorage.getItem("tokenInfo"))?.accessToken;
  return token ? children : <Navigate to="/" />;
};

export default function RouteComp() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/*" element={<Auth />} />
          <Route
            path="admin/*"
            element={
              <PrivateRoute>
                <Layout />
              </PrivateRoute>
            }
          />
          <Route path="notfound" element={<PageNotFound />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}
