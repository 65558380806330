import React, { useState, useCallback } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useDropzone } from "react-dropzone";
import CloseIcon from "@mui/icons-material/Close";
import "./csv.css";
import Grid from "@mui/material/Grid";
import BrokerService from "../../../services/component/Broker";
import Tooltip from "@mui/material/Tooltip";
import { isEmpty } from "lodash";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";

const sampleLeaseCompLink =
  "https://app-development.s3.amazonaws.com/users/sampleLease.csv";

const sampleSalesCompLink =
  "https://app-development.s3.amazonaws.com/users/sampleSalesData.csv";

const CompCsv = ({
  handleCsvClose,
  setCsvFile,
  totalRecords,
  selectedOwner,
  setSelectedOwner,
}) => {
  const [error, setError] = useState("");
  const [ownerData, setOwnerData] = useState([]);
  console.log("ownerData =>", ownerData);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const onDrop = useCallback(
    (acceptedFiles) => {
      if (
        selectedOwner &&
        acceptedFiles &&
        acceptedFiles[0]?.type === "text/csv"
      ) {
        setCsvFile(acceptedFiles[0]);
        setError("");
      } else {
        setError("Only CSV files are allowed.");
      }
    },
    [selectedOwner]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: ".csv",
  });

  const getAllUsersData = () => {
    setIsloading(true);
    let queryParam = `?limit=${totalRecords}`;
    BrokerService.getBrokerList(queryParam)
      .then((res) => {
        if (res.status === 200) {
          setIsloading(false);
          setOwnerData(res?.data?.response?.results);
        }
      })
      .catch((error) => {
        setIsloading(false);
        console.log(error);
      });
  };

  const selectOpen = () => {
    setOpen(true);
    getAllUsersData();
  };

  const handleSelectedOwner = (data) => {
    setSelectedOwner(data?._id);
  };

  return (
    <div className="filter-cover">
      <div className="filter-header">
        <h2>Upload file</h2>
        <CloseIcon
          className="close-icon"
          title="Close"
          onClick={handleCsvClose}
        />
      </div>
      <div className="filter-body">
        <Grid item xs={12}>
          <Autocomplete
            disablePortal
            onOpen={selectOpen}
            id="owner-dropdown"
            onChange={(event, newValue) => {
              handleSelectedOwner(newValue);
            }}
            options={ownerData}
            autoHighlight
            getOptionLabel={(option) =>
              option?.name + ` (${option?.email ?? option?.mobile})`
            }
            renderOption={(props, option) => (
              <Box component="li" {...props}>
                <Tooltip
                  title={option?.name + ` (${option?.email ?? option?.mobile})`}
                  arrow
                >
                  <span className="truncate">
                    {option?.name + ` (${option?.email ?? option?.mobile})`}
                  </span>
                </Tooltip>
              </Box>
            )}
            sx={{ width: "100%" }}
            componentsProps={{
              paper: {
                sx: {
                  width: "100%",
                },
              },
            }}
            loading={isLoading && isEmpty(ownerData)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Owner"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {isLoading && isEmpty(ownerData) ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
        </Grid>
        <div className="flex-column mt-20">
          <button
            className="btn btn-primary mr-20"
            style={{ fontSize: "14px", fontWeight: "bold", maxWidth: "195px" }}
            onClick={() => {
              document.getElementById("fileInput").click();
            }}
            disabled={!selectedOwner}
          >
            <span className="space-between">
              <CloudUploadIcon className="mr-10" /> Upload CSV
            </span>
          </button>
          <p className="mt-10">Download a sample</p>
          <ul>
            <li className="li">
              <a href={sampleLeaseCompLink}>Lease Comp File</a>
            </li>
            <li className="li">
              <a href={sampleSalesCompLink}>Sales Comp File</a>
            </li>
          </ul>
        </div>
        <div
          {...getRootProps()}
          style={{ pointerEvents: selectedOwner ? "auto" : "none" }}
          className="dropzone my-16 text-center"
        >
          <input {...getInputProps()} />
          <CloudUploadIcon />
          <p>
            Drop your files here, or <a className="browse-link">Browse</a>
          </p>
          <p className="mt-1 opacity-60 dark:text-white">Support: csv</p>
        </div>

        {error ? <div className="error">{error}</div> : <></>}
        <input
          type="file"
          id="fileInput"
          style={{ display: "none" }}
          onChange={(e) => {
            const files = Array.from(e.target.files);
            onDrop(files);
          }}
          accept=".csv"
        />
      </div>
      <div className="filter-footer"></div>
    </div>
  );
};

export default CompCsv;
