import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

import Loader from "../../../components/common/Loader";
import "./add-coupon.css";
import { ERROR } from "../../../utils/Constant";
import {
  valideWhiteSpace,
  validateCoupon,
  validateEmail,
} from "../../../utils/Helper";
import SubscriptionService from "../../../services/component/Subscription";

export default function AddCoupon({ handleCloseInvite }) {
  const [loader, setLoader] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [formValues, setFormValues] = useState({
    name: "",
    discount: "100",
  });
  const [formErrors, setFormErrors] = useState({});
  const [inputList, setInputList] = useState([{ email: "" }]);
  const [fieldSetErrors, setFieldSetErrors] = useState([{ email: null }]);
  useEffect(() => {
    if (formValues && inputList) {
      if (
        Object.values(formValues).every((x) => x) &&
        Object.values(inputList).every((x) => x.email) &&
        Object.values(formErrors).every((x) => !x) &&
        fieldSetErrors
          .map((error) => Object.values(error).every((x) => !x))
          .every((v) => v === true)
      ) {
        setDisabled(false);
      }
    }
  }, [formErrors, formValues, inputList, fieldSetErrors]);

  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    if (!valideWhiteSpace(value)) {
      const list = [...inputList];
      list[index][name] = value.trim();
      setInputList(list);
      validateFieldSet(index, value.trim(), name);
    }
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);

    const values = [...fieldSetErrors];
    values.splice(index, 1);
    setFieldSetErrors(values);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setDisabled(true);
    setInputList([...inputList, { email: "" }]);
    setFieldSetErrors([...fieldSetErrors, { email: null }]);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (!valideWhiteSpace(value)) {
      setFormValues({ ...formValues, [name]: value });
      validateField(value, name);
    }
  };

  const validateField = (value, name) => {
    if (!Boolean(value)) {
      setDisabled(true);
      setFormErrors({
        ...formErrors,
        [name]: "Required Field",
      });
    } else if (!validateCoupon(value) && Boolean(value) && name === "name") {
      setDisabled(true);
      setFormErrors({ ...formErrors, [name]: ERROR.INVALID_COUPON });
    } else {
      setFormErrors({ ...formErrors, [name]: "" });
    }
  };

  const validateFieldSet = (index, value, name) => {
    if (!Boolean(value)) {
      setDisabled(true);
      const values = [...fieldSetErrors];
      values[index][name] = "Required";
      setFieldSetErrors(values);
    } else if (!validateEmail(value) && Boolean(value)) {
      setDisabled(true);
      const values = [...fieldSetErrors];
      values[index][name] = ERROR.INVALID_EMAIL;
      setFieldSetErrors(values);
    } else {
      const values = [...fieldSetErrors];
      values[index][name] = null;
      setFieldSetErrors(values);
    }
  };

  const hasDuplicates = (array) => {
    return new Set(array).size !== array.length;
  };

  const handleSubmit = () => {
    const { name, discount } = formValues;
    const emails = inputList.map((x) => x.email);
    if (!hasDuplicates(emails)) {
      const body = {
        name,
        discount,
        emails: emails,
      };
      setLoader(true);

      SubscriptionService.generateCoupon(body)
        .then((res) => {
          toast.success(res.data.message);
          setLoader(false);
          handleCloseInvite();
        })
        .catch((error) => {
          setLoader(false);
          toast.error(error?.response?.data?.message);
        });
    } else {
      toast.error("Email contain duplicate values");
    }
  };

  return (
    <div className="filter-cover">
      {loader && <Loader />}
      <div className="filter-header">
        <h2>Invite</h2>
        <CloseIcon
          className="close-icon"
          title="Close"
          onClick={handleCloseInvite}
        />
      </div>
      <div className="filter-body">
        <div
          className="formItem add-details-info add-subs"
          style={{ marginBottom: "0px" }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <h3 className="mb-10 required">Coupon Code</h3>
              <TextField
                fullWidth
                name="name"
                value={formValues.name || ""}
                onChange={handleChange}
                variant="outlined"
                label="Coupon Code"
                e
                inputProps={{
                  maxLength: 35,
                }}
                error={Boolean(formErrors?.name)}
                helperText={formErrors?.name}
              />
            </Grid>

            <Grid item xs={12}>
              <div className="radio-buttonswrapper">
                <div className="radio-label">
                  <h3 className="required mt-10">Discount</h3>
                </div>

                <div className="radio-options">
                  <FormControl>
                    <RadioGroup
                      name="discount"
                      value={formValues?.discount}
                      onChange={handleChange}
                      style={{ flexDirection: "row" }}
                    >
                      <FormControlLabel
                        className={`${
                          formValues?.discount === "1" ? "active" : ""
                        }`}
                        value="100"
                        control={<Radio />}
                        label="100"
                      />
                      <FormControlLabel
                        className={`${
                          formValues?.discount === "2" ? "active" : ""
                        }`}
                        value="50"
                        control={<Radio />}
                        label="50"
                      />
                      <FormControlLabel
                        className={`${
                          formValues?.discount === "3" ? "active" : ""
                        }`}
                        value="25"
                        control={<Radio />}
                        label="25"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="space-between">
                <h3 className="required nowrap">Email Id</h3>
                <div className="add-link-cover" style={{ flex: 1 }}>
                  <p
                    className="underline bold"
                    title="Add Description"
                    onClick={handleAddClick}
                  >
                    <AddCircleOutlineIcon /> &nbsp; Add
                  </p>
                </div>
              </div>

              <div className="item">
                {inputList.map((x, i) => {
                  return (
                    <div className="text-plus-button space-between">
                      <TextField
                        fullWidth
                        name="email"
                        value={x.email}
                        inputProps={{
                          maxLength: 35,
                        }}
                        onChange={(e) => handleInputChange(e, i)}
                        variant="outlined"
                        label="Enter Email"
                        error={Boolean(fieldSetErrors[i]?.email)}
                        helperText={fieldSetErrors[i]?.email}
                      />

                      <button
                        className="btn btn-danger"
                        disabled={inputList.length === 1}
                        onClick={() => handleRemoveClick(i)}
                        title="Remove"
                      >
                        <RemoveCircleOutlineIcon />
                      </button>
                    </div>
                  );
                })}
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className="filter-footer">
        <button
          className="btn btn-primary"
          disabled={disabled}
          onClick={handleSubmit}
        >
          Save
        </button>
      </div>
    </div>
  );
}
