import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

export default function ConfirmAlert({
  message,
  open,
  handleClose,
  handleAlertSubmit,
}) {
  return (
    <Dialog
      className="modal-in-center"
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="xs"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <h3 className="center mt-20" style={{color: 'black'}}>{message}</h3>
        </DialogContentText>
      </DialogContent>
      <div className="flex-center mt-20 mb-30">
        <button className="btn btn-simple mr-20" onClick={handleClose}>Cancel</button>
        <button className="btn btn-primary" onClick={handleAlertSubmit} autoFocus>
          Yes
        </button>
      </div>
    </Dialog>
  );
}
