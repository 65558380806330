import React from "react";
import { Routes, Route } from "react-router-dom";
import CouponListing from "./coupon-listing/CouponListing";

export default function CouponRoute() {
  return (
    <Routes>
      <Route path="/" element={<CouponListing />} />
    </Routes>
  );
}
