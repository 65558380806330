import "./App.css";
import RouteComp from "./routes/index"; 
import { injectStyle } from "react-toastify/dist/inject-style";
import { ToastContainer } from "react-toastify";

if (typeof window !== "undefined") {
  injectStyle();
}

function App() {
  return (
    <>
      <div className="App">
        <RouteComp />
      </div>
      <ToastContainer autoClose={2000} />
    </>
  );
}

export default App;
