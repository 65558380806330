import { REGEX } from "./Constant";

const validatePassword = (input) => {
  return REGEX.PASSWORD.test(input);
};

const validateEmail = (input) => {
  return REGEX.EMAIL.test(input);
};
const validateCoupon = (input) => {
  return REGEX.COUPON.test(input);
};
const validateNumber = (input) => {
  return REGEX.NUMBER.test(input);
};

const valideWhiteSpace = (input) => {
  return REGEX.SPACE.test(input);
};

const formatPhoneNumber = (value) => {
  if (!value) return value;
  const phoneNumber = value.replace(/[^\d]/g, "");
  const phoneNumberLength = phoneNumber.length;
  if (phoneNumberLength < 4) return phoneNumber;
  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
    3,
    6
  )}-${phoneNumber.slice(6, 10)}`;
};

const validatePhoneNumber = (input) => {
  return REGEX.MOBILE.test(input);
};

const cleanObject = (obj) => {
  for (var propName in obj) {
    if (
      obj[propName] === null ||
      obj[propName] === undefined ||
      obj[propName] === ""
    ) {
      delete obj[propName];
    }
  }
  return obj;
};

const numberFormat = (value) => {
  return value.replace(/[^A-Z0-9]/gi, "");
};

const serialNumber = (index, page, limit) => {
  if (limit === "" || limit === 10) {
    if (page === 1) {
      return `${index}`;
    }
    if (page !== 1) {
      if (index === 10) {
        return `${page}0`;
      }
      return `${page - 1}${index}`;
    }
  } else {
    if (page === 1) {
      return `${index}`;
    } else {
      return `${limit * (page - 1) + index}`;
    }
  }
};

//Future Date
const incrementDate = (dateInput, increment) => {
  var dateFormatTotime = new Date(dateInput);
  var increasedDate = new Date(
    dateFormatTotime.getTime() + increment * 86400000
  );
  return increasedDate;
};

const elementFromArray = (arr, item) => {
  let index = arr.indexOf(item);
  return [...arr.slice(0, index), ...arr.slice(index + 1)];
};

const convertNumToDecimal = (val, decimal) => {
  if (val) {
    let convertNum = Number(val);
    let finalVal = convertNum.toFixed(decimal);
    return finalVal;
  } else {
    return val;
  }
};

const addDefaultSrc = (e, img) => {
  e.target.src = img;
};

const currencyFormat = (num) => {
  num = Number(num);
  return num.toFixed(2).replace(REGEX.CURRENCY, "$1,");
};

const sizeFormat = (num) => {
  return num.toString().replace(REGEX.CURRENCY, "$1,");
};
export {
  addDefaultSrc,
  elementFromArray,
  valideWhiteSpace,
  validatePassword,
  validateEmail,
  validateNumber,
  formatPhoneNumber,
  validatePhoneNumber,
  cleanObject,
  numberFormat,
  serialNumber,
  incrementDate,
  convertNumToDecimal,
  sizeFormat,
  currencyFormat,
  validateCoupon,
};
