import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { ERROR } from "../../utils/Constant";
import { validatePassword, validateEmail } from "../../utils/Helper";
import AuthService from "../../services/component/Auth";
import { userSignIn } from "../../redux/Action";

export default function SignIn() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [formValues, setFormValues] = useState({ email: "", password: "" });
  const [formErrors, setFormErrors] = useState({});
  const [disabled, setDisabled] = useState(true);
  const [loader, setLoader] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (
      Object.keys(formErrors).length !== 0 &&
      Object.values(formValues).every((x) => x) &&
      Object.values(formErrors).every((x) => !x)
    ) {
      setDisabled(false);
    }
  }, [formErrors, formValues]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    validate(value, name);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  //validate input fields
  const validate = (value, name) => {
    switch (name) {
      case "email":
        validateField(
          value,
          name,
          validateEmail,
          ERROR.EMAIL_REQUIRED,
          ERROR.INVALID_EMAIL
        );

        break;

      default:
        validateField(
          value,
          name,
          validatePassword,
          ERROR.PASSWORD_REQUIRED,
          ERROR.INVALID_PASSWORD
        );
    }
  };

  const handleSubmit = () => {
    setLoader(true);
    setDisabled(true);
    AuthService.signinUser(formValues)
      .then((res) => {
        if (res.status === 200) {
          toast.success(res.data.message);
          setLoader(false);
          setDisabled(false);
          const authUser = {
            token: res.data.response.tokens,
            user: res.data.response.user,
          };
          localStorage.setItem(
            "tokenInfo",
            JSON.stringify(res.data.response.tokens)
          );
          localStorage.setItem(
            "userInfo",
            JSON.stringify(res.data.response.user)
          );

          dispatch(userSignIn(authUser));
          navigate("../../admin/dashboard");
        }
      })
      .catch((error) => {
        setLoader(false);
        setDisabled(false);
        toast.error(error.response.data.message);
      });
  };

  const validateField = (value, name, validate, required, invalid) => {
    if (!Boolean(value)) {
      setDisabled(true);
      setFormErrors({
        ...formErrors,
        [name]: required,
      });
    } else if (!validate(value) && Boolean(value)) {
      setDisabled(true);
      setFormErrors({ ...formErrors, [name]: invalid });
    } else {
      setFormErrors({ ...formErrors, [name]: "" });
    }
  };

  return (
    <div className="page-signin">
      <h2>Welcome Back!</h2>
      <h3>Login into your account</h3>
      <div className="formIn">
        <div className="formItem">
          <TextField
            fullWidth
            name="email"
            variant="outlined"
            label="Email"
            value={formValues?.email || ""}
            onChange={handleChange}
            error={Boolean(formErrors?.email)}
            helperText={formErrors?.email}
          />
        </div>
        <div className="formItem form">
          <TextField
            fullWidth
            name="password"
            variant="outlined"
            label="Password"
            type={showPassword ? "text" : "password"}
            value={formValues?.password || ""}
            onChange={handleChange}
            error={Boolean(formErrors?.password)}
            helperText={formErrors?.password}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickShowPassword}
                    className="color-secondary"
                  >
                    {showPassword ? (
                      <RemoveRedEyeOutlinedIcon />
                    ) : (
                      <VisibilityOffOutlinedIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>
      <div className="btn-password">
        {/* <button type="button" class="forgot-pass">
          Forgot Password?
        </button> */}
        <Link className="forgot-pass" to="/forgot-password">
          Forgot Password?
        </Link>
      </div>
      <button
        className="btn btn-primary mt-30 w-100"
        onClick={handleSubmit}
        disabled={disabled}
      >
        {" "}
        {!loader ? "Login" : "Loading..."}
      </button>
    </div>
  );
}
