import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Grid from "@mui/material/Grid";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs-plugin-utc";
import { toast } from "react-toastify";
import { useParams, useNavigate, Link } from "react-router-dom";

import "./comp-detail.css";
import propertyImg from "../../../assets/images/dummy-property-image.jpg";
import goldMedalIcon from "../../../assets/icons/gold-medal.svg";
import silverMedalIcon from "../../../assets/icons/silver-medal.svg";
import bronzeMedalIcon from "../../../assets/icons/bronze-medal.svg";
import lockIcon from "../../../assets/icons/lock-icon.svg";
import unlockIcon from "../../../assets/icons/unlock-icon.svg";
import brokerImg from "../../../assets/images/landlord-broker-img.svg";
import backIcon from "../../../assets/images/back-icon.svg";
import shareMailIcon from "../../../assets/icons/share-mail-icon.svg";
import fileTimeIcon from "../../../assets/icons/file-time-icon.svg";

import CompService from "../../../services/component/Comp";
import Loader from "./../../../components/common/Loader";
import { CONFIG } from "../../../utils/Constant";
import {
  propertyType,
  rentType,
  tisUnit,
  leaseType,
} from "../../../utils/SwitchCase";
import {
  convertNumToDecimal,
  sizeFormat,
  currencyFormat,
} from "../../../utils/Helper";
import ConfirmAlert from "../../../components/common/CofirmAlert";

dayjs.extend(dayjsPluginUTC);
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function CompDetail() {
  const navigate = useNavigate();
  const params = useParams();
  const [loader, setLoader] = useState(true);
  const [value, setValue] = useState(0);
  const [active, setActive] = useState(0);
  const [comp, setComp] = useState({});

  const [newAlignment, setNewAlignment] = useState(null);
  const [open, setOpen] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    CompService.getComp(params.id)
      .then((res) => {
        if (res.status === 200) {
          setComp(res.data.response);
          setActive(res.data.response.isActive ? 1 : 0);
          setLoader(false);
        }
      })
      .catch((error) => {
        setLoader(false);
        console.log("getCompError");
      });
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  const levelImage = (value) => {
    let level;
    if (value) {
      switch (value) {
        case 1:
          level = goldMedalIcon;
          break;
        case 2:
          level = silverMedalIcon;
          break;
        default:
          level = bronzeMedalIcon;
      }
      return level;
    }
  };

  const calculateValue = (salePrice, sizeSale) => {
    const value = salePrice / sizeSale;
    return convertNumToDecimal(value, 2);
  };

  const addDefaultSrc = (e, img) => {
    e.target.src = img;
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setNewAlignment(null);
    setOpen(false);
  };

  const handleActive = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: number
  ) => {
    setNewAlignment(newAlignment);
    handleOpen();
  };

  const handleAlertSubmit = () => {
    setOpen(false);
    setLoader(true);
    try {
      const body = {
        compId: comp._id,
        status: newAlignment,
      };

      CompService.activeComp(body)
        .then((res) => {
          if (res.status === 200) {
            toast.success(res?.data?.message || "status updated");
            setLoader(false);
            setActive(newAlignment);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoader(false);
        });
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };

  return (
    <div className='page-comp-detail'>
      <div className='space-between mb-20'>
        <div className='dashboard-title back-link'>
          <img
            src={backIcon}
            alt='back-icon'
            onClick={() => navigate("/admin/comp")}
          />
          <h2>Comp Detail</h2>
        </div>
        <div className='justify-end'>
          <ToggleButtonGroup
            color='primary'
            value={active}
            exclusive
            onChange={handleActive}
          >
            <ToggleButton
              className='btn btn-danger'
              disabled={active === 1}
              value={1}
            >
              Active
            </ToggleButton>
            <ToggleButton
              className='btn btn-success'
              disabled={active === 0}
              value={0}
            >
              Inactive
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      </div>
      <div className='comp-detail-wrapper'>
        {loader && <Loader />}
        <div className='property-image-cover'>
          <figure>
            <img
              className='prop-img'
              onError={(e) => addDefaultSrc(e, propertyImg)}
              src={
                !comp?.buildingPhotoUrl
                  ? propertyImg
                  : CONFIG.S3_URL + comp?.buildingPhotoUrl
              }
              alt='prop-img'
            />
          </figure>
          <div className='image-content'>
            <h3>
              {comp.type === 1 ? "Lease" : "Sale"} |{" "}
              {comp?.isConfidential === true ? "Non-Tradable" : "Tradable"}
            </h3>
            <div className='justify-end icons-cover'>
              <img
                src={comp?.level ? levelImage(comp.level) : goldMedalIcon}
                alt='comp-level'
              />
              <img
                src={comp?.isLocked === true ? lockIcon : unlockIcon}
                alt='lock-img'
              />
            </div>
          </div>
        </div>
        <div className='comp-detail-tabs'>
          <Tabs value={value} onChange={handleChange} className='mb-20'>
            <Tab label='Address' {...a11yProps(0)} />
            <Tab label='Basic Info' {...a11yProps(1)} />
            <Tab label='Brokers Info' {...a11yProps(2)} />
            <Tab label='Details' {...a11yProps(3)} />
          </Tabs>
          <TabPanel value={value} index={0}>
            <Grid container columnSpacing={3} rowSpacing={3}>
              <Grid item xs={12} lg={6}>
                <h4>Address Line</h4>
                <h4 className='color-primary'>{comp?.addressLine1 || "N/A"}</h4>
              </Grid>
              <Grid item xs={12} lg={6}>
                <h4>Suite</h4>
                <h4 className='color-primary'>{comp?.suite || "N/A"}</h4>
              </Grid>
              <Grid item xs={12} lg={6}>
                <h4>City</h4>
                <h4 className='color-primary'>{comp?.city || "N/A"}</h4>
              </Grid>
              <Grid item xs={12} lg={6}>
                <h4>State</h4>
                <h4 className='color-primary'>{comp?.state || "N/A"}</h4>
              </Grid>
              <Grid item xs={12} lg={6}>
                <h4>Zip Code</h4>
                <h4 className='color-primary'>{comp?.zipCode || "N/A"}</h4>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={1}>
            {comp.type === 1 ? (
              <Grid container columnSpacing={3} rowSpacing={3}>
                <Grid item xs={12} lg={6}>
                  <h4>Tenant Company Name</h4>
                  <h4 className='color-primary'>{comp?.tenantCompanyName}</h4>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <h4>Property Type</h4>
                  <h4 className='color-primary'>
                    {" "}
                    {comp?.propertyType ? propertyType(comp.propertyType) : ""}
                  </h4>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <h4>Rent Type</h4>
                  <h4 className='color-primary'>
                    {comp?.rentType ? rentType(comp.rentType) : ""}
                  </h4>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <h4>Commencement</h4>
                  <h4 className='color-primary'>
                    {comp?.commencement
                      ? dayjs(comp?.commencement).format("MM/DD/YYYY")
                      : ""}
                  </h4>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <h4>Term</h4>
                  <h4 className='color-primary'>{comp?.term} MONTH</h4>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <h4>Lease Expiration</h4>
                  <h4 className='color-primary'>
                    {comp?.leaseExpiration
                      ? dayjs(comp?.leaseExpiration).format("MM/DD/YYYY")
                      : ""}
                  </h4>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <h4>Size</h4>
                  <h4 className='color-primary'>
                    {comp?.size ? `${sizeFormat(comp?.size)} SQFT` : "N/A"}{" "}
                  </h4>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <h4>Base Rent</h4>
                  <h4 className='color-primary'>
                    {/* {comp?.baseRent} $/SQFT/YEAR */}
                    {`$ ${currencyFormat(comp?.baseRent)} / SQFT / ${
                      comp.baseRentUnit === 2 ? "YEAR" : "MONTH"
                    }`}
                  </h4>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <h4>Free Rent</h4>
                  <h4 className='color-primary'>{comp?.freeRent} MONTH</h4>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <h4>Effective Rent</h4>
                  <h4 className='color-primary'>
                    {/* {comp?.effectiveRent} $/SQFT/YEAR */}
                    {comp?.effectiveRent
                      ? `$ ${currencyFormat(comp?.effectiveRent)} / SQFT / ${
                          comp?.effectiveRentUnit === 2 ? "YEAR" : "MONTH"
                        }`
                      : "N/A"}
                  </h4>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <h4>Expenses</h4>
                  <h4 className='color-primary'>
                    {comp?.expenses
                      ? `$ ${currencyFormat(comp?.expenses)} / SQFT / ${
                          comp?.expensesUnit === 2 ? "YEAR" : "MONTH"
                        }`
                      : "N/A"}
                  </h4>
                </Grid>

                <Grid item xs={12} lg={6}>
                  <h4>Escalations</h4>
                  <h4 className='color-primary'>
                    {/* {comp?.escalation}{" "}
                    {comp?.escalationUnit
                      ? escalationUnit(comp.escalationUnit)
                      : ""} */}
                    {comp?.escalationUnit === 3
                      ? `${currencyFormat(comp?.escalation)} %`
                      : `$ ${currencyFormat(comp?.escalation)} / SQFT / ${
                          comp?.escalationUnit === 2 ? "YEAR" : "MONTH"
                        }`}
                  </h4>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <h4>TIs</h4>
                  <h4 className='color-primary'>{tisUnit(comp)}</h4>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <h4>Lease Type</h4>
                  <h4 className='color-primary'>
                    {comp?.leaseType ? leaseType(comp.leaseType) : ""}
                  </h4>
                </Grid>
              </Grid>
            ) : (
              <Grid container columnSpacing={3} rowSpacing={3}>
                <Grid item xs={12} lg={6}>
                  <h4>Buyer Name</h4>
                  <h4 className='color-primary'>{comp?.buyerName}</h4>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <h4>Property Type</h4>
                  <h4 className='color-primary'>
                    {" "}
                    {comp?.propertyType ? propertyType(comp.propertyType) : ""}
                  </h4>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <h4>Sale Price</h4>
                  <h4 className='color-primary'>
                    {comp?.salePrice
                      ? `$ ${currencyFormat(comp?.salePrice)}`
                      : ""}
                  </h4>
                </Grid>
                {comp?.propertyType !== 6 && (
                  <Grid item xs={12} lg={6}>
                    <h4>Class</h4>
                    <h4 className='color-primary'>{comp?.class}</h4>
                  </Grid>
                )}
                <Grid item xs={12} lg={6}>
                  <h4>Size</h4>
                  <h4 className='color-primary'>
                    {" "}
                    {comp?.size ? `${sizeFormat(comp?.size)} SQFT` : "N/A"}{" "}
                  </h4>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <h4>Close of Escrow Date</h4>
                  <h4 className='color-primary'>
                    {" "}
                    {comp?.escrowCloseDate
                      ? dayjs(comp?.escrowCloseDate).format("MM/DD/YYYY")
                      : ""}
                  </h4>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <h4>Sale Price / SQFT</h4>
                  <h4 className='color-primary'>
                    {comp?.salePrice && comp?.size
                      ? `$ ${calculateValue(comp?.salePrice, comp?.size)}`
                      : "0"}
                  </h4>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <h4>NOI</h4>
                  <h4 className='color-primary'>
                    {comp?.NOI ? `$ ${currencyFormat(comp?.NOI)}` : "N/A"}
                  </h4>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <h4>Cap Rate</h4>
                  <h4 className='color-primary'>
                    {comp?.capRate === "NA"
                      ? comp?.capRate
                      : `${comp?.capRate} %`}
                  </h4>
                </Grid>
              </Grid>
            )}
          </TabPanel>
          <TabPanel value={value} index={2}>
            {/* Brokers INFO */}
            <div className='brokers-info-wrapper'>
              <div className='landlords-details'>
                <h3 className='mb-20'>
                  {comp.type === 1 ? "Landlord" : "Seller's"} Brokers
                </h3>
                <div className='landlords-info'>
                  {comp?.brokers?.length > 0 &&
                  comp?.brokers.filter((x) => x.type === 2).length > 0 ? (
                    comp?.brokers
                      .filter((x) => x.type === 2)
                      .map((seller) => (
                        <div className='landlord-card' key={seller._id}>
                          <div className='space-between mb-10'>
                            {seller?.isOwner && <h4>Owner</h4>}
                            {seller?.isEditor && <h4>Editor</h4>}
                            {seller?.invitedByEmail && (
                              <img src={shareMailIcon} alt='Share' />
                            )}
                            {seller?.invitationAccepted && (
                              <img src={fileTimeIcon} alt='Share' />
                            )}
                          </div>
                          <figure>
                            <img
                              onError={(e) => addDefaultSrc(e, brokerImg)}
                              src={
                                !seller?.brokerDetail?.profilePic
                                  ? brokerImg
                                  : CONFIG.S3_URL +
                                    seller?.brokerDetail?.profilePic
                              }
                              alt='broker-img'
                            />
                          </figure>
                          <h5>{seller?.brokerDetail?.name}</h5>
                          <em>
                            <h6>{seller?.brokerDetail?.companyName}</h6>
                          </em>
                        </div>
                      ))
                  ) : (
                    <h4 className='subtext mb-20'>
                      This side of the deal has no broker(s)
                    </h4>
                  )}
                </div>
              </div>
              {comp.type === 1 ? (
                <>
                  <h4 className='mt-20'>Landlord Company Name</h4>
                  <h4 className='color-primary mb-20'>
                    {comp.landlordCompanyName || "N/A"}
                  </h4>
                </>
              ) : (
                <>
                  <h4 className='mt-20'>Seller Name</h4>
                  <h4 className='color-primary mb-20'>
                    {comp.sellerCompanyName || "N/A"}
                  </h4>
                </>
              )}
              <div className='tenants-details'>
                <h3 className='mb-20'>
                  {comp.type === 1 ? "Tenant" : "Buyer's"} Brokers
                </h3>
                <div className='tenants-info'>
                  {comp?.brokers?.length > 0 &&
                  comp?.brokers.filter((x) => x.type === 1).length > 0 ? (
                    comp?.brokers
                      .filter((x) => x.type === 1)
                      .map((buyer) => (
                        <div className='tenants-card' key={buyer._id}>
                          <div className='tenants-editor'>
                            <div className='space-between mb-20'>
                              {buyer?.isOwner && (
                                <h4 style={{ marginBottom: "4px" }}>Owner</h4>
                              )}
                              {buyer?.isEditor && <h4> Editor</h4>}

                              {buyer?.invitedByEmail && (
                                <img src={shareMailIcon} alt='Share' />
                              )}
                              {buyer?.invitationAccepted && (
                                <img src={fileTimeIcon} alt='Share' />
                              )}
                            </div>
                          </div>
                          <figure>
                            <img
                              onError={(e) => addDefaultSrc(e, brokerImg)}
                              src={
                                !buyer?.brokerDetail?.profilePic
                                  ? brokerImg
                                  : CONFIG.S3_URL +
                                    buyer?.brokerDetail?.profilePic
                              }
                              alt='broker-img'
                            />
                            {/* <img src={brokerImg} alt="broker-img" /> */}
                          </figure>
                          <h5>{buyer?.brokerDetail?.name}</h5>
                          <em>
                            <h6>{buyer?.brokerDetail?.companyName}</h6>
                          </em>
                        </div>
                      ))
                  ) : (
                    <h4 className='subtext mb-20'>
                      This side of the deal has no broker(s)
                    </h4>
                  )}
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel value={value} index={3}>
            {comp.type === 1 ? (
              <>
                <Grid container columnSpacing={3} rowSpacing={3}>
                  <Grid item xs={12} lg={6}>
                    <h4>Class</h4>
                    <h4 className='color-primary'>{comp?.class || "N/A"}</h4>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <h4>Signature Date</h4>
                    <h4 className='color-primary'>
                      {comp?.signatureDate
                        ? dayjs(comp?.signatureDate).format("MM/DD/YYYY")
                        : "N/A"}
                    </h4>
                  </Grid>
                </Grid>
                <div className='parking-info mt-30 mb-20'>
                  <h3 className='mb-10'>Parking</h3>
                  <Grid container columnSpacing={3} rowSpacing={3}>
                    <Grid item xs={12} lg={6}>
                      <h4>Stalls</h4>
                      <h4 className='color-primary'>
                        {comp?.parkingStalls
                          ? `${comp?.parkingStalls} Per 1000 SQFT`
                          : "N/A"}
                      </h4>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <h4>Parking Charges</h4>
                      <h4 className='color-primary'>
                        {comp?.parkingCharges
                          ? `${comp?.parkingCharges} $/STALL/MONTH`
                          : "N/A"}
                      </h4>
                    </Grid>
                  </Grid>
                </div>

                <Grid container columnSpacing={3} rowSpacing={3}>
                  <Grid item xs={12} lg={6}>
                    <h4>Total Trades</h4>
                    <Link to={`/admin/comp/${comp._id}/total-trades`}>
                      {" "}
                      <h2
                        className='color-primary underline'
                        style={{ display: "inline" }}
                      >
                        {comp?.Trades?.length}
                      </h2>
                    </Link>
                  </Grid>
                </Grid>
              </>
            ) : (
              <>
                <Grid container columnSpacing={3} rowSpacing={3}>
                  <Grid item xs={12} lg={6}>
                    <h4>Year Built</h4>
                    <h4 className='color-primary'>
                      {comp?.yearBuilt || "N/A"}
                    </h4>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <h4>Days in Escrow</h4>
                    <h4 className='color-primary'>
                      {comp?.escrowDays || "N/A"}
                    </h4>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <h4>Zoning</h4>
                    <h4 className='color-primary'>{comp?.zoning || "N/A"}</h4>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <h4>Parking Ratio</h4>
                    <h4 className='color-primary'>
                      {comp?.parkingRatio
                        ? `${comp?.parkingRatio} Per 1000 SQFT`
                        : "N/A"}
                    </h4>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <h4>Sale Comment</h4>
                    <h4 className='color-primary'>
                      {comp?.saleComment || "N/A"}
                    </h4>
                  </Grid>

                  <Grid item xs={12} lg={6}>
                    <h4>Total Trades</h4>
                    <Link to={`/admin/comp/${comp._id}/total-trades`}>
                      {" "}
                      <h2
                        className='color-primary underline'
                        style={{ display: "inline-block" }}
                      >
                        {comp?.Trades?.length}
                      </h2>
                    </Link>
                  </Grid>
                </Grid>
              </>
            )}
          </TabPanel>
        </div>
      </div>
      <ConfirmAlert
        open={open}
        message={`Are you sure you want to change status to ${
          newAlignment === 1 ? "Active" : "InActive"
        }?`}
        handleClose={handleClose}
        handleAlertSubmit={handleAlertSubmit}
      />
    </div>
  );
}
