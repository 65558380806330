import { httpClient } from "../../utils/Apis";
import { TRADE } from "../Constant";

class TradeService {
  getTrades(query) {
    return httpClient.get(`${TRADE.GET_TRADES}${query}`);
  }

  getTradesByCompId(compId, query) {
    return httpClient.get(
      `${TRADE.GET_TRADES_BY_COMPID.replace("{compId}", compId)}${query}`
    );
  }

  getTradesCompleted(userId, query) {
    return httpClient.get(
      `${TRADE.GET_TRADES_COMPLETED.replace("{userId}", userId)}${query}`
    );
  }
}

export default new TradeService();
