import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";

import "../profile/Profile.css";
import { ERROR } from "../../utils/Constant";
import { validatePassword, valideWhiteSpace } from "../../utils/Helper";
import AuthService from "../../services/component/Auth";
import { userSignOut } from "../../redux/Action";

export default function Profile() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state?.auth?.userInfo);
  const [formValues, setFormValues] = useState({
    oldPassword: "",
    password: "",
    confirmPassword: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [disabled, setDisabled] = useState(true);
  const [loader, setLoader] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (!valideWhiteSpace(value)) {
      setFormValues({ ...formValues, [name]: value });
      validate(value, name);
    }
  };

  useEffect(() => {
    if (
      Object.keys(formErrors).length !== 0 &&
      Object.values(formValues).every((x) => x) &&
      Object.values(formErrors).every((x) => !x)
    ) {
      setDisabled(false);
    }
  }, [formErrors, formValues]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (formValues?.password && formValues.confirmPassword) {
      if (formValues.password !== formValues.confirmPassword) {
        setDisabled(true);
        setFormErrors({
          ...formErrors,
          confirmPassword: ERROR.COMPARE_PASSWORD,
        });
      } else {
        //setDisabled(false);
        setFormErrors({
          ...formErrors,
          confirmPassword: "",
        });
      }
    }
  }, [formValues, setFormErrors]); //eslint-disable-line react-hooks/exhaustive-deps

  //validate input fields
  const validate = (value, name) => {
    validateField(
      value,
      name,
      validatePassword,
      ERROR.PASSWORD_REQUIRED,
      ERROR.PASSWORD
    );
  };

  const validateField = (value, name, validate, required, invalid) => {
    if (!Boolean(value)) {
      setDisabled(true);
      setFormErrors({
        ...formErrors,
        [name]: required,
      });
    } else if (!validate(value) && Boolean(value) && name === "password") {
      setDisabled(true);
      setFormErrors({ ...formErrors, [name]: invalid });
    } else {
      setFormErrors({ ...formErrors, [name]: "" });
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleShowOldPassword = () => {
    setShowOldPassword(!showOldPassword);
  };

  const handleSubmit = () => {
    setLoader(true);
    setDisabled(true);
    const body = {
      oldPassword: formValues?.oldPassword,
      newPassword: formValues?.password,
    };
    AuthService.changePassword(body)
      .then((res) => {
        if (res.status === 200) {
          toast.success(res.data.message);
          localStorage.clear();
          dispatch(userSignOut());
          navigate("/");
          setLoader(false);
          setDisabled(false);
        }
      })
      .catch((error) => {
        setLoader(false);
        setDisabled(false);
        toast.error(error.response.data.message);
      });
  };

  return (
    <div className="dashboard-container">
      <div className="dashboard-title-section">
        <div className="dashboard-title">
          <h2>My Profile</h2>
        </div>
      </div>
      <div className="dashboardContainer">
        <div className="user-profile">
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={5}>
              <Grid item xs={4}>
                <div className="user-card">
                  <h5>Name</h5>
                  <h2>{user?.name || ""}</h2>
                </div>
              </Grid>
              <Grid item xs={7}>
                <div className="user-card">
                  <h5>Email</h5>
                  <h2>{user?.email || ""}</h2>
                </div>
              </Grid>
            </Grid>
          </Box>
        </div>
      </div>
      <div className="dashboardContainer">
        <div className="user-password">
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={8}>
              <Grid item xs={8}>
                <div className="user-password-change">
                  <h5>Change Password</h5>
                </div>
              </Grid>
              <Grid item xs={3}>
                <div className="dashboard-title-section btn-link">
                  <div className="dashboard-title">
                    <div className="dashboard-btn">
                      <button
                        className="btn btn-primary"
                        disabled={disabled}
                        onClick={handleSubmit}
                      >
                        {loader ? "Loading..." : "Save"}
                      </button>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Box>
          <div className="formItem">
            <div className="formItemList">
              <Grid container spacing={8}>
                <Grid item xs={5}>
                  <FormControl fullWidth variant="outlined">
                    <TextField
                      fullWidth
                      name="oldPassword"
                      variant="outlined"
                      type={showOldPassword ? "text" : "password"}
                      label="Current Password"
                      value={formValues?.oldPassword || ""}
                      onChange={handleChange}
                      error={Boolean(formErrors?.oldPassword)}
                      helperText={formErrors?.oldPassword}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={handleShowOldPassword}>
                              {showOldPassword ? (
                                <RemoveRedEyeOutlinedIcon />
                              ) : (
                                <VisibilityOffOutlinedIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </div>
            <div className="new-pass">
              <Grid container spacing={8}>
                <Grid item xs={5}>
                  <FormControl variant="outlined" fullWidth>
                    <TextField
                      fullWidth
                      name="password"
                      variant="outlined"
                      type={showPassword ? "text" : "password"}
                      label="New Password"
                      value={formValues?.password || ""}
                      onChange={handleChange}
                      error={Boolean(formErrors?.password)}
                      helperText={formErrors?.password}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={handleClickShowPassword}>
                              {showPassword ? (
                                <RemoveRedEyeOutlinedIcon />
                              ) : (
                                <VisibilityOffOutlinedIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={5}>
                  <FormControl variant="outlined" fullWidth>
                    <TextField
                      fullWidth
                      name="confirmPassword"
                      variant="outlined"
                      type={showConfirmPassword ? "text" : "password"}
                      label="Confirm Password"
                      value={formValues?.confirmPassword || ""}
                      onChange={handleChange}
                      error={Boolean(formErrors?.confirmPassword)}
                      helperText={formErrors?.confirmPassword}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={handleShowConfirmPassword}>
                              {showConfirmPassword ? (
                                <RemoveRedEyeOutlinedIcon />
                              ) : (
                                <VisibilityOffOutlinedIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
