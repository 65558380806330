import { httpClient } from '../../utils/Apis';
import { COMP } from '../Constant';

class CompService {
  getCompList(query) {
    return httpClient.get(`${COMP.COMP_LIST}${query}`);
  }

  getCompsByBrokerId(id, query) {
    return httpClient.get(
      `${COMP.COMPS_BY_BROKERID.replace('{userId}', id)}${query}`
    );
  }

  getComp(id) {
    return httpClient.get(COMP.GET_COMP.replace('{compId}', id));
  }

  getCompsByLocation(location) {
    return httpClient.get(
      COMP.GET_COMPS_BY_LOCATION.replace('{location}', JSON.stringify(location))
    );
  }

  getTradedCompsByBrokerId(id, query) {
    return httpClient.get(
      `${COMP.GET_TRADED_COMPS.replace('{userId}', id)}${query}`
    );
  }

  activeComp(body) {
    return httpClient.post(COMP.ACTIVE_COMP, body);
  }

  uploadCsv(body) {
    return httpClient.post(COMP.CSV_UPLOAD, body);
  }
}

export default new CompService();
