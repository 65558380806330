import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import Signin from "./SignIn";
import logo from "../../assets/images/broker2broker.svg";
import dummyMobile from "../../assets/images/auth-common.png";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import "./auth.css";

export default function Auth() {
  if (localStorage.getItem("tokenInfo")) {
    return <Navigate to="../admin/dashboard" />;
  }

  return (
    <>
      <div className="container">
        <div className="leftPortion">
          <img src={dummyMobile} className="banner-img" alt="website-logo" />
          <img src={logo} className="websiteLogo" alt="website-logo" />
        </div>
        <div className="rightPortion">
          <div className="loginWrapper">
            <Routes>
              <Route path="/" element={<Signin />} />
              <Route path="forgot-password" element={<ForgotPassword />} />
              <Route path="reset-password/:token" element={<ResetPassword />} />

              <Route path="*" element={<Navigate to="/notfound" replace />} />
            </Routes>
          </div>
        </div>
      </div>
    </>
  );
}
