import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import backIcon from "../../../assets/images/back-icon.svg";
import profilePic from "../../../assets/images/profile-pic.svg";
import tickmark from "../../../assets/images/tickmark.svg";
import emailicon from "../../../assets/images/email.svg";
import BrokerService from "../../../services/component/Broker";
import Loader from "./../../../components/common/Loader";
import { CONFIG } from "../../../utils/Constant";
import ConfirmAlert from "../../../components/common/CofirmAlert";
import Comment from "./comment/Comment";
import { valideWhiteSpace } from "../../../utils/Helper";

import "../broker-detail/broker-detail.css";

export default function BrokerDetail() {
  const navigate = useNavigate();
  const params = useParams();
  const [loader, setLoader] = useState(true);
  const [broker, setBroker] = useState({});
  const [block, setBlock] = useState(0);
  const [verify, setVerify] = useState(2);
  const [newAlignment, setNewAlignment] = useState(null);
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(2);

  const [openComment, setOpenComment] = useState(false);
  const [comment, setComment] = useState("");

  useEffect(() => {
    BrokerService.getBroker(params.id)
      .then((res) => {
        if (res.status === 200) {
          const result = res.data.response;
          setBroker(result);
          setVerify(result.user.approvalStatus);
          setValue(result.user.approvalStatus);
          setBlock(result.user.isBlocked ? 1 : 0);
          setLoader(false);
        }
      })
      .catch((error) => {
        setLoader(false);
        console.log("");
      });
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  const handleTotalComps = () => {
    if (broker?.user?._id) {
      navigate(
        `../${broker?.user?._id}/total-comps?myComps=${broker?.totalComp}&tradedComps=${broker?.totalUserTrade}`
      );
    }
  };

  const handleTradeCompleted = () => {
    if (broker?.user?._id) {
      navigate(
        `../${broker?.user?._id}/trade-completed?total=${
          broker?.totalTradesCompleted || 0
        }`
      );
    }
  };

  const addDefaultSrc = (e) => {
    e.target.src = profilePic;
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleOpenComment = () => {
    setOpenComment(true);
  };

  const handleCommentChange = (event) => {
    const { value } = event.target;
    if (!valideWhiteSpace(value)) {
      setComment(value);
    }
  };

  const handleClose = () => {
    setNewAlignment(null);
    setOpen(false);
  };

  const handleCommentClose = () => {
    setNewAlignment(null);
    setOpenComment(false);
    setComment("");
  };

  const handleComment = () => {
    const body = {
      userId: broker.user._id,
      status: 3,
      comment: comment,
    };
    handleBrokerStatus(body, 3);
  };

  const handleBlock = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: number
  ) => {
    setNewAlignment(newAlignment);
    handleOpen();
  };

  const handleVerify = (
    event: React.MouseEvent<HTMLElement>,
    alignment: number
  ) => {
    try {
      //setNewAlignment(alignment);
      if (alignment === 2) {
        const body = {
          userId: broker.user._id,
          status: alignment,
        };
        handleBrokerStatus(body, 2);
      } else {
        handleOpenComment();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleSkip = () => {
    const body = {
      userId: broker.user._id,
      status: 3,
    };
    handleBrokerStatus(body, 3);
  };

  const handleBrokerStatus = (body, val) => {
    setLoader(true);
    BrokerService.brokerStatus(body)
      .then((res) => {
        if (res.status === 200) {
          toast.success(res?.data?.message || "status updated");
          setLoader(false);
          setOpenComment(false);
          setValue(val);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoader(false);
      });
  };

  const handleAlertSubmit = () => {
    setOpen(false);
    setLoader(true);
    try {
      const body = {
        userId: broker.user._id,
        status: newAlignment,
      };

      BrokerService.blockBroker(body)
        .then((res) => {
          if (res.status === 200) {
            console.log("newAlignment", newAlignment);
            toast.success(res?.data?.message || "status updated");
            setLoader(false);
            setBlock(newAlignment);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoader(false);
        });
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };

  const handleBack = () => {
    navigate(`..`);
  };

  return (
    <div className='dashboard-container'>
      <div className='dashboard-title-section btn-link'>
        <div className='dashboard-title back-link'>
          <img src={backIcon} alt='back-icon' onClick={handleBack} />
          <h2>Broker’s Detail</h2>
        </div>
        <div className='dashboard-btn'>
          {value === 2 && (
            <>
              {" "}
              <ToggleButtonGroup
                color='primary'
                value={block}
                exclusive
                onChange={handleBlock}
              >
                <ToggleButton
                  className='btn btn-danger'
                  disabled={block === 1}
                  value={1}
                >
                  Block
                </ToggleButton>
                <ToggleButton
                  className='btn btn-success'
                  disabled={block === 0}
                  value={0}
                >
                  Unblock
                </ToggleButton>
              </ToggleButtonGroup>
            </>
          )}

          {value === 1 && (
            <>
              {" "}
              <ToggleButtonGroup
                color='primary'
                value={verify}
                exclusive
                onChange={handleVerify}
              >
                <ToggleButton className='btn btn-success' value={2}>
                  Verify
                </ToggleButton>
                <ToggleButton className='btn btn-danger' value={3}>
                  Reject
                </ToggleButton>
              </ToggleButtonGroup>
            </>
          )}

          {value === 3 && (
            <>
              <ToggleButton className='btn btn-danger' disabled>
                Rejected
              </ToggleButton>
            </>
          )}
        </div>
      </div>
      {/* 
      {loader ? (
        <Loader />
      ) : (
        <> */}
      <div className='dashboardContainer profile-box'>
        {loader && <Loader />}
        <div className='profile-image'>
          <figure>
            <img
              onError={addDefaultSrc}
              src={
                !broker?.user?.profilePic
                  ? profilePic
                  : CONFIG.S3_URL + broker?.user?.profilePic
              }
              alt='profile-pic'
            />
          </figure>
        </div>
        <div className='completed-tasks-wrapper'>
          <div className='tasks-completed'>
            <h3>
              {broker?.totalComp +
                broker?.totalUserTrade +
                broker?.totalSharedAccessComp}
            </h3>
            <h4 className='pointer' onClick={handleTotalComps}>
              Total Comps
            </h4>
          </div>
          <div className='tasks-completed'>
            <h3>{broker?.totalTradesCompleted || 0}</h3>
            <h4 className='pointer' onClick={handleTradeCompleted}>
              Trades Completed
            </h4>
          </div>
          <div className='tasks-completed' style={{ opacity: "0.3" }}>
            <h3>0</h3>
            <h4>Total Payments</h4>
          </div>
        </div>
      </div>

      <div className='dashboardContainer lic'>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} lg={3}>
            <h3>Name</h3>
            <h4 className='color-primary'>{broker?.user?.name || "N/A"}</h4>
          </Grid>
          <Grid item xs={12} sm={12} lg={4}>
            <h3>
              Mobile Number
              {broker?.user?.mobile ? (
                broker?.user?.isMobileVerified ? (
                  <img src={tickmark} alt='tickmark' />
                ) : (
                  <img src={emailicon} alt='email' />
                )
              ) : (
                ""
              )}
            </h3>
            <h4 className='color-primary'>{broker?.user?.mobile || "N/A"}</h4>
          </Grid>
          <Grid item xs={12} sm={12} lg={5}>
            <h3>
              Email Address
              {broker?.user?.email ? (
                broker?.user?.isEmailVerified ? (
                  <img src={tickmark} alt='tickmark' />
                ) : (
                  <img src={emailicon} alt='email' />
                )
              ) : (
                ""
              )}
            </h3>
            <h4 className='color-primary'>{broker?.user?.email || "N/A"}</h4>
          </Grid>
          <Grid item xs={12} sm={12} lg={3} style={{ opacity: "0.3" }}>
            <h3>Subscription</h3>
            <h4 className='color-primary'>Monthly</h4>
            <h6 className='color-primary bold mt-10'>
              <i>Subscription History</i>
            </h6>
          </Grid>
          <Grid item xs={12} sm={12} lg={4}>
            <h3>Address</h3>
            <h4 className='color-primary'>
              {broker?.user
                ? broker?.user?.suite
                  ? `${broker.user.zipCode} ${broker.user.addressLine} ${broker.user.suite}${broker.user.city}, ${broker.user.state}`
                  : `${broker.user.zipCode} ${broker.user.addressLine} ${broker.user.city}, ${broker.user.state}`
                : "N/A"}
            </h4>
          </Grid>
          <Grid item xs={12} sm={12} lg={5}>
            <h3>Lic Information</h3>
            {broker?.user?.LIC.map((lic) => (
              <Grid
                container
                columnSpacing={3}
                rowSpacing={5}
                key={lic._id}
                className='mb-20'
              >
                <Grid item xs={4} sm={4} lg={4}>
                  <h4>Lic Number</h4>
                  <h4 className='color-primary'>{lic?.licNumber || "N/A"}</h4>
                </Grid>
                <Grid item xs={4} sm={4} lg={4}>
                  <h4>State</h4>
                  <h4 className='color-primary'>{lic?.state || "N/A"}</h4>
                </Grid>
                <Grid item xs={4} sm={4} lg={4}>
                  <h4>Expiry Date</h4>
                  <h4 className='color-primary'>{`${lic.expiryMonth} / ${lic.expiryYear}`}</h4>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </div>

      <ConfirmAlert
        open={open}
        message={`Are you sure you want to ${
          newAlignment === 1 ? "Block" : "UnBlock"
        }?`}
        handleClose={handleClose}
        handleAlertSubmit={handleAlertSubmit}
      />

      <Comment
        open={openComment}
        comment={comment}
        handleClose={handleCommentClose}
        handleComment={handleComment}
        handleCommentChange={handleCommentChange}
        handleSkip={handleSkip}
      />
    </div>
  );
}
