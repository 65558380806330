import { httpClient } from "../../utils/Apis";
import { AUTH } from "../Constant";

class AuthService {
  signinUser(user) {
    return httpClient.post(AUTH.SIGNIN, user);
  }

  forgotPasssword(email) {
    return httpClient.post(AUTH.FORGOT_PASSWORD, email);
  }

  resetPassword(token, password) {
    return httpClient.post(
      AUTH.RESET_PASSWORD.replace("{token}", token),
      password
    );
  }

  refreshToken(body) {
    return httpClient.post(AUTH.REFRESH_TOKEN, body);
  }

  changePassword(body) {
    return httpClient.post(AUTH.CHANGE_PASSWORD, body);
  }
}

export default new AuthService();
