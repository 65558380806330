import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import NotificationListing from "./notification-listing/NotificationListing";
import AddNotification from "./addNotification/AddNotification";
import NotificationDetail from "./notification-detail/NotificationDetail";
import EditNotification from "../notification/edit-notification/EditNotification";

export default function NotificationRoute() {
  return (
    <Routes>
      <Route path="/" element={<NotificationListing />} />
      <Route path="/add-notification" element={<AddNotification />} />
      <Route path="/edit/:id" element={<EditNotification />} />
      <Route path="/:id" element={<NotificationDetail />} />
      <Route path="*" element={<Navigate to="/notfound" replace />} />
    </Routes>
  );
}
