import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { ERROR } from "../../..//utils/Constant";
import backIcon from "../../../assets/images/back-icon.svg";
import BrokerService from "../../../services/component/Broker";
import {
  validateEmail,
  validatePassword,
  formatPhoneNumber,
  validatePhoneNumber,
  cleanObject,
  numberFormat,
} from "../../../utils/Helper";

export default function AddBroker() {
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState({
    mobile: "",
    email: "",
    password: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [disabled, setDisabled] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (
      Object.keys(formErrors).length !== 0 &&
      (formValues["email"] || formValues["mobile"]) &&
      formValues["password"] &&
      Object.values(formErrors).every((x) => !x)
    ) {
      setDisabled(false);
    }
  }, [formErrors, formValues]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "mobile") {
      const number = formatPhoneNumber(value);
      setFormValues({ ...formValues, [name]: number });
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
    validate(value, name);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const validate = (value, name) => {
    switch (name) {
      case "mobile":
        validateField(
          value,
          name,
          validatePhoneNumber,
          ERROR.MOBILE_REQUIRED,
          ERROR.INVALID_MOBILE
        );

        break;

      case "email":
        validateField(
          value,
          name,
          validateEmail,
          ERROR.EMAIL_REQUIRED,
          ERROR.INVALID_EMAIL
        );

        break;

      default:
        validateField(
          value,
          name,
          validatePassword,
          ERROR.PASSWORD_REQUIRED,
          ERROR.PASSWORD
        );
    }
  };

  const validateField = (value, name, validate, required, invalid) => {
    if (!Boolean(value) && name !== "mobile") {
      setDisabled(true);
      setFormErrors({
        ...formErrors,
        [name]: required,
      });
    } else if (!validate(value) && Boolean(value)) {
      setDisabled(true);
      setFormErrors({ ...formErrors, [name]: invalid });
    } else {
      setFormErrors({ ...formErrors, [name]: "" });
    }
  };

  const handleSubmit = () => {
    setLoader(true);
    setDisabled(true);
    const obj = cleanObject(formValues);
    const body = { ...obj };
    if (body.mobile) {
      body.mobile = numberFormat(body.mobile);
    }

    BrokerService.addBroker(body)
      .then((res) => {
        if (res.status === 200) {
          toast.success(res?.data?.message);
          setLoader(false);
          setDisabled(false);
          navigate("../");
        }
      })
      .catch((error) => {
        setLoader(false);
        setDisabled(false);
        toast.error(error?.response?.data?.message || "somethning went wrong");
      });
  };

  return (
    <div className="dashboard-container">
      <div className="dashboard-title-section">
        <div className="dashboard-title back-link">
          <img
            src={backIcon}
            alt="back-icon"
            style={{ marginRight: "14px" }}
            onClick={() => navigate(-1)}
          />
          <h2>Add Broker</h2>
        </div>
      </div>
      <div className="formIn formItem add-details-info">
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <h3 className="mt-10">Mobile Number</h3>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={8}>
            <TextField
              fullWidth
              label="Enter Mobile Number"
              variant="outlined"
              name="mobile"
              value={formValues?.mobile || ""}
              onChange={handleChange}
              error={Boolean(formErrors?.mobile)}
              helperText={formErrors?.mobile}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <h3 className="mt-10 required">Email</h3>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={8}>
            <TextField
              fullWidth
              name="email"
              label="Enter Email"
              variant="outlined"
              value={formValues?.email || ""}
              onChange={handleChange}
              error={Boolean(formErrors?.email)}
              helperText={formErrors?.email}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={4}>
            <h3 className="mt-10 required">Password</h3>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={8}>
            <TextField
              fullWidth
              type={showPassword ? "text" : "password"}
              name="password"
              value={formValues?.password || ""}
              onChange={handleChange}
              error={Boolean(formErrors?.password)}
              helperText={formErrors?.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      className="color-secondary"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              label="Password"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4}></Grid>
          <Grid item xs={12} sm={12} md={12} lg={8} className="center">
            <button
              className="btn btn-primary mt-30"
              disabled={disabled}
              onClick={handleSubmit}
            >
              {loader ? "Saving..." : "Save"}
            </button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
