import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import "../../Listing/listing.css";
import SubscriptionService from "../../../services/component/Subscription";
import Loader from "./../../../components/common/Loader";

export default function SubscriptionListing() {
  const navigate = useNavigate();
  const [plans, setPlans] = useState([]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    getPlans();
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  const getPlans = () => {
    setLoader(true);
    SubscriptionService.getSubscriptions()
      .then((res) => {
        if (res.status === 201) {
          setLoader(false);
          setPlans(res?.data?.response);
        }
      })
      .catch((error) => {
        setLoader(false);
        console.log("getPlansError", error);
      });
  };

  // const handleAddSubscription = () => {
  //   navigate(`../add-subscription`);
  // };
  const goToSubscriptionDetail = (id) => {
    navigate(`../edit/${id}`);
  };

  return (
    <div className="page-listing">
      <div className="space-between">
        <h1 className="bold">Subscription Management</h1>
        {/* <button className='btn btn-primary' onClick={handleAddSubscription}>
          Add
        </button> */}
      </div>
      <div className="listing-filter space-between mt-20 mb-20"></div>

      <div className="table-cover">
        <Table aria-label="simple table" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Tenure</TableCell>
              <TableCell>Total Price($)</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!loader &&
              plans &&
              plans.length > 0 &&
              plans.map((plan, index) => (
                <TableRow
                  className="pointer"
                  key={plan._id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  onClick={() => goToSubscriptionDetail(plan._id)}
                >
                  <TableCell>{index + 1}</TableCell>
                  <TableCell className="for-subs">{plan?.name || ""}</TableCell>
                  <TableCell>{plan?.duration || ""}</TableCell>
                  <TableCell>{plan?.price || ""}</TableCell>
                  <TableCell className="for-subs">
                    {plan?.benefits &&
                      plan?.benefits?.length > 0 &&
                      plan?.benefits.map((benefit, i) => (
                        <p key={`${plan?._id}-${i}`}>{benefit}</p>
                      ))}{" "}
                  </TableCell>
                  <TableCell>{plan?.activeStatus || ""}</TableCell>
                </TableRow>
              ))}

            {!loader && plans.length === 0 && (
              <TableRow>
                <TableCell
                  colSpan={9}
                  style={{
                    padding: "120px 0px",
                    color: "#333",
                    fontSize: "24px",
                  }}
                >
                  No Records Found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {loader && <Loader />}
      </div>
    </div>
  );
}
