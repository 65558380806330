import React, { useState } from "react";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import CloseIcon from "@mui/icons-material/Close";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

import { incrementDate } from "../../../utils/Helper";
import "../../Listing/Filter/filter.css";

export default function TradeFilter({
  handleFilter,
  handleClose,
  handleClear,
  toSend,
  fromSend,
  toRedeem,
  fromRedeem,
  type,
}) {
  const [formValues, setFormValues] = useState({
    toSend: toSend,
    fromSend: fromSend,
    toRedeem: toRedeem,
    fromRedeem: fromRedeem,
    type: type,
  });

  const handleChange = (e) => {
    const { value, name } = e.target;

    if (name === "fromSend") {
      setFormValues({ ...formValues, toSend: null, [name]: value });
    } else if (name === "fromRedeem") {
      setFormValues({ ...formValues, toRedeem: null, [name]: value });
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
  };

  const handleApply = () => {
    handleFilter(formValues);
    handleClose();
  };
  const handleClearButton = () => {
    setFormValues({
      toSend: null,
      fromSend: null,
      toRedeem: null,
      fromRedeem: null,
      type: 0,
    });
    handleClear();
  };

  return (
    <div className="filter-cover">
      <div className="filter-header">
        <h2>Filter</h2>
        <CloseIcon className="close-icon" title="Close" onClick={handleClose} />
      </div>
      <div className="filter-body" style={{ minHeight: "305px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <h4 className="subtext mb-5">Send Date</h4>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <form autoComplete="off" style={{ position: "relative" }}>
                  <DatePicker
                    onChangeRaw={(e) => e.preventDefault()}
                    selected={formValues?.fromSend || ""}
                    name="fromSend"
                    placeholderText="From"
                    onChange={(e) =>
                      handleChange({
                        target: { name: "fromSend", value: e },
                      })
                    }
                  />
                  <CalendarMonthIcon
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "50%",
                      transform: "translateY(-55%)",
                      pointerEvents: "none",
                      color: "#666666",
                    }}
                  />
                </form>
              </Grid>
              <Grid item xs={6}>
                <form autoComplete="off" style={{ position: "relative" }}>
                  <DatePicker
                    onChangeRaw={(e) => e.preventDefault()}
                    selected={formValues?.toSend || ""}
                    name="toSend"
                    minDate={
                      formValues?.fromSend
                        ? incrementDate(formValues?.fromSend, 1)
                        : "" || null
                    }
                    placeholderText="To"
                    onChange={(e) =>
                      handleChange({
                        target: { name: "toSend", value: e },
                      })
                    }
                  />
                  <CalendarMonthIcon
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "50%",
                      transform: "translateY(-55%)",
                      pointerEvents: "none",
                      color: "#666666",
                    }}
                  />
                </form>
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid item xs={12}>
            <h4 className="subtext mb-5">Redeem Date</h4>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <form autoComplete="off" style={{ position: "relative" }}>
                  <DatePicker
                    onChangeRaw={(e) => e.preventDefault()}
                    selected={formValues?.fromRedeem || ""}
                    name="fromRedeem"
                    placeholderText="From"
                    onChange={(e) =>
                      handleChange({
                        target: { name: "fromRedeem", value: e },
                      })
                    }
                  />
                  <CalendarMonthIcon
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "50%",
                      transform: "translateY(-55%)",
                      pointerEvents: "none",
                      color: "#666666",
                    }}
                  />
                </form>
              </Grid>
              <Grid item xs={6}>
                <form autoComplete="off" style={{ position: "relative" }}>
                  <DatePicker
                    onChangeRaw={(e) => e.preventDefault()}
                    selected={formValues?.toRedeem || ""}
                    name="toRedeem"
                    minDate={
                      formValues?.fromRedeem
                        ? incrementDate(formValues?.fromRedeem, 1)
                        : "" || null
                    }
                    placeholderText="To"
                    onChange={(e) =>
                      handleChange({
                        target: { name: "toRedeem", value: e },
                      })
                    }
                  />
                  <CalendarMonthIcon
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "50%",
                      transform: "translateY(-55%)",
                      pointerEvents: "none",
                      color: "#666666",
                    }}
                  />
                </form>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <h4 className="subtext mb-5">Status</h4>
            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="type"
                value={formValues?.type}
                onChange={handleChange}
              >
                <MenuItem value={0}>ALL</MenuItem>
                <MenuItem value={1}>Pending</MenuItem>
                <MenuItem value={2}>Redeem</MenuItem>
                <MenuItem value={2}>Expired</MenuItem>
              </Select>
            </FormControl>
          </Grid> */}
        </Grid>
      </div>
      <div className="filter-footer">
        <button
          className="btn btn-primary-border mr-30"
          onClick={handleClearButton}
        >
          Clear All
        </button>
        <button className="btn btn-primary" onClick={handleApply}>
          Apply
        </button>
      </div>
    </div>
  );
}
