import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import { ERROR } from "../../utils/Constant";
import { validateEmail } from "../../utils/Helper";
import AuthService from "../../services/component/Auth";
import backIcon from "../../assets/images/back-icon.svg";

export default function ForgotPassword() {
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState({ email: "" });
  const [formErrors, setFormErrors] = useState({});
  const [disabled, setDisabled] = useState(true);
  const [loader, setLoader] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    validateField(value, name);
  };

  const handleSubmit = () => {
    setLoader(true);
    setDisabled(true);
    AuthService.forgotPasssword({ email: formValues.email })
      .then((res) => {
        if (res.status === 200) {
          setFormValues({ email: "" });
          navigate("../");
          toast.success(res.data.message);
          setLoader(false);
          setDisabled(true);
        }
      })
      .catch((error) => {
        setLoader(false);
        setDisabled(false);
        toast.error(error.response.data.message);
      });
  };

  useEffect(() => {
    if (
      Object.keys(formErrors).length !== 0 &&
      Object.values(formValues).every((x) => x) &&
      Object.values(formErrors).every((x) => !x)
    ) {
      setDisabled(false);
    }
  }, [formErrors, formValues]);

  const validateField = (value, name) => {
    if (!Boolean(value)) {
      setDisabled(true);
      setFormErrors({
        ...formErrors,
        [name]: ERROR.EMAIL_REQUIRED,
      });
    } else if (!validateEmail(value) && Boolean(value)) {
      setDisabled(true);
      setFormErrors({ ...formErrors, [name]: ERROR.INVALID_EMAIL });
    } else {
      setFormErrors({ ...formErrors, [name]: "" });
    }
  };

  const handleBack = () => {
    navigate("../");
  };

  return (
    <div className="page-forgot-password">
      <span className="back-icon mb-20" onClick={handleBack}>
        <img src={backIcon} alt="back-icon" />
      </span>
      <h2>Forgot Password?</h2>
      <h3>We’ll help you reset it and get back on track</h3>
      <div className="formIn">
        <div className="formItem">
          <TextField
            fullWidth
            name="email"
            variant="outlined"
            label="Email"
            value={formValues?.email || ""}
            onChange={handleChange}
            error={Boolean(formErrors?.email)}
            helperText={formErrors?.email}
          />
        </div>
      </div>
      <button
        className="btn btn-primary mt-30 w-100"
        disabled={disabled}
        onClick={handleSubmit}
      >
        {!loader ? "Submit" : "Loading..."}
      </button>
    </div>
  );
}
