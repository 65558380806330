import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import FilterListIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate, useParams } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import Dialog from "@mui/material/Dialog";
import { validateNumber } from "../../../utils/Helper";
import Loader from "./../../../components/common/Loader";
import CompService from "../../../services/component/Comp";
import { propertyType, levelType } from "../../../utils/SwitchCase";
import CompFilter from "../../comp/comp-filter/CompFilter";

export default function TradedComps() {
  const navigate = useNavigate();
  const params = useParams();

  const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState(true);
  const [comps, setComps] = useState([]);
  const [limit, setLimit] = useState(10);
  const [query, setQuery] = useState("");

  const [category, setCategory] = useState(0);
  const [status, setStatus] = useState(0);
  const [property, setProperty] = useState(0);
  const [level, setLevel] = useState(0);
  const [type, setType] = useState(0);

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);

  useEffect(() => {
    let queryParam = `?page=${page}&&limit=${limit}`;
    getComps(queryParam);
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setQueryParams();
  }, [query]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setQueryParams();
  }, [limit]); //eslint-disable-line react-hooks/exhaustive-deps

  const setQueryParams = () => {
    let queryParam = `?page=${page}&&limit=${limit}&&search=${query}`;
    if (query) {
      getComps(queryParam);
    } else {
      queryParam = `?page=${page}&&limit=${limit}`;
      getComps(queryParam);
    }
  };

  const getComps = (queryParam) => {
    setLoader(true);
    if (category !== 0) {
      queryParam = queryParam + `&&type=${category}`;
    }

    if (type !== 0) {
      queryParam = queryParam + `&&category=${type}`;
    }

    if (level !== 0) {
      queryParam = queryParam + `&&level=${level}`;
    }

    if (status !== 0) {
      queryParam = queryParam + `&&status=${status}`;
    }

    if (property !== 0) {
      queryParam = queryParam + `&&propertyType=${property}`;
    }

    CompService.getTradedCompsByBrokerId(params.id, queryParam)
      .then((res) => {
        if (res.status === 200) {
          setLoader(false);
          setTotalPages(res?.data?.response?.totalPages);
          setComps(res.data.response.results);
          setTotalRecords(res?.data?.response?.totalResults);
        }
      })
      .catch((error) => {
        setLoader(false);
        console.log("getCompsError");
      });
  };

  const getFilterdComps = (queryParam) => {
    setLoader(true);

    CompService.getTradedCompsByBrokerId(params.id, queryParam)
      .then((res) => {
        if (res.status === 200) {
          setLoader(false);
          setTotalPages(res?.data?.response?.totalPages);
          setComps(res.data.response.results);
          setTotalRecords(res?.data?.response?.totalResults);
        }
      })
      .catch((error) => {
        setLoader(false);
        console.log("getFilterdComps");
      });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleQuery = (event) => {
    setQuery(event.target.value);
  };

  const handleSearch = () => {
    setQuery("");
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
    let queryParam = "";

    if (query) {
      queryParam = `?page=${value}&&limit=${limit}&&search=${query}`;
      getComps(queryParam);
    } else {
      queryParam = `?page=${value}&&limit=${limit}`;
      getComps(queryParam);
    }
  };

  const handleLimitChange = (event) => {
    const value = event.target.value;
    if (value === "" || validateNumber(value)) {
      setLimit(value);
    }
  };

  const handleFilter = (values) => {
    setCategory(values.category);
    setStatus(values.status);
    setProperty(values.property);
    setLevel(values.level);
    setType(values.type);

    let queryParam = `?page=${page}&&limit=${limit}`;
    if (query) {
      queryParam = `?page=${page}&&limit=${limit}&&search=${query}`;
    }
    if (values.category !== 0) {
      queryParam = queryParam + `&&type=${values.category}`;
    }
    if (values.type !== 0) {
      queryParam = queryParam + `&&category=${values.type}`;
    }

    if (values.level !== 0) {
      queryParam = queryParam + `&&level=${values.level}`;
    }

    if (values.status !== 0) {
      queryParam = queryParam + `&&status=${values.status}`;
    }

    if (values.property !== 0) {
      queryParam = queryParam + `&&propertyType=${values.property}`;
    }

    getFilterdComps(queryParam);
  };

  const handleClear = () => {
    setCategory(0);
    setStatus(0);
    setProperty(0);
    setLevel(0);
    setType(0);
    handleClose();
    let queryParam = `?page=${page}&&limit=${limit}`;
    if (query) {
      queryParam = `?page=${page}&&limit=${limit}&&search=${query}`;
    }
    getFilterdComps(queryParam);
  };

  const goToCompDetail = (id) => {
    navigate(`../../comp/${id}`);
  };

  return (
    <>
      <div className="justify-end mb-20" style={{ marginTop: "-65px" }}>
        <div className="search mr-20">
          <TextField
            className="search-box"
            fullWidth
            id="outlined-search"
            type="search"
            onChange={handleQuery}
            placeholder="Search by Address or Company"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon
                    className="search-icon pointer"
                    onClick={handleSearch}
                  ></SearchIcon>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <button
          className="btn btn-primary btn-filter"
          onClick={handleClickOpen}
        >
          <FilterListIcon fontSize="large" />
        </button>
      </div>

      <div className="table-cover">
        <Table aria-label="simple table" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Address</TableCell>
              <TableCell>Owner</TableCell>
              <TableCell>Property Type</TableCell>
              <TableCell>Level</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!loader &&
              comps.length > 0 &&
              comps.map((comp, index) => (
                <TableRow
                  key={comp._id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  onClick={() => goToCompDetail(comp._id)}
                >
                  <TableCell>{comp?._id || ""}</TableCell>

                  <TableCell>{comp.type === 1 ? "Lease" : "Sale"}</TableCell>
                  <TableCell title={comp?.fullAddress || ""}>
                    {comp?.fullAddress || ""}
                  </TableCell>

                  <TableCell>{comp?.ownerDetail?.name || ""}</TableCell>
                  <TableCell>
                    {comp?.propertyType ? propertyType(comp.propertyType) : ""}
                  </TableCell>
                  <TableCell>
                    {comp?.level ? levelType(comp.level) : ""}
                  </TableCell>
                  <TableCell>
                    {`${comp?.isLocked === true ? "Locked" : "Unlocked"}/${
                      comp?.isActive === true ? "Active" : "Inactive"
                    }`}
                  </TableCell>
                </TableRow>
              ))}

            {!loader && comps.length === 0 && (
              <TableRow>
                <TableCell
                  colSpan={9}
                  style={{
                    padding: "120px 0px",
                    color: "#333",
                    fontSize: "24px",
                  }}
                >
                  No Records Found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {loader && <Loader />}
      </div>
      <div className="pagination-cover">
        <Pagination
          count={totalPages}
          variant="outlined"
          shape="rounded"
          onChange={handlePageChange}
        />
        <TextField
          name="limit"
          variant="standard"
          value={limit}
          onChange={handleLimitChange}
          style={{ width: "80px", margin: "0px 20px" }}
        />
        <h3>
          {comps?.length !== 0 ? comps?.length : "0"} of {totalRecords}
        </h3>
      </div>

      <Dialog open={open}>
        <CompFilter
          handleFilter={handleFilter}
          category={category}
          status={status}
          property={property}
          level={level}
          type={type}
          handleClose={handleClose}
          handleClear={handleClear}
          show={false}
        />
      </Dialog>
    </>
  );
}
