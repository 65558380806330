import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import InputAdornment from "@mui/material/InputAdornment";
import FilterListIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Dialog from "@mui/material/Dialog";
import Pagination from "@mui/material/Pagination";
import { toast } from "react-toastify";

import Loader from "../../../components/common/Loader";
import backIcon from "../../../assets/images/back-icon.svg";
import NotificationService from "../../../services/component/Notification";
import { validateNumber, valideWhiteSpace } from "../../../utils/Helper";
import { brokerStatus, recipientStatus } from "../../../utils/SwitchCase";
import BrokerFilter from "../../broker/broker-filter/BrokerFilter";

export default function NotificationDetail() {
  const params = useParams();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(true);
  const [users, setUsers] = useState([]);

  const [limit, setLimit] = useState(10);
  const [query, setQuery] = useState("");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [notification, setNotification] = useState({});
  const [open, setOpen] = useState(false);

  const [company, setCompany] = useState("");
  const [address, setAddress] = useState("");
  const [status, setStatus] = useState(0);
  const [submit, setSubmit] = useState(false);

  useEffect(() => {
    const queryParam = `?page=${page}&&limit=${limit}`;
    getNotification(queryParam);
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setQueryParams();
  }, [query, limit]); //eslint-disable-line react-hooks/exhaustive-deps

  const setQueryParams = () => {
    let queryParam = `?page=${page}&&limit=${limit}&&search=${query}`;
    if (query) {
      getNotification(queryParam);
    } else {
      queryParam = `?page=${page}&&limit=${limit}`;
      getNotification(queryParam);
    }
  };

  const getNotification = (queryParam) => {
    setLoader(true);
    NotificationService.getNotification(params.id, queryParam)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.response.notification.recipient === 4) {
            setUsers(res.data.response.users.results);
            setTotalPages(res?.data?.response?.users?.totalPages);
            setTotalRecords(res?.data?.response?.users?.totalResults);
          }
          setNotification(res.data.response.notification);
          setLoader(false);
        }
      })
      .catch((error) => {
        setLoader(false);
        console.log("getNotificationError");
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClear = () => {
    setCompany("");
    setStatus(0);
    setAddress("");
    //setType(0);
    handleClose();

    let queryParam = `?page=${page}&&limit=${limit}`;
    if (query) {
      queryParam = `?page=${page}&&limit=${limit}&&search=${query}`;
    }
    getNotification(queryParam);
  };

  const handleFilter = (values) => {
    setCompany(values.company);
    setStatus(values.status);

    setAddress(values.address);

    let queryParam = `?page=${page}&&limit=${limit}`;
    if (query) {
      queryParam = `?page=${page}&&limit=${limit}&&search=${query}`;
    }
    if (values.company) {
      queryParam = queryParam + `&&companyName=${values.company}`;
    }
    if (values.address) {
      queryParam = queryParam + `&&fullAddress=${values.address}`;
    }

    if (values.status !== 0) {
      queryParam = queryParam + `&&approvalStatus=${values.status}`;
    }

    getNotification(queryParam);
  };

  const handleQuery = (event) => {
    const { value } = event.target;
    if (!valideWhiteSpace(value)) {
      setQuery(value);
    }
  };

  const handleClearQuery = () => {
    setQuery("");
  };

  const handlePageChange = (event, value) => {
    setPage(value);
    let queryParam = "";

    if (query) {
      queryParam = `?page=${value}&&limit=${limit}&&search=${query}`;
      getNotification(queryParam);
    } else {
      queryParam = `?page=${value}&&limit=${limit}`;
      getNotification(queryParam);
    }
  };

  const handleLimitChange = (event) => {
    const value = event.target.value;
    if (value === "" || validateNumber(value)) {
      setLimit(value);
    }
  };
  const handleBack = () => {
    navigate("/admin/notification");
  };

  const handleEdit = (id) => {
    navigate(`/admin/notification/edit/${id}`);
  };

  const handleSend = () => {
    const body = {
      title: notification.title,
      description: notification.description,
      recipient: notification.recipient,
    };

    if (body.recipient === 4) {
      body.users = notification.receiverIds;
    }

    setSubmit(true);
    NotificationService.addNotification(body)
      .then((res) => {
        if (res.status === 200) {
          setSubmit(false);
          toast.success(res.data.message);
          navigate("/admin/notification");
        }
      })
      .catch((error) => {
        setSubmit(false);
        console.log("addNotificationError");
      });
  };

  return (
    <div className="dashboard-container">
      <div className="dashboard-title-section">
        <div className="dashboard-title back-link">
          <a href="javascript:void(0)">
            <img src={backIcon} alt="back-icon" onClick={handleBack} />
          </a>
          <h2>Notification Detail</h2>
        </div>
      </div>
      <div className="formItem add-details-info">
        {loader && <Loader />}
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <h3>Title</h3>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={8}>
            <TextField
              fullWidth
              disabled
              variant="outlined"
              value={notification?.title || ""}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <h3>Description</h3>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={8}>
            <TextField
              fullWidth
              multiline
              rows={4}
              disabled
              variant="outlined"
              value={notification?.description || ""}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={4}>
            <h3>Recipient</h3>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <h1>
              {" "}
              {notification?.recipient
                ? recipientStatus(notification?.recipient)
                : ""}
            </h1>
          </Grid>
        </Grid>
      </div>
      {notification?.recipient === 4 && (
        <div className="notification-table">
          <div className="listing-filter space-between mt-20 mb-20">
            <div className="justify-start">
              <div className="search mr-20">
                <TextField
                  className="search-box"
                  fullWidth
                  id="outlined-search"
                  type="search"
                  value={query}
                  onChange={handleQuery}
                  placeholder="Search by Name"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon
                          className="search-icon pointer"
                          onClick={handleClearQuery}
                        ></SearchIcon>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>
            <button
              className="btn btn-primary btn-filter"
              onClick={handleClickOpen}
            >
              <FilterListIcon fontSize="large" />
            </button>
          </div>

          <div className="table-cover">
            <Table aria-label="simple table" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Mobile Number</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Company</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!loader &&
                  users &&
                  users.length > 0 &&
                  users.map((user, index) => (
                    <TableRow
                      key={user._id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell>{user?._id || ""}</TableCell>
                      <TableCell>{user?.name || ""}</TableCell>
                      <TableCell>{user?.mobile || ""}</TableCell>
                      <TableCell>{user?.email || ""}</TableCell>
                      <TableCell>{user?.companyName || ""}</TableCell>
                      <TableCell>
                        {user?.approvalStatus
                          ? brokerStatus(user.approvalStatus)
                          : ""}
                      </TableCell>
                    </TableRow>
                  ))}

                {!loader && users && users.length === 0 && (
                  <TableRow>
                    <TableCell
                      colSpan={9}
                      style={{
                        padding: "120px 0px",
                        color: "#333",
                        fontSize: "24px",
                      }}
                    >
                      No Records Found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
          <div className="pagination-cover">
            <Pagination
              count={totalPages}
              variant="outlined"
              shape="rounded"
              onChange={handlePageChange}
            />
            <TextField
              name="limit"
              variant="standard"
              value={limit}
              onChange={handleLimitChange}
              style={{ width: "80px", margin: "0px 20px" }}
            />
            <h3>
              {users && users?.length !== 0 ? users?.length : "0"} of{" "}
              {totalRecords}
            </h3>
          </div>
        </div>
      )}

      <div className="dashboard-btn subscription-detail">
        <button
          className="btn btn-white mr-10"
          onClick={handleSend}
          disabled={submit}
        >
          {submit ? "Loading..." : "Send"}
        </button>
        <button
          className="btn btn-primary"
          onClick={() => handleEdit(params.id)}
        >
          Edit
        </button>
      </div>

      <Dialog open={open}>
        <BrokerFilter
          handleFilter={handleFilter}
          company={company}
          status={status}
          address={address}
          handleClose={handleClose}
          handleClear={handleClear}
        />
      </Dialog>
    </div>
  );
}
