import React, { useState, useEffect } from "react";

import DashboardService from "../../services/component/Dashboard";
import Loader from "../../components/common/Loader";
import "./dashboard.css";

export default function Dashboard() {
  const [dashboard, setDashboard] = useState({});

  const [loader, setLoader] = useState(true);

  useEffect(() => {
    getDashboardInfo();
  }, []);

  const getDashboardInfo = () => {
    DashboardService.getDashboard()
      .then((res) => {
        if (res.status === 200) {
          setDashboard(res.data.response);
          setLoader(false);
        }
      })
      .catch((error) => {
        setLoader(false);
        console.log("getDashboardInfoError");
      });
  };

  const calculatePercent = (num, total) => {
    return ((num / total) * 100).toFixed(2);
  };

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <div className="dashboard-container">
          <h1 className="bold">Dashboard</h1>
          <ul className="counters-list">
            <li className="counter-card">
              <h4>Total Brokers</h4>
              <h1>{dashboard?.allUsersCount}</h1>
            </li>
            <li className="counter-card">
              <h4>Brokers in app in last 7 days</h4>
              <h1>
                {dashboard?.lastSevenDaysAddedUsersCount} (
                {calculatePercent(
                  dashboard?.lastSevenDaysAddedUsersCount,
                  dashboard?.allUsersCount
                )}
                %)
              </h1>
            </li>
            <li className="counter-card">
              <h4>Brokers who have ever traded</h4>
              <h1>
                {dashboard?.userWhoTradedCount} (
                {calculatePercent(
                  dashboard?.userWhoTradedCount,
                  dashboard?.allUsersCount
                )}
                %)
              </h1>
            </li>
            <li className="counter-card">
              <h4>Brokers who have ever traded in last 7 days</h4>
              <h1>
                {dashboard?.userWhoTradedLastSevenDaysCount}(
                {calculatePercent(
                  dashboard?.userWhoTradedLastSevenDaysCount,
                  dashboard?.userWhoTradedCount
                )}
                %){" "}
              </h1>
            </li>
            <li className="counter-card">
              <h4>Total Comps</h4>
              <h1>{dashboard?.allCompsCount}</h1>
            </li>
            <li className="counter-card">
              <h4>Comps added in last 7 days</h4>
              <h1>{dashboard?.lastSevenDaysAddedCompsCount}</h1>
            </li>
            <li className="counter-card">
              <h4>Trades all time</h4>
              <h1>{dashboard?.allTradesCompletedCount}</h1>
            </li>
            <li className="counter-card">
              <h4>Trades in last 7 days</h4>
              <h1>{dashboard?.lastSevenDaysCompletedTradesCount}</h1>
            </li>
            <li className="counter-card">
              <h4>Requests all time</h4>
              <h1>{dashboard?.allRequestsCompletedCount}</h1>
            </li>
            <li className="counter-card">
              <h4>Requests in last 7 days</h4>
              <h1>{dashboard?.lastSevenDaysCompletedRequestsCount}</h1>
            </li>
          </ul>
        </div>
      )}
    </>
  );
}
