import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs-plugin-utc";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Pagination from "@mui/material/Pagination";
import TextField from "@mui/material/TextField";
import TableSortLabel from "@mui/material/TableSortLabel";

import ReportService from "../../services/component/Reports";
import Loader from "../../components/common/Loader";
import { validateNumber } from "../../utils/Helper";

dayjs.extend(dayjsPluginUTC);

export default function UserInfo() {
  const [loader, setLoader] = useState(true);
  const [users, setUsers] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [firstRender, setFirstRender] = useState(true);
  const [download, setDownload] = useState(false);
  const [activeInvitedOn, setActiveInvitedOn] = useState(false);
  const [directionInvitedOn, setDirectionInvitedOn] = useState("");

  const [activeInvitationAcceptedOn, setActiveInvitationAcceptedOn] =
    useState(false);
  const [directionInvitationAcceptedOn, setDirectionInvitationAcceptedOn] =
    useState("");

  useEffect(() => {
    const queryParam = `?page=${page}&&limit=${limit}`;
    getUserInfo(queryParam);
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!firstRender) {
      let queryParam = `?page=${page}`;
      if (limit) {
        queryParam = `${queryParam}&&limit=${limit}`;
      }
      if (directionInvitedOn) {
        queryParam = `${queryParam}&&sortBy=invitedOn:${
          directionInvitedOn === "asc" ? "inc" : "desc"
        }`;
      }
      if (directionInvitationAcceptedOn) {
        if (directionInvitedOn) {
          queryParam = `${queryParam},invitationAcceptedOn:${
            directionInvitationAcceptedOn === "asc" ? "inc" : "desc"
          }`;
        } else {
          queryParam = `${queryParam}&&sortBy=invitationAcceptedOn:${
            directionInvitationAcceptedOn === "asc" ? "inc" : "desc"
          }`;
        }
      }

      getUserInfo(queryParam);
    }
  }, [page, limit, directionInvitedOn, directionInvitationAcceptedOn]); //eslint-disable-line react-hooks/exhaustive-deps

  const getUserInfo = (queryParam) => {
    setLoader(true);
    ReportService.getUsers(queryParam)
      .then((res) => {
        if (res.status === 200) {
          setLoader(false);
          setTotalPages(res?.data?.response?.users?.totalPages);
          setUsers(res?.data?.response?.users?.results);
          setTotalRecords(res?.data?.response?.users?.totalResults);
          if (firstRender) {
            setFirstRender(false);
          }
        }
      })
      .catch((error) => {
        setLoader(false);
        console.log("getOpenAppError");
      });
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleLimitChange = (event) => {
    const value = event.target.value;
    if (value === "" || validateNumber(value)) {
      setLimit(value);
    }
  };

  const createInviteSortHandler = () => {
    setActiveInvitedOn(true);
    if (directionInvitedOn) {
      directionInvitedOn === "asc"
        ? setDirectionInvitedOn("desc")
        : setDirectionInvitedOn("asc");
    } else {
      setDirectionInvitedOn("asc");
    }
  };

  const createAcceptSortHandler = () => {
    setActiveInvitationAcceptedOn(true);
    if (directionInvitationAcceptedOn) {
      directionInvitationAcceptedOn === "asc"
        ? setDirectionInvitationAcceptedOn("desc")
        : setDirectionInvitationAcceptedOn("asc");
    } else {
      setDirectionInvitationAcceptedOn("asc");
    }
  };

  const handleDownload = () => {
    try {
      let queryParam;
      setDownload(true);

      if (directionInvitedOn) {
        queryParam = `?sortBy=invitedOn:${
          directionInvitedOn === "asc" ? "inc" : "desc"
        }`;
      }
      if (directionInvitationAcceptedOn) {
        if (directionInvitedOn) {
          queryParam = `${queryParam},invitationAcceptedOn:${
            directionInvitationAcceptedOn === "asc" ? "inc" : "desc"
          }`;
        } else {
          queryParam = `?sortBy=invitationAcceptedOn:${
            directionInvitationAcceptedOn === "asc" ? "inc" : "desc"
          }`;
        }
      }

      ReportService.downloadUserInfo(queryParam)
        .then((res) => {
          if (res.status === 200) {
            const url = res.data.response.Location;
            const link = document.createElement("a");
            link.href = url;
            document.body.appendChild(link);
            link.click();
            setDownload(false);
          }
        })
        .catch((error) => {
          setDownload(false);
          console.log("downloadUserInfoError");
        });
    } catch (error) {
      setDownload(false);
      console.log("downloadUserInfo,error");
    }
  };

  const compCount = (user) => {
    return (
      user?.compCount + user?.sharedAccessCompCount + user?.tradedCompCount
    );
  };

  const confidentialCompCount = (user) => {
    return (
      user?.confidentialCompCount + user?.confidentialSharedAccessCompCount
    );
  };

  return (
    <>
      <div className='justify-end mb-20'>
        <button
          className='btn btn-success'
          disabled={download}
          onClick={handleDownload}
        >
          {download ? "Loading..." : "Download"}
        </button>
      </div>

      <div className='table-cover'>
        <Table aria-label='simple table' stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell> User ID</TableCell>
              <TableCell>Full Name</TableCell>
              <TableCell>Company</TableCell>
              <TableCell>Email</TableCell>
              <TableCell style={{ minWidth: "300px" }}>
                App Invite Timestamp
                <TableSortLabel
                  active={activeInvitedOn}
                  direction={directionInvitedOn || "asc"}
                  onClick={createInviteSortHandler}
                  className='sorting-icon'
                ></TableSortLabel>
              </TableCell>
              <TableCell style={{ minWidth: "320px" }}>
                App Accept Invite Timestamp
                <TableSortLabel
                  active={activeInvitationAcceptedOn}
                  direction={directionInvitationAcceptedOn || "asc"}
                  onClick={createAcceptSortHandler}
                  className='sorting-icon'
                ></TableSortLabel>
              </TableCell>
              <TableCell>No. of Comps</TableCell>
              <TableCell style={{ minWidth: "200px" }}>
                No. of Non-Tradable Comps
              </TableCell>
              <TableCell>City</TableCell>
              <TableCell>State</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!loader &&
              users.length > 0 &&
              users.map((user, index) => (
                <TableRow
                  key={`${user._id}-${index}`}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>{user?._id}</TableCell>
                  <TableCell>{user?.name}</TableCell>
                  <TableCell>{user?.companyName}</TableCell>
                  <TableCell>{user?.email}</TableCell>
                  <TableCell>
                    {user?.invitedOn
                      ? dayjs(user.invitedOn).format("YYYY-MM-DDTHH:mm:ssZ")
                      : "-"}
                  </TableCell>
                  <TableCell>
                    {user?.invitationAcceptedOn
                      ? dayjs(user.invitationAcceptedOn).format(
                          "YYYY-MM-DDTHH:mm:ssZ"
                        )
                      : "-"}
                  </TableCell>
                  <TableCell>{compCount(user)}</TableCell>
                  <TableCell>{confidentialCompCount(user)}</TableCell>
                  <TableCell>{user?.city}</TableCell>
                  <TableCell>{user?.state}</TableCell>
                </TableRow>
              ))}

            {!loader && users.length === 0 && (
              <TableRow>
                <TableCell
                  colSpan={9}
                  style={{
                    padding: "120px 0px",
                    color: "#333",
                    fontSize: "24px",
                  }}
                >
                  No Records Found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {loader && <Loader />}
      </div>
      <div className='pagination-cover'>
        <Pagination
          count={totalPages}
          variant='outlined'
          shape='rounded'
          onChange={handlePageChange}
        />
        <TextField
          name='limit'
          variant='standard'
          value={limit}
          onChange={handleLimitChange}
          style={{ width: "80px", margin: "0px 20px" }}
        />
        <h3>
          {users?.length !== 0 ? users?.length : "0"} of {totalRecords}
        </h3>
      </div>
    </>
  );
}
