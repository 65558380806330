import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import "../add-subscription/add-subscription.css";
import backIcon from "../../../assets/images/back-icon.svg";
import { valideWhiteSpace } from "../../../utils/Helper";

export default function AddSubscription() {
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState(true);
  const [formValues, setFormValues] = useState({
    tenure: "",
    price: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [inputList, setInputList] = useState([{ benefit: "" }]);
  const [fieldSetErrors, setFieldSetErrors] = useState([{ benefit: null }]);
  useEffect(() => {    
    if (formValues) {
      if (
        Object.values(formValues).every((x) => x) &&
        Object.values(inputList).every((x) => x.benefit) &&
        Object.values(formErrors).every((x) => !x)
      ) {
        setDisabled(false);
      }
    }
  }, [formErrors, formValues, inputList]);

 

  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    if (!valideWhiteSpace(value)) {
      const list = [...inputList];
      list[index][name] = value;
      setInputList(list);
      validateFieldSet(index, value, name);
    }
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setDisabled(true);
    setInputList([...inputList, { benefit: "" }]);
    setFieldSetErrors([...fieldSetErrors, { benefit: null }]);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (!valideWhiteSpace(value)) {
      setFormValues({ ...formValues, [name]: value });
      validateField(value, name);
    }
  };

  const validateField = (value, name) => {
    if (!Boolean(value)) {
      setDisabled(true);
      setFormErrors({
        ...formErrors,
        [name]: "Required Field",
      });
    } else {
      setFormErrors({ ...formErrors, [name]: "" });
    }
  };

  const validateFieldSet = (index, value, name) => {
    if (!Boolean(value)) {
      setDisabled(true);
      const values = [...fieldSetErrors];
      values[index][name] = "Required";
      setFieldSetErrors(values);
    } else {
      const values = [...fieldSetErrors];
      values[index][name] = null;
      setFieldSetErrors(values);
    }
  };

  const handleSubmit = () => {};
  return (
    <div className='dashboard-container'>
      <div className='dashboard-title-section'>
        <div className='dashboard-title back-link'>
          <img src={backIcon} alt='back-icon' onClick={() => navigate(-1)} />

          <h2>Add Subscription</h2>
        </div>
      </div>
      <div className='radio-buttons-wrapper'>
        <div className='radio-label'>
          <h3>Tenure</h3>
        </div>

        <div className='radio-options'>
          <FormControl>
            <RadioGroup
              name='tenure'
              value={formValues?.tenure}
              onChange={handleChange}
              style={{ flexDirection: "row" }}
            >
              <FormControlLabel
                className={`${formValues?.tenure === "1" ? "active" : ""}`}
                value='1'
                control={<Radio />}
                label='Monthly'
              />
              <FormControlLabel
                className={`${formValues?.tenure === "2" ? "active" : ""}`}
                value='2'
                control={<Radio />}
                label='Yearly'
              />
            </RadioGroup>
          </FormControl>
        </div>
      </div>
      <div className='formItem add-details-info add-subs'>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <h3>Total Price ($)</h3>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={8}>
            <TextField
              fullWidth
              name='price'
              value={formValues.price || ""}
              onChange={handleChange}
              variant='outlined'
              label='Enter Price'
              error={Boolean(formErrors?.price)}
              helperText={formErrors?.price}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <h3>Benefits</h3>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={8}>
            <button onClick={handleAddClick}>Add</button>

            {inputList.map((x, i) => {
              return (
                <div className='box'>
                  <TextField
                    fullWidth
                    name='benefit'
                    value={x.benefit}
                    onChange={(e) => handleInputChange(e, i)}
                    variant='outlined'
                    label='Enter benefit'
                    error={
                      x?.benefit || fieldSetErrors[i].benefit === null
                        ? false
                        : true
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          {inputList.length !== 1 && (
                            <button
                              className='mr10'
                              onClick={() => handleRemoveClick(i)}
                            >
                              Remove
                            </button>
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              );
            })}
          </Grid>
        </Grid>
      </div>
      <div className='dashboard-btn'>
        <button
          className='btn btn-primary'
          disabled={disabled}
          onClick={handleSubmit}
        >
          Save
        </button>
      </div>
    </div>
  );
}
