import React from "react";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import backIcon from "../../../assets/images/back-icon.svg";
import "../subscription-detail/subscription-detail.css";

export default function SubscriptionDetail() {
  const navigate = useNavigate();

  const handleEdit = (id) => {
    navigate(`/admin/subscription/edit/${id}`);
  };
  return (
    <div className="dashboard-container">
      <div className="dashboard-title-section btn-link">
        <div className="dashboard-title back-link">
          <img src={backIcon} alt="back-icon" onClick={() => navigate(-1)} />
          <h2>Subscription Detail</h2>
        </div>
        <div className="dashboard-btn subscription-detail">
          <button className="btn btn-success mr-10">Active</button>
          <button className="btn btn-danger">Inactive</button>
        </div>
      </div>
      <div className="formItem add-details-info">
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <h3>Total Price ($)</h3>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={8}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="500"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <h3>Total Price ($)</h3>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={8}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="500"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <h3>Description</h3>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={8}>
            <TextareaAutosize
              aria-label="minimum height"
              minRows={8}
              placeholder="Lorem Ipsum is simply dummy Lorem Ipsum is 
                                simply dummy Lorem Ipsum is simply dummyi
                                Lorem Ipsum is simply dummy Lorem Ipsum is
                                Lorem Ipsum is simply dummy.."
            />
          </Grid>
        </Grid>
      </div>
      <div className="dashboard-btn">
        <button className="btn btn-primary" onClick={() => handleEdit("123")}>
          Edit
        </button>
      </div>
    </div>
  );
}
