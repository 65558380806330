import React, { useState } from "react";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import CloseIcon from "@mui/icons-material/Close";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import "../../Listing/Filter/filter.css";

export default function BrokerFilter({
  handleFilter,
  handleClose,
  handleClear,
  company,
  status,
  address,
}) {
  const [formValues, setFormValues] = useState({
    company: company,
    address: address,
    status: status,
  });

  const handleChange = (e) => {
    const { value, name } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleApply = () => {
    handleFilter(formValues);
    handleClose();
  };
  const handleClearButton = () => {
    setFormValues({
      company: "",
      address: "",
      status: 0,
    });
    handleClear();
  };

  return (
    <div className="filter-cover">
      <div className="filter-header">
        <h2>Filter</h2>
        <CloseIcon className="close-icon" title="Close" onClick={handleClose} />
      </div>
      <div className="filter-body">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <h4 className="subtext mb-5">Status</h4>
            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="status"
                value={formValues?.status}
                onChange={handleChange}
              >
                <MenuItem value={0}>ALL</MenuItem>
                <MenuItem value={1}>PENDING</MenuItem>
                <MenuItem value={2}>APPROVED</MenuItem>
                <MenuItem value={3}>REJECTED</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <h4 className="subtext mb-5">Address</h4>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Enter Address"
              name="address"
              value={formValues?.address || ""}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12}>
            <h4 className="subtext mb-5">Company</h4>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Enter Company Name"
              name="company"
              value={formValues?.company || ""}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </div>
      <div className="filter-footer">
        <button
          className="btn btn-primary-border mr-30"
          onClick={handleClearButton}
        >
          Clear All
        </button>
        <button className="btn btn-primary" onClick={handleApply}>
          Apply
        </button>
      </div>
    </div>
  );
}
