import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs-plugin-utc";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import FilterListIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Pagination from "@mui/material/Pagination";
import Dialog from "@mui/material/Dialog";

import "../../Listing/listing.css";
import SubscriptionService from "../../../services/component/Subscription";
import CouponFilter from "../coupon-filter/CouponFilter";
import Loader from "./../../../components/common/Loader";
import AddCoupon from "../coupon-add/AddCoupon";
import { validateNumber, valideWhiteSpace } from "../../../utils/Helper";

dayjs.extend(dayjsPluginUTC);

export default function CouponListing() {
  const [open, setOpen] = useState(false);
  const [openInvite, setOpenInvite] = useState(false);
  const [loader, setLoader] = useState(true);
  const [coupons, setCoupons] = useState([]);
  const [limit, setLimit] = useState(10);
  const [query, setQuery] = useState("");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);

  const [fromSend, setFromSend] = useState(null);
  const [toSend, setToSend] = useState(null);
  const [fromRedeem, setFromRedeem] = useState(null);
  const [toRedeem, setToRedeem] = useState(null);
  const [type, setType] = useState(0);

  useEffect(() => {
    let queryParam = `?page=${page}&&limit=${limit}`;
    getCouponList(queryParam);
  }, [openInvite]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setQueryParams();
  }, [limit, query]); //eslint-disable-line react-hooks/exhaustive-deps

  const setQueryParams = () => {
    let queryParam = `?page=${page}&&limit=${limit}&&search=${query}`;
    if (query) {
      getCouponList(queryParam);
    } else {
      queryParam = `?page=${page}&&limit=${limit}`;
      getCouponList(queryParam);
    }
  };

  const getCouponList = (queryParam) => {
    setLoader(true);

    if (toSend && fromSend) {
      const fromDate = dayjs(fromSend).format("MM/DD/YYYY");
      const toDate = dayjs(toSend).format("MM/DD/YYYY");
      queryParam =
        queryParam + `&&sentDateMin=${fromDate}&&sentDateMax${toDate}`;
    }

    if (toRedeem && fromRedeem) {
      const fromDate = dayjs(fromRedeem).format("MM/DD/YYYY");
      const toDate = dayjs(toRedeem).format("MM/DD/YYYY");
      queryParam =
        queryParam + `&&redeemDateMin=${fromDate}&&redeemDateMax${toDate}`;
    }

    if (type !== 0) {
      queryParam = queryParam + `&&status=${type}`;
    }

    SubscriptionService.getCouponList(queryParam)
      .then((res) => {
        if (res.status === 200) {
          setLoader(false);
          setTotalPages(res?.data?.response?.totalPages);
          setCoupons(res.data.response.results);

          setTotalRecords(res?.data?.response?.totalResults);
        }
      })
      .catch((error) => {
        setLoader(false);
        console.log("getCouponListError");
      });
  };

  const getFilteredCouponList = (value) => {
    setLoader(true);
    SubscriptionService.getCouponList(value, limit, query)
      .then((res) => {
        if (res.status === 200) {
          setTotalPages(res?.data?.response?.totalPages);
          setTotalRecords(res?.data?.response?.totalResults);
          setLoader(false);
          setCoupons(res.data.response.results);
        }
      })
      .catch((error) => {
        setLoader(false);
        console.log("getFilteredCouponListError");
      });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
    let queryParam = "";

    if (query) {
      queryParam = `?page=${value}&&limit=${limit}&&search=${query}`;
      getCouponList(queryParam);
    } else {
      queryParam = `?page=${value}&&limit=${limit}`;
      getCouponList(queryParam);
    }
  };

  const handleLimitChange = (event) => {
    const value = event.target.value;
    if (value === "" || validateNumber(value)) {
      setLimit(value);
    }
  };

  const handleCloseInvite = () => {
    setOpenInvite(false);
  };

  const handleClear = () => {
    setFromSend(null);
    setToSend(null);
    setFromRedeem(null);
    setToRedeem(null);
    setType(0);
    handleClose();

    let queryParam = `?page=${page}&&limit=${limit}`;
    if (query) {
      queryParam = `?page=${page}&&limit=${limit}&&search=${query}`;
    }
    getFilteredCouponList(queryParam);
  };

  const handleQuery = (event) => {
    const { value } = event.target;
    if (!valideWhiteSpace(value)) {
      setQuery(value);
    }
  };

  const handleSearch = () => {
    setQuery("");
  };

  const handleFilter = (values) => {
    setToSend(values.toSend);
    setFromSend(values.fromSend);
    setToRedeem(values.toRedeem);
    setFromRedeem(values.fromRedeem);
    setType(values.type);

    let queryParam = `?page=${page}&&limit=${limit}`;
    if (query) {
      queryParam = `?page=${page}&&limit=${limit}&&search=${query}`;
    }
    if (values.toSend && values.fromSend) {
      queryParam =
        queryParam +
        `&&sentDateMin=${values.fromSend}&&sentDateMax=${values.toSend}`;
    }
    if (values.toRedeem && values.fromRedeem) {
      queryParam =
        queryParam +
        `&&redeemDateMin=${values.fromRedeem}&&redeemDateMax=${values.toRedeem}`;
    }

    if (values.type !== 0) {
      queryParam = queryParam + `&&status=${values.type}`;
    }

    getFilteredCouponList(queryParam);
  };

  return (
    <div className="page-listing">
      <div className="space-between">
        <h1 className="bold">Coupon Management</h1>
        <button className="btn btn-primary" onClick={() => setOpenInvite(true)}>
          Invite
        </button>
      </div>
      <div className="listing-filter space-between mt-20 mb-20">
        <div className="justify-start">
          <div className="search mr-20">
            <TextField
              className="search-box"
              fullWidth
              id="outlined-search"
              type="search"
              value={query}
              onChange={handleQuery}
              placeholder="Search by email or coupon"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon
                      className="search-icon pointer"
                      onClick={handleSearch}
                    ></SearchIcon>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div>
        <button
          className="btn btn-primary btn-filter"
          onClick={handleClickOpen}
        >
          <FilterListIcon fontSize="large" />
        </button>
      </div>

      <div className="table-cover">
        <Table aria-label="simple table" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Coupon Code</TableCell>
              <TableCell>Discount(%)</TableCell>
              <TableCell>Sent Date</TableCell>
              <TableCell>Redeem Date</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!loader &&
              coupons.length > 0 &&
              coupons.map((coupon, index) => (
                <TableRow
                  key={`${coupon._id}-${index}`}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>{coupon?._id}</TableCell>
                  <TableCell> {coupon?.receiversEmail}</TableCell>
                  <TableCell>{coupon?.name}</TableCell>
                  <TableCell>{coupon?.discount}</TableCell>
                  <TableCell>
                    {coupon?.updatedAt
                      ? dayjs(coupon?.updatedAt).format("MM/DD/YYYY | h:mm A")
                      : ""}
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell>Pending</TableCell>
                </TableRow>
              ))}

            {!loader && coupons.length === 0 && (
              <TableRow>
                <TableCell
                  colSpan={9}
                  style={{
                    padding: "120px 0px",
                    color: "#333",
                    fontSize: "24px",
                  }}
                >
                  No Records Found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {loader && <Loader />}
      </div>
      <div className="pagination-cover">
        <Pagination
          count={totalPages}
          variant="outlined"
          shape="rounded"
          onChange={handlePageChange}
        />
        <TextField
          name="limit"
          variant="standard"
          value={limit}
          onChange={handleLimitChange}
          style={{ width: "80px", margin: "0px 20px" }}
        />
        <h3>
          {coupons?.length !== 0 ? coupons?.length : "0"} of {totalRecords}
        </h3>
      </div>

      <Dialog open={open}>
        <CouponFilter
          handleFilter={handleFilter}
          fromSend={fromSend}
          toSend={toSend}
          fromRedeem={fromRedeem}
          toRedeem={toRedeem}
          type={type}
          handleClose={handleClose}
          handleClear={handleClear}
        />
      </Dialog>

      <Dialog open={openInvite} className="invite-dialog-cover">
        <AddCoupon handleCloseInvite={handleCloseInvite} />
      </Dialog>
    </div>
  );
}
