import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import FilterListIcon from '@mui/icons-material/FilterList';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import SearchIcon from '@mui/icons-material/Search';
import MapIcon from '@mui/icons-material/Map';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Pagination from '@mui/material/Pagination';
import Dialog from '@mui/material/Dialog';
import { toast } from 'react-toastify';
import CompFilter from '../comp-filter/CompFilter';
import CompCsv from '../comp-csv/compCsv';
import CompService from '../../../services/component/Comp';
import Loader from './../../../components/common/Loader';
import { validateNumber, valideWhiteSpace } from '../../../utils/Helper';
import CompMap from '../comp-map/CompMap';
import ClusteredCompList from '../clustered-comp-list/ClusteredCompList';
import BrokerService from '../../../services/component/Broker';

import '../../Listing/listing.css';

export default function CompListing() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openCsvBox, setOpenCsvBox] = useState(false);
  const [loader, setLoader] = useState(true);
  const [comps, setComps] = useState([]);
  const [limit, setLimit] = useState(10);
  const [query, setQuery] = useState('');
  const [showMap, setShowMap] = useState(false);
  const [showClustered, setShowClustered] = useState(false);
  const [clusteredList, setClusteredList] = useState([]);
  const [category, setCategory] = useState(0);
  const [status, setStatus] = useState(0);
  const [property, setProperty] = useState(0);
  const [level, setLevel] = useState(0);
  const [type, setType] = useState(0);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [progress, setProgress] = useState(0);
  const [csvFile, setCsvFile] = useState(null);
  const [responseData, setResponseData] = useState(null);
  const [selectedOwner, setSelectedOwner] = useState(null);

  useEffect(() => {
    if (csvFile) handleCsvUpload();
  }, [csvFile]);

  useEffect(() => {
    let queryParam = `?page=${page}&&limit=${limit}`;
    getComps(queryParam);
    let usersQueryParam = `?page=${page}&&limit=${limit}`;
    getUsersList(usersQueryParam);
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setQueryParams();
  }, [query]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setQueryParams();
  }, [limit]); //eslint-disable-line react-hooks/exhaustive-deps

  const setQueryParams = () => {
    let queryParam = `?page=${page}&&limit=${limit}&&search=${query}`;
    if (query) {
      getComps(queryParam);
    } else {
      queryParam = `?page=${page}&&limit=${limit}`;
      getComps(queryParam);
    }
  };

  const getComps = (queryParam) => {
    setLoader(true);
    if (category !== 0) {
      queryParam = queryParam + `&&type=${category}`;
    }

    if (type !== 0) {
      queryParam = queryParam + `&&category=${type}`;
    }

    if (level !== 0) {
      queryParam = queryParam + `&&level=${level}`;
    }

    if (status !== 0) {
      queryParam = queryParam + `&&status=${status}`;
    }

    if (property !== 0) {
      queryParam = queryParam + `&&propertyType=${property}`;
    }

    CompService.getCompList(queryParam)
      .then((res) => {
        if (res.status === 200) {
          setLoader(false);
          setTotalPages(res?.data?.response?.totalPages);
          setComps(res.data.response.results);
        }
      })
      .catch((error) => {
        setLoader(false);
        console.log(error);
        console.log('getCompsError');
      });
  };

  const getUsersList = (queryParam) => {
    setLoader(true);
    BrokerService.getBrokerList(queryParam)
      .then((res) => {
        if (res.status === 200) {
          setLoader(false);
          setTotalRecords(res?.data?.response?.totalResults);
        }
      })
      .catch((error) => {
        setLoader(false);
        console.log('getBrokersError');
      });
  };

  const getFilterdComps = (queryParam) => {
    setLoader(true);

    CompService.getCompList(queryParam)
      .then((res) => {
        if (res.status === 200) {
          setLoader(false);
          setTotalPages(res?.data?.response?.totalPages);
          setComps(res.data.response.results);
          setTotalRecords(res?.data?.response?.totalResults);
        }
      })
      .catch((error) => {
        setLoader(false);
        console.log('getFilterdComps');
      });
  };

  const handleFilter = (values) => {
    setCategory(values.category);
    setStatus(values.status);
    setProperty(values.property);
    setLevel(values.level);
    setType(values.type);

    let queryParam = `?page=${page}&&limit=${limit}`;
    if (query) {
      queryParam = `?page=${page}&&limit=${limit}&&search=${query}`;
    }
    if (values.category !== 0) {
      queryParam = queryParam + `&&type=${values.category}`;
    }
    if (values.type !== 0) {
      queryParam = queryParam + `&&category=${values.type}`;
    }

    if (values.level !== 0) {
      queryParam = queryParam + `&&level=${values.level}`;
    }

    if (values.status !== 0) {
      queryParam = queryParam + `&&status=${values.status}`;
    }

    if (values.property !== 0) {
      queryParam = queryParam + `&&propertyType=${values.property}`;
    }

    getFilterdComps(queryParam);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCsvOpen = () => {
    setOpenCsvBox(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMapClose = () => {
    setShowMap(false);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
    let queryParam = '';

    if (query) {
      queryParam = `?page=${value}&&limit=${limit}&&search=${query}`;
      getComps(queryParam);
    } else {
      queryParam = `?page=${value}&&limit=${limit}`;
      getComps(queryParam);
    }
  };

  const handleLimitChange = (event) => {
    const value = event.target.value;
    if (value === '' || validateNumber(value)) {
      setLimit(value);
    }
  };

  const handleClear = () => {
    setCategory(0);
    setStatus(0);
    setProperty(0);
    setLevel(0);
    setType(0);
    handleClose();
    let queryParam = `?page=${page}&&limit=${limit}`;
    if (query) {
      queryParam = `?page=${page}&&limit=${limit}&&search=${query}`;
    }
    getFilterdComps(queryParam);
  };

  const handleQuery = (event) => {
    const { value } = event.target;
    if (!valideWhiteSpace(value)) {
      setQuery(value);
    }
  };

  const handleSearch = () => {
    setQuery('');
  };

  const levelType = (value) => {
    let level = '';
    if (value) {
      switch (value) {
        case 1:
          level = 'Gold';
          break;
        case 2:
          level = 'Silver';
          break;
        default:
          level = 'Bronze';
      }
      return level;
    }
  };

  const propertyType = (value) => {
    let type = '';
    if (value) {
      switch (value) {
        case 1:
          type = 'Office';
          break;
        case 2:
          type = 'Industrial';
          break;
        case 3:
          type = 'Retail';
          break;
        case 4:
          type = 'Medical';
          break;
        case 5:
          type = 'Flex';
          break;

        default:
          type = 'Land';
      }
      return type;
    }
  };

  const goToCompDetail = (id) => {
    navigate(`../${id}`);
  };

  const handleShowMap = () => {
    setShowMap(true);
  };

  const handleClusterShow = (e) => {
    setShowClustered(true);
    setClusteredList(e.comps);
  };
  const handleClusterClose = () => {
    setShowClustered(false);
    setClusteredList([]);
  };

  const handleCsvUpload = async () => {
    try {
      const formData = new FormData();
      formData.append('csvFile', csvFile);
      formData.append('ownerId', selectedOwner);
      const res = await CompService.uploadCsv(formData);
      if (res.status === 200) {
        setOpenCsvBox(false);
        setSelectedOwner(null);
        setResponseData(res.data);
        toast.success(res?.data?.message || 'File uploaded successfully');
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleCsvClose = () => {
    setOpenCsvBox(false);
    setSelectedOwner(null);
  };

  return (
    <div className='page-listing'>
      <div className='space-between'>
        <h1 className='bold'>Comp Management</h1>
      </div>
      <div className='listing-filter space-between mt-20 mb-20'>
        <div className='justify-start'>
          <div className='search mr-20'>
            <TextField
              className='search-box'
              fullWidth
              id='outlined-search'
              type='search'
              onChange={handleQuery}
              placeholder='Search by Address or Company'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchIcon
                      className='search-icon pointer'
                      onClick={handleSearch}
                    ></SearchIcon>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position='end' title='Map'>
                    <MapIcon
                      className='map-icon pointer'
                      style={{ color: '#c98d06' }}
                      onClick={handleShowMap}
                    ></MapIcon>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div>
        <div className='space-between'>
          <button className='btn btn-primary mr-20' onClick={handleCsvOpen}>
            <span className='space-between'>
              <CloudUploadIcon className='mr-10' /> Upload CSV
            </span>
          </button>
          <button
            className='btn btn-primary btn-filter'
            onClick={handleClickOpen}
          >
            <FilterListIcon fontSize='large' />
          </button>
        </div>
      </div>

      <div className='table-cover'>
        <Table aria-label='simple table' stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Address</TableCell>
              <TableCell>Company Name</TableCell>
              <TableCell>Owner</TableCell>
              <TableCell>Property Type</TableCell>
              <TableCell>Level</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Type</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!loader &&
              comps.length > 0 &&
              comps.map((comp, index) => (
                <TableRow
                  key={comp._id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  onClick={() => goToCompDetail(comp._id)}
                >
                  <TableCell>{comp?._id || ''}</TableCell>

                  <TableCell>{comp.type === 1 ? 'Lease' : 'Sale'}</TableCell>
                  <TableCell title={comp?.fullAddress || ''}>
                    {comp?.fullAddress || ''}
                  </TableCell>
                  <TableCell>{comp?.tenantCompanyName || ''}</TableCell>
                  <TableCell>{comp?.ownerDetail?.name || ''}</TableCell>
                  <TableCell>
                    {comp?.propertyType ? propertyType(comp.propertyType) : ''}
                  </TableCell>
                  <TableCell>
                    {comp?.level ? levelType(comp.level) : ''}
                  </TableCell>
                  <TableCell>
                    {`${comp?.isLocked === true ? 'Locked' : 'Unlocked'}/${
                      comp?.isActive === true ? 'Active' : 'Inactive'
                    }`}
                  </TableCell>
                  <TableCell>
                    {comp?.isConfidential === true
                      ? 'Non-Tradable'
                      : 'Tradable'}
                  </TableCell>
                </TableRow>
              ))}

            {!loader && comps.length === 0 && (
              <TableRow>
                <TableCell
                  colSpan={9}
                  style={{
                    padding: '120px 0px',
                    color: '#333',
                    fontSize: '24px',
                  }}
                >
                  No Records Found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {loader && <Loader />}
      </div>
      <div className='pagination-cover'>
        <Pagination
          count={totalPages}
          variant='outlined'
          shape='rounded'
          onChange={handlePageChange}
        />
        <TextField
          name='limit'
          variant='standard'
          value={limit}
          onChange={handleLimitChange}
          style={{ width: '80px', margin: '0px 20px' }}
        />
        <h3>
          {comps?.length !== 0 ? comps?.length : '0'} of {totalRecords}
        </h3>
      </div>

      <CompMap
        open={showMap}
        handleMapClose={handleMapClose}
        handleClusterShow={handleClusterShow}
      />

      <ClusteredCompList
        open={showClustered}
        comps={clusteredList}
        handleClose={handleClusterClose}
        propertyType={propertyType}
        levelType={levelType}
        goToCompDetail={goToCompDetail}
      />

      <Dialog open={open}>
        <CompFilter
          handleFilter={handleFilter}
          category={category}
          status={status}
          property={property}
          level={level}
          type={type}
          handleClose={handleClose}
          handleClear={handleClear}
          show={true}
        />
      </Dialog>
      <Dialog open={openCsvBox}>
        <CompCsv
          handleCsvClose={handleCsvClose}
          progress={progress}
          setProgress={setProgress}
          setCsvFile={setCsvFile}
          responseData={responseData}
          totalRecords={totalRecords}
          selectedOwner={selectedOwner}
          setSelectedOwner={setSelectedOwner}
        />
      </Dialog>
    </div>
  );
}
