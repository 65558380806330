import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import TradeListing from "./trade-listing/TradeListing";

export default function TradeRoute() {
  return (
    <Routes>
      <Route path="/" element={<TradeListing />} />
      <Route path="*" element={<Navigate to="/notfound" replace />} />
    </Routes>
  );
}
