import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs-plugin-utc";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import FilterListIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Pagination from "@mui/material/Pagination";
import Dialog from "@mui/material/Dialog";

import "../../Listing/listing.css";
import NotificationService from "../../../services/component/Notification";
import NotificationFilter from "../notification-filter/NotificationFilter";
import Loader from "./../../../components/common/Loader";
import { validateNumber, valideWhiteSpace } from "../../../utils/Helper";
import { recipientStatus } from "../../../utils/SwitchCase";
dayjs.extend(dayjsPluginUTC);

export default function NotificationListing() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState(true);
  const [notifications, setNotifications] = useState([]);
  const [limit, setLimit] = useState(10);
  const [query, setQuery] = useState("");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);

  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [sentTo, setSentTo] = useState(0);

  useEffect(() => {
    let queryParam = `?page=${page}&&limit=${limit}`;
    getNotifications(queryParam);
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (query) {
      setQueryParams();
    }
  }, [query]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setQueryParams();
  }, [limit]); //eslint-disable-line react-hooks/exhaustive-deps

  const setQueryParams = () => {
    let queryParam = `?page=${page}&&limit=${limit}&&search=${query}`;
    if (query) {
      getNotifications(queryParam);
    } else {
      queryParam = `?page=${page}&&limit=${limit}`;
      getNotifications(queryParam);
    }
  };

  const getNotifications = (queryParam) => {
    setLoader(true);

    if (to && from) {
      const fromDate = dayjs(from).format("MM/DD/YYYY");
      const toDate = dayjs(to).format("MM/DD/YYYY");
      queryParam = queryParam + `&&fromDate=${fromDate}&&toDate=${toDate}`;
    }
    if (sentTo !== 0) {
      queryParam = queryParam + `&&recipient=${sentTo}`;
    }

    NotificationService.getNotifications(queryParam)
      .then((res) => {
        if (res.status === 200) {
          setLoader(false);
          setTotalPages(res?.data?.response?.totalPages);
          setNotifications(res.data.response.results);
          setTotalRecords(res?.data?.response?.totalResults);
        }
      })
      .catch((error) => {
        setLoader(false);
        console.log("getNotifications");
      });
  };

  const getFilteredNotifications = (value) => {
    setLoader(true);
    NotificationService.getNotifications(value, limit, query)
      .then((res) => {
        if (res.status === 200) {
          setTotalPages(res?.data?.response?.totalPages);
          setTotalRecords(res?.data?.response?.totalResults);
          setLoader(false);
          setNotifications(res.data.response.results);
        }
      })
      .catch((error) => {
        setLoader(false);
        console.log("getFilteredNotifications");
      });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
    let queryParam = "";

    if (query) {
      queryParam = `?page=${value}&&limit=${limit}&&search=${query}`;
      getNotifications(queryParam);
    } else {
      queryParam = `?page=${value}&&limit=${limit}`;
      getNotifications(queryParam);
    }
  };

  const handleLimitChange = (event) => {
    const value = event.target.value;
    if (value === "" || validateNumber(value)) {
      setLimit(value);
    }
  };

  const handleClear = () => {
    setFrom(null);
    setTo(null);
    setSentTo(0);
    handleClose();

    let queryParam = `?page=${page}&&limit=${limit}`;
    if (query) {
      queryParam = `?page=${page}&&limit=${limit}&&search=${query}`;
    }
    getFilteredNotifications(queryParam);
  };

  const handleQuery = (event) => {
    const { value } = event.target;
    if (!valideWhiteSpace(value)) {
      setQuery(value);
    }
  };

  const handleSearch = () => {
    setQuery("");
  };

  const goToNotificationDetail = (id) => {
    navigate(`../${id}`);
  };

  const handleAddNotification = () => {
    navigate(`../add-notification`);
  };

  const handleFilter = (values) => {
    setTo(values.to);
    setFrom(values.from);
    setSentTo(values.sentTo);

    let queryParam = `?page=${page}&&limit=${limit}`;
    if (query) {
      queryParam = `?page=${page}&&limit=${limit}&&search=${query}`;
    }
    if (values.to && values.from) {
      queryParam =
        queryParam + `&&fromDate=${values.from}&&toDate=${values.to}`;
    }
    if (values.sentTo !== 0) {
      queryParam = queryParam + `&&recipient=${values.sentTo}`;
    }

    getFilteredNotifications(queryParam);
  };

  return (
    <div className="page-listing">
      <div className="space-between">
        <h1 className="bold">Notification Management</h1>
        <button className="btn btn-primary" onClick={handleAddNotification}>
          Add
        </button>
      </div>
      <div className="listing-filter space-between mt-20 mb-20">
        <div className="justify-start">
          <div className="search mr-20">
            <TextField
              className="search-box"
              fullWidth
              id="outlined-search"
              type="search"
              value={query}
              onChange={handleQuery}
              placeholder="Search by Name"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon
                      className="search-icon pointer"
                      onClick={handleSearch}
                    ></SearchIcon>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div>
        <button
          className="btn btn-primary btn-filter"
          onClick={handleClickOpen}
        >
          <FilterListIcon fontSize="large" />
        </button>
      </div>

      <div className="table-cover">
        <Table aria-label="simple table" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Sent to</TableCell>
              <TableCell>Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!loader &&
              notifications.length > 0 &&
              notifications.map((notification, index) => (
                <TableRow
                  key={notification._id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  onClick={() => goToNotificationDetail(notification._id)}
                >
                  <TableCell>{notification?._id || ""}</TableCell>
                  <TableCell>{notification?.title || ""}</TableCell>
                  <TableCell>{notification?.description || ""}</TableCell>
                  <TableCell>
                    {" "}
                    {notification?.recipient
                      ? recipientStatus(notification?.recipient)
                      : ""}
                  </TableCell>
                  <TableCell>
                    {" "}
                    {notification?.createdAt
                      ? dayjs(notification?.createdAt).format("MM/DD/YYYY")
                      : ""}
                  </TableCell>
                </TableRow>
              ))}

            {!loader && notifications.length === 0 && (
              <TableRow>
                <TableCell
                  colSpan={9}
                  style={{
                    padding: "120px 0px",
                    color: "#333",
                    fontSize: "24px",
                  }}
                >
                  No Records Found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {loader && <Loader />}
      </div>
      <div className="pagination-cover">
        <Pagination
          count={totalPages}
          variant="outlined"
          shape="rounded"
          onChange={handlePageChange}
        />
        <TextField
          name="limit"
          variant="standard"
          value={limit}
          onChange={handleLimitChange}
          style={{ width: "80px", margin: "0px 20px" }}
        />
        <h3>
          {notifications?.length !== 0 ? notifications?.length : "0"} of{" "}
          {totalRecords}
        </h3>
      </div>

      <Dialog open={open}>
        <NotificationFilter
          handleFilter={handleFilter}
          from={from}
          to={to}
          sentTo={sentTo}
          handleClose={handleClose}
          handleClear={handleClear}
        />
      </Dialog>
    </div>
  );
}
