import {   
    SIGNIN_USER,  
    SIGNOUT_USER,   
    SIGNUP_USER,
  
  } from '../../Constant';
  
  export const userSignUp = (user) => {
    return {
      type: SIGNUP_USER,
      payload: user
    };
  };
  export const userSignIn = (user) => {
    return {
      type: SIGNIN_USER,
      payload: user
    };
  };
  export const userSignOut = () => {   
    return {
      type: SIGNOUT_USER
    };
  };
 