import axios from "axios";
import AuthService from "../services/component/Auth";

export const getAccessToken = () => {
  const token = localStorage.getItem("tokenInfo")
    ? JSON.parse(localStorage.getItem("tokenInfo"))
    : "";
  if (token) {
    return token;
  }
  return null;
};

export const httpClient = axios.create({
  baseURL: process.env.REACT_APP_BASEURL,
});

httpClient.interceptors.request.use(
  (config) => {
    const token = getAccessToken()?.accessToken;
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

httpClient.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (
      error.response.status === 401 &&
      getAccessToken() &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;

      const token = await AuthService.refreshToken({
        refreshToken: getAccessToken()?.refreshToken,
      });

      if (token) {
        localStorage.setItem("tokenInfo", JSON.stringify(token.data));
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + token.data.accessToken;
        return httpClient(originalRequest);
      }
    }
    return Promise.reject(error);
  }
);
