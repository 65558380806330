import React, { useState, useEffect } from "react";
import {
  Autocomplete,
  GoogleMap,
  Marker,
  useLoadScript,
  MarkerClusterer,
} from "@react-google-maps/api";
import { useNavigate } from "react-router-dom";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import Geocode from "react-geocode";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";

import bluePin from "../../../assets/icons/blue-pin.svg";
import orangePin from "../../../assets/icons/orange-pin.svg";
import vibrantIcon from "../../../assets/icons/vibrant-badge.png";

import CompService from "../../../services/component/Comp";
import { CONFIG } from "../../../utils/Constant";

const mapStyles = {
  width: "100%",
  height: "75vh",
};
Geocode.setApiKey(CONFIG.GOOGLE_MAPS_API_KEY);
//const google = window.google;

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

export default function CompMap({ open, handleMapClose, handleClusterShow }) {
  const navigate = useNavigate();
  const [libraries] = useState(["places"]);
  const { isLoaded } = useLoadScript({
    // country: "us",
    googleMapsApiKey: CONFIG.GOOGLE_MAPS_API_KEY,
    libraries,
  });
  const [currentPosition, setCurrentPosition] = useState({
    lat: parseFloat(-1.286389),
    lng: parseFloat(36.817223),
  });
  const [autocomplete, setAutoComplete] = useState({});
  const [comps, setComps] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(success, errorCallback);
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  const errorCallback = () => {
    setCurrentPosition({
      lat: parseFloat(-1.286389),
      lng: parseFloat(36.817223),
    });
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleCurrentLocation = () => {
    setComps([]);
    setSearch("");
    navigator.geolocation.getCurrentPosition(success, errorCallback);
  };

  const onPlaceChanged = (e) => {
    if (autocomplete) {
      setComps([]);
      const getPlace = autocomplete.getPlace();
      setSearch(getPlace.formatted_address);
      const lat = getPlace.geometry.location.lat();
      const lng = getPlace.geometry.location.lng();

      getCompsByLocation(lng, lat);
    }
  };

  const success = (position) => {
    const currentPosition = {
      lat: position.coords.latitude,
      lng: position.coords.longitude,
    };

    if (currentPosition.lat && currentPosition.lng) {
      Geocode.fromLatLng(currentPosition.lat, currentPosition.lng).then(
        (response) => {
          //const address = response.results[0].formatted_address;
        },
        (error) => {
          console.error(error);
        }
      );

      setCurrentPosition(currentPosition);
      getCompsByLocation(currentPosition.lng, currentPosition.lat);
    }
  };

  const getCompsByLocation = (lng, lat) => {
    CompService.getCompsByLocation([lng, lat])
      .then((res) => {
        if (res.status === 200) {
          if (res.data.response.results.length > 0) {
            setComps(res.data.response.results);
          }

          setCurrentPosition({ lat, lng });
        }
      })
      .catch((error) => {
        console.log("getCompsByLocationError");
      });
  };

  const handleMarker = (id) => {
    navigate(`../${id}`);
  };

  const onLoad = (autocomplete) => {
    setAutoComplete(autocomplete);
  };

  const renderMarkers = (item, clusterer) => {
    const userId = localStorage.getItem("userInfo")
      ? JSON.parse(localStorage.getItem("userInfo"))._id
      : "";

    const position = {
      lat: item.location[1],
      lng: item.location[0],
    };

    return (
      <Marker
        onClick={() => handleMarker(item.comps[0]._id)}
        key={item.comps[0]._id}
        position={position}
        clusterer={clusterer}
        icon={item.comps[0]?.ownerId === userId ? bluePin : orangePin}
      />
    );
  };

  const handleClose = () => {
    setAutoComplete({});
    handleMapClose();
    setComps([]);
    setSearch("");
    navigator.geolocation.getCurrentPosition(success, errorCallback);
  };

  const handleCombineMarker = (e) => {
    handleClusterShow(e);
    handleClose();
  };

  return (
    <BootstrapDialog open={open} className="map-dialog">
      <CloseIcon className="close-icon" title="Close" onClick={handleClose} />
      <DialogContent>
        <div>
          {isLoaded ? (
            <div
              style={{
                boxShadow: "0px 1px 2px 1px rgba(0,0,0,0.2)",
                position: "relative",
              }}
            >
              <GoogleMap
                mapContainerStyle={mapStyles}
                zoom={2}
                center={currentPosition}
              >
                {comps && comps.length > 0 ? (
                  <>
                    {comps
                      .filter((x) => x.isMultiple)
                      .map((y) => (
                        <Marker
                          onClick={() => handleCombineMarker(y)}
                          key={y.comps[0]._id}
                          position={{
                            lat: y.location[1],
                            lng: y.location[0],
                          }}
                          icon={vibrantIcon}
                          label={y.comps.length.toString() || "0"}
                        />
                      ))}

                    <MarkerClusterer>
                      {(clusterer) =>
                        comps
                          .filter((x) => !x.isMultiple)
                          .map((y, i) => (
                            <div key={i}>{renderMarkers(y, clusterer)};</div>
                          ))
                      }
                    </MarkerClusterer>
                  </>
                ) : (
                  <Marker position={currentPosition} />
                )}

                {
                  <Autocomplete
                    onLoad={onLoad}
                    restrictions={{ country: "us" }}
                    onPlaceChanged={onPlaceChanged}
                  >
                    <div
                      style={{
                        width: `240px`,
                        height: `48px`,
                        position: "absolute",
                        left: "50%",
                        top: "9px",
                        marginLeft: "-120px",
                      }}
                    >
                      <input
                        type="text"
                        value={search}
                        onChange={handleSearch}
                        placeholder="Enter location"
                        style={{
                          boxSizing: `border-box`,
                          border: `1px solid transparent`,
                          width: `100%`,
                          height: `48px`,
                          padding: `0 40px 0 12px`,
                          borderRadius: `2px`,
                          boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                          fontSize: `14px`,
                          outline: `none`,
                          textOverflow: `ellipses`,
                        }}
                      />

                      <MyLocationIcon
                        titleAccess="Set Current Location"
                        style={{
                          position: "absolute",
                          cursor: "pointer",
                          top: "12px",
                          right: "10px",
                          color: "#254089",
                        }}
                        onClick={handleCurrentLocation}
                      />
                    </div>
                  </Autocomplete>
                }
              </GoogleMap>
            </div>
          ) : (
            <></>
          )}
        </div>
      </DialogContent>
    </BootstrapDialog>
  );
}
