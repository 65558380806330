import { SIGNIN_USER, SIGNOUT_USER, SIGNUP_USER } from "../../Constant";

const INIT_STATE = {
  tokenInfo: localStorage.getItem("tokenInfo")
    ? JSON.parse(localStorage.getItem("tokenInfo"))
    : "",
  userInfo: localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : "",
};

const authReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SIGNIN_USER: {
      return {
        ...state,
        tokenInfo: action.payload.token,
        userInfo: action.payload.user,
      };
    }

    case SIGNUP_USER: {
      return {
        ...state,
        tokenInfo: action.payload.token,
        userInfo: action.payload.user,
      };
    }

    case SIGNOUT_USER: {
      return {
        ...state,
        tokenInfo: "",
        userInfo: "",
      };
    }

    default:
      return state;
  }
};

export default authReducer;
