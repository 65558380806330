import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import DialogContentText from "@mui/material/DialogContentText";
import TextField from "@mui/material/TextField";

export default function Comment({ open, comment, handleSkip, handleClose, handleComment, handleCommentChange }) {
  return (
    <Dialog
      className="modal-in-center"
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="xs"
    >
      <CloseIcon className="close-icon" title="Close" onClick={handleClose} style={{
        position: 'absolute',
        right: '15px',
        top: '5%',
        fontSize: '26px',
        cursor: 'pointer',
        transform: 'translateY(-50%)'
      }} />
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <h3 className="center mt-20 mb-20" style={{ color: "black" }}>Leave a comment</h3>
          <TextField
            fullWidth
            multiline
            rows={4}
            name="comment"
            value={comment}
            onChange={handleCommentChange}
            label="Write here..."
          />
        </DialogContentText>
      </DialogContent>
      <div className="flex-center mt-20 mb-30">
        <button className="btn btn-simple mr-20" onClick={handleSkip}>
          Skip
        </button>
        <button className="btn btn-primary" onClick={handleComment} disabled={!comment} >
          Save
        </button>
      </div>
    </Dialog>
  );
}
