import React, { useState, useEffect } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Pagination from "@mui/material/Pagination";
import TextField from "@mui/material/TextField";
import TableSortLabel from "@mui/material/TableSortLabel";

import ReportService from "../../services/component/Reports";
import Loader from "../../components/common/Loader";
import { validateNumber } from "../../utils/Helper";

import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import advanced from "dayjs/plugin/advancedFormat";

dayjs.extend(timezone);
dayjs.extend(dayjsPluginUTC);
dayjs.extend(advanced);

export default function OpenApp() {
  const [loader, setLoader] = useState(true);
  const [active, setActive] = useState(false);
  const [download, setDownload] = useState(false);
  const [users, setUsers] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [direction, setDirection] = useState("");
  const [firstRender, setFirstRender] = useState(true);

  useEffect(() => {
    const queryParam = `?page=${page}&&limit=${limit}`;
    getOpenApp(queryParam);
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!firstRender) {
      let queryParam = `?page=${page}`;
      if (limit) {
        queryParam = `${queryParam}&&limit=${limit}`;
      }
      if (direction) {
        queryParam = `${queryParam}&&sortBy=userSession.createdAt:${
          direction === "asc" ? "inc" : "desc"
        }`;
      }
      getOpenApp(queryParam);
    }
  }, [page, limit, direction]); //eslint-disable-line react-hooks/exhaustive-deps

  const getOpenApp = (queryParam) => {
    setLoader(true);
    ReportService.getOpenApp(queryParam)
      .then((res) => {
        if (res.status === 200) {
          setLoader(false);
          setTotalPages(res?.data?.response?.users?.totalPages);
          setUsers(res?.data?.response?.users?.results);
          setTotalRecords(res?.data?.response?.users?.totalResults);
          if (firstRender) {
            setFirstRender(false);
          }
        }
      })
      .catch((error) => {
        setLoader(false);
        console.log("getOpenAppError");
      });
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleLimitChange = (event) => {
    const value = event.target.value;
    if (value === "" || validateNumber(value)) {
      setLimit(value);
    }
  };

  const createSortHandler = () => {
    setActive(true);
    if (direction) {
      direction === "asc" ? setDirection("desc") : setDirection("asc");
    } else {
      setDirection("asc");
    }
  };

  const handleDownload = () => {
    try {
      let queryParam;
      setDownload(true);
      if (direction) {
        queryParam = `?sortBy=lastActive:${
          direction === "asc" ? "inc" : "desc"
        }`;
      }
      ReportService.downloadOpenApp(queryParam)
        .then((res) => {
          if (res.status === 200) {
            const url = res.data.response.Location;
            const link = document.createElement("a");
            link.href = url;
            document.body.appendChild(link);
            link.click();
            setDownload(false);
          }
        })
        .catch((error) => {
          setDownload(false);
          console.log("downloadOpenAppError");
        });
    } catch (error) {
      setDownload(false);
      console.log("downloadOpenAppError,error");
    }
  };

  const handleActiveUser = (date) => {
    const then = new Date(date);
    const now = new Date();
    const msBetweenDates = Math.abs(then.getTime() - now.getTime());
    const daysBetweenDates = msBetweenDates / (24 * 60 * 60 * 1000);
    if (daysBetweenDates && daysBetweenDates > 30) {
      return true;
    }
    return false;
  };

  return (
    <>
      <div className='justify-end mb-20'>
        <button
          className='btn btn-success'
          disabled={download}
          onClick={handleDownload}
        >
          {download ? "Loading..." : "Download"}
        </button>
      </div>
      <div className='table-cover'>
        <Table aria-label='simple table' stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>User ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>
                App Open Timestamp{" "}
                <TableSortLabel
                  active={active}
                  direction={direction || "asc"}
                  onClick={createSortHandler}
                  className='sorting-icon'
                ></TableSortLabel>
              </TableCell>
              <TableCell>City</TableCell>
              <TableCell>Country</TableCell>
              <TableCell>TimeZone</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!loader &&
              users.length > 0 &&
              users.map((user, index) => (
                <TableRow
                  className={`${
                    user?.lastActive
                      ? handleActiveUser(user?.lastActive)
                        ? "inactive-user"
                        : ""
                      : "inactive-user"
                  }`}
                  key={`${user._id}-${index}`}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>{user?._id}</TableCell>
                  <TableCell>{user?.name}</TableCell>
                  <TableCell>{user?.email}</TableCell>
                  <TableCell>
                    {user?.userSession?.createdAt
                      ? dayjs(user.userSession.createdAt)
                          .tz(
                            user?.userSession?.geo?.timezone || "Europe/London"
                          )
                          .format("YYYY-MM-DDTHH:mm:ssZ")
                      : "-"}
                  </TableCell>
                  <TableCell>{user?.userSession?.geo?.city}</TableCell>
                  <TableCell>
                    {user?.userSession?.geo?.country}
                    {/* {user?.userSession ? user?.userSession?.geo?.country : ""} */}
                  </TableCell>
                  <TableCell>{user?.userSession?.geo?.timezone}</TableCell>
                </TableRow>
              ))}

            {!loader && users.length === 0 && (
              <TableRow>
                <TableCell
                  colSpan={9}
                  style={{
                    padding: "120px 0px",
                    color: "#333",
                    fontSize: "24px",
                  }}
                >
                  No Records Found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {loader && <Loader />}
      </div>
      <div className='pagination-cover'>
        <Pagination
          count={totalPages}
          variant='outlined'
          shape='rounded'
          onChange={handlePageChange}
        />
        <TextField
          name='limit'
          variant='standard'
          value={limit}
          onChange={handleLimitChange}
          style={{ width: "80px", margin: "0px 20px" }}
        />
        <h3>
          {users?.length !== 0 ? users?.length : "0"} of {totalRecords}
        </h3>
      </div>
    </>
  );
}
