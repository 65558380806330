import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import CompListing from "./comp-listing/CompListing";
import CompDetail from "./comp-detail/CompDetail";
import  TotalTrade from "./total-trades/trade-listing/TradeListing";

export default function CompRoute() {
  return (
    <Routes>
      <Route path="/" element={<CompListing />} />
      <Route path="/:id" element={<CompDetail />} />
      <Route path="/:id/total-trades" element={<TotalTrade />} />
      <Route path="*" element={<Navigate to="/notfound" replace />} />
    </Routes>
  );
}
