import { httpClient } from "../../utils/Apis";
import { BROKER } from "../Constant";

class BrokerService {
  getBrokerList(query) {
    return httpClient.get(`${BROKER.BROKER_LIST}${query}`);
  }
  getBroker(id) {
    return httpClient.get(BROKER.GET_BROKER.replace("{userId}", id));
  }
  addBroker(body) {
    return httpClient.post(BROKER.ADD_BROKER, body);
  }
  brokerStatus(body) {
    return httpClient.post(BROKER.USER_STATUS, body);
  }

  blockBroker(body) {
    return httpClient.post(BROKER.USER_BLOCK, body);
  }

  getApprovalStatus() {
    return httpClient.get(BROKER.APPROVAL_STATUS);
  }

  addApprovalStatus(body) {
    return httpClient.post(BROKER.APPROVAL_STATUS, body);
  }

  
}

export default new BrokerService();
