import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ERROR } from "../../utils/Constant";
import { validatePassword } from "../../utils/Helper";
import AuthService from "../../services/component/Auth";
import backIcon from "../../assets/images/back-icon.svg";

export default function ResetPassword() {
  const navigate = useNavigate();
  const params = useParams();
  const [formValues, setFormValues] = useState({
    password: "",
    confirmPassword: "",
  });
  const [disabled, setDisabled] = useState(true);
  const [loader, setLoader] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    validate(value, name);
  };

  useEffect(() => {
    if (
      Object.keys(formErrors).length !== 0 &&
      Object.values(formValues).every((x) => x) &&
      Object.values(formErrors).every((x) => !x)
    ) {
      setDisabled(false);
    }
  }, [formErrors, formValues]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (Object.values(formValues).every((x) => x)) {
      if (formValues.password !== formValues.confirmPassword) {
        setDisabled(true);
        setFormErrors({
          ...formErrors,
          confirmPassword: ERROR.COMPARE_PASSWORD,
        });
      } else {
        //setDisabled(false);
        setFormErrors({
          ...formErrors,
          confirmPassword: "",
        });
      }
    }
  }, [formValues, setFormErrors]); //eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = () => {
    setLoader(true);
    setDisabled(true);
    const token = params.token;
    const body = {
      password: formValues.password,
    };
    if (token && body) {
      AuthService.resetPassword(token, body)
        .then((res) => {
          if (res.status === 200) {
            toast.success(res.data.message);
            setLoader(false);
            setDisabled(false);
            navigate("../");
          }
        })
        .catch((error) => {
          setLoader(false);
          setDisabled(false);
          toast.error(error.response.data.message);
        });
    }
  };

  //validate input fields
  const validate = (value, name) => {
    switch (name) {
      case "password":
        validateField(
          value,
          name,
          validatePassword,
          ERROR.PASSWORD_REQUIRED,
          ERROR.PASSWORD
        );

        break;
      default:
        validateField(
          value,
          name,
          validatePassword,
          ERROR.PASSWORD_REQUIRED,
          ERROR.PASSWORD
        );
    }
  };

  const validateField = (value, name, validate, required, invalid) => {
    if (!Boolean(value)) {
      setDisabled(true);
      setFormErrors({
        ...formErrors,
        [name]: required,
      });
    } else if (!validate(value) && Boolean(value) && name === "password") {
      setDisabled(true);
      setFormErrors({ ...formErrors, [name]: invalid });
    } else {
      setFormErrors({ ...formErrors, [name]: "" });
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleBack = () => {
    navigate("../");
  };

  return (
    <div className="page-reset-password">
      <span className="back-icon mb-20" onClick={handleBack}>
        <img src={backIcon} alt="back-icon" />
      </span>
      <h2>Reset Password</h2>
      <h3>Kindly enter the new password!</h3>
      <div className="formIn">
        <div className="formItem">
          <TextField
            fullWidth
            name="password"
            variant="outlined"
            type={showPassword ? "text" : "password"}
            label="New Password"
            value={formValues?.password || ""}
            onChange={handleChange}
            error={Boolean(formErrors?.password)}
            helperText={formErrors?.password}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickShowPassword}
                    className="color-secondary"
                  >
                    {showPassword ? (
                      <RemoveRedEyeOutlinedIcon />
                    ) : (
                      <VisibilityOffOutlinedIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="formItem">
          <TextField
            fullWidth
            name="confirmPassword"
            variant="outlined"
            type={showConfirmPassword ? "text" : "password"}
            label="Confirm Password"
            value={formValues?.confirmPassword || ""}
            onChange={handleChange}
            error={Boolean(formErrors?.confirmPassword)}
            helperText={formErrors?.confirmPassword}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickShowConfirmPassword}
                    className="color-secondary"
                  >
                    {showConfirmPassword ? (
                      <RemoveRedEyeOutlinedIcon />
                    ) : (
                      <VisibilityOffOutlinedIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>

      <button
        className="btn btn-primary mt-30 w-100"
        onClick={handleSubmit}
        disabled={disabled}
      >
        {!loader ? "Update password" : "Loading..."}
      </button>
    </div>
  );
}
