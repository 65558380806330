import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { toast } from "react-toastify";

import NotificationService from "../../../services/component/Notification";
import backIcon from "../../../assets/images/back-icon.svg";
import {
  valideWhiteSpace,
  validateNumber,
  elementFromArray,
} from "../../../utils/Helper";
import BrokerFilter from "../../broker/broker-filter/BrokerFilter";
import BrokerList from "../broker-list/BrokerList.js";

export default function AddNotification() {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [submit, setSubmit] = useState(false);

  const [disabled, setDisabled] = useState(true);
  const [formValues, setFormValues] = useState({
    title: "",
    description: "",
    recipient: "1",
  });
  const [formErrors, setFormErrors] = useState({});
  const [limit, setLimit] = useState(10);
  const [query, setQuery] = useState("");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [open, setOpen] = useState(false);
  const [users, setUsers] = useState([]);

  const [company, setCompany] = useState("");
  const [address, setAddress] = useState("");
  const [status, setStatus] = useState(0);
  const [brokers, setBrokers] = useState([]);

  useEffect(() => {
    const queryParam = `?page=${page}&&limit=${limit}`;
    getNotificationUsers(queryParam);
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setQueryParams();
  }, [query, limit]); //eslint-disable-line react-hooks/exhaustive-deps

  const setQueryParams = () => {
    let queryParam = `?page=${page}&&limit=${limit}&&search=${query}`;
    if (query) {
      getNotificationUsers(queryParam);
    } else {
      queryParam = `?page=${page}&&limit=${limit}`;
      getNotificationUsers(queryParam);
    }
  };

  useEffect(() => {
    if (formValues) {
      if (
        Object.values(formValues).every((x) => x) &&
        Object.values(formErrors).every((x) => !x)
      ) {
        setDisabled(false);
      }
    }
  }, [formErrors, formValues]);

  const getNotificationUsers = (queryParam) => {
    setLoader(true);
    NotificationService.getNotificationUsers(queryParam)
      .then((res) => {
        if (res.status === 200) {
          setUsers(res.data.response.results);
          setTotalPages(res?.data?.response?.totalPages);
          setTotalRecords(res?.data?.response?.totalResults);
          setLoader(false);
        }
      })
      .catch((error) => {
        setLoader(false);
        console.log("getNotificationUsersError");
      });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (!valideWhiteSpace(value)) {
      setFormValues({ ...formValues, [name]: value });
      validateField(value, name);
    }
  };

  const handleUserChange = (e, broker) => {
    if (e.target.checked) {
      setBrokers([...brokers, broker]);
    } else {
      setBrokers(elementFromArray(brokers, broker));
    }
  };

  const validateField = (value, name) => {
    if (!Boolean(value)) {
      setDisabled(true);
      setFormErrors({
        ...formErrors,
        [name]: "Required Field",
      });
    } else {
      setFormErrors({ ...formErrors, [name]: "" });
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClear = () => {
    setCompany("");
    setStatus(0);
    setAddress("");
    //setType(0);
    handleClose();

    let queryParam = `?page=${page}&&limit=${limit}`;
    if (query) {
      queryParam = `?page=${page}&&limit=${limit}&&search=${query}`;
    }
    getNotificationUsers(queryParam);
  };

  const handleFilter = (values) => {
    setCompany(values.company);
    setStatus(values.status);

    setAddress(values.address);

    let queryParam = `?page=${page}&&limit=${limit}`;
    if (query) {
      queryParam = `?page=${page}&&limit=${limit}&&search=${query}`;
    }
    if (values.company) {
      queryParam = queryParam + `&&companyName=${values.company}`;
    }
    if (values.address) {
      queryParam = queryParam + `&&fullAddress=${values.address}`;
    }

    if (values.status !== 0) {
      queryParam = queryParam + `&&approvalStatus=${values.status}`;
    }

    getNotificationUsers(queryParam);
  };

  const handleBack = () => {
    navigate("/admin/notification");
  };

  const handleSubmit = () => {
    const body = { ...formValues };
    body.recipient = Number(body.recipient);

    if (body.recipient === 4) {
      body.users = brokers;
    }

    if (body.recipient === 4 && body.users.length === 0) {
      toast.error("Please select atleast one user to submit!");
    } else {
      setSubmit(true);
      setDisabled(true);
      NotificationService.addNotification(body)
        .then((res) => {
          if (res.status === 200) {
            toast.success(res.data.message);
            setSubmit(false);
            setDisabled(true);
            navigate("/admin/notification");
          }
        })
        .catch((error) => {
          setSubmit(false);
          setDisabled(true);
          console.log("addNotificationError");
        });
    }
  };

  const handleQuery = (event) => {
    const { value } = event.target;
    if (!valideWhiteSpace(value)) {
      setQuery(value);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClearQuery = () => {
    setQuery("");
  };

  const handlePageChange = (event, value) => {
    setPage(value);
    let queryParam = "";

    if (query) {
      queryParam = `?page=${value}&&limit=${limit}&&search=${query}`;
      getNotificationUsers(queryParam);
    } else {
      queryParam = `?page=${value}&&limit=${limit}`;
      getNotificationUsers(queryParam);
    }
  };

  const handleLimitChange = (event) => {
    const value = event.target.value;
    if (value === "" || validateNumber(value)) {
      setLimit(value);
    }
  };

  return (
    <div className="dashboard-container">
      <div className="dashboard-title-section btn-link">
        <div className="dashboard-title back-link">
          <img src={backIcon} alt="back-icon" onClick={handleBack} />

          <h2>Add Notification</h2>
        </div>
        <div className="dashboard-btn">
          <button
            className="btn btn-primary"
            disabled={disabled}
            onClick={handleSubmit}
          >
            {submit ? "Loading..." : "Send"}
          </button>
        </div>
      </div>
      <div className="formItem add-details-info">
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <h3 className="required">Title</h3>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={8}>
            <TextField
              fullWidth
              name="title"
              value={formValues.title || ""}
              onChange={handleChange}
              variant="outlined"
              label="Enter Title"
              error={Boolean(formErrors?.title)}
              helperText={formErrors?.title}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <h3 className="required">Description</h3>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={8}>
            <TextField
              fullWidth
              multiline
              rows={4}
              name="description"
              value={formValues.description || ""}
              onChange={handleChange}
              label="Write Description"
              error={Boolean(formErrors?.description)}
              helperText={formErrors?.description}
            />
          </Grid>
        </Grid>
      </div>
      <div className="radio-buttons-wrapper add-noti">
        <div className="radio-label">
          <h3 className="required">Recipient</h3>
        </div>
        <div className="radio-options">
          <FormControl>
            <RadioGroup
              name="recipient"
              value={formValues?.recipient}
              onChange={handleChange}
              style={{ flexDirection: "row" }}
            >
              <FormControlLabel
                className={`${formValues?.recipient === "1" ? "active" : ""}`}
                value="1"
                control={<Radio />}
                label="All Users"
              />&nbsp;
              {/* <FormControlLabel
                className={`${formValues?.recipient === "2" ? "active" : ""}`}
                value="2"
                control={<Radio />}
                label="iOS"
              />&nbsp;
              <FormControlLabel
                className={`${formValues?.recipient === "3" ? "active" : ""}`}
                value="3"
                control={<Radio />}
                label="Android"
              /> */}
              &nbsp;
              <FormControlLabel
                className={`${formValues?.recipient === "4" ? "active" : ""}`}
                value="4"
                control={<Radio />}
                label="Selected Users"
              />
            </RadioGroup>
          </FormControl>
        </div>
      </div>

      {formValues?.recipient === "4" && (
        <BrokerList
          query={query}
          page={page}
          limit={limit}
          totalPages={totalPages}
          totalRecords={totalRecords}
          handleQuery={handleQuery}
          handleClearQuery={handleClearQuery}
          handleClickOpen={handleClickOpen}
          users={users}
          loader={loader}
          loderExist={true}
          brokers={brokers}
          handleUserChange={handleUserChange}
          handleLimitChange={handleLimitChange}
          handlePageChange={handlePageChange}
        />
      )}

      <Dialog open={open}>
        <BrokerFilter
          handleFilter={handleFilter}
          company={company}
          status={status}
          address={address}
          handleClose={handleClose}
          handleClear={handleClear}
        />
      </Dialog>
    </div>
  );
}
