const REGEX = {
  EMAIL:
    /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  PASSWORD: /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,40}$/,
  NUMBER: /^[0-9\b]+$/,
  MOBILE: /^[?[(]?[0-9]{3}[)]?[-\s]?[0-9]{3}[-\s]?[0-9]{4,6}$/im,
  SPACE: /^\s/,
  CURRENCY: /(\d)(?=(\d{3})+(?!\d))/g,
  //COUPON: /^\S{5,}$/,
  COUPON: /^[a-zA-Z0-9]{5,}$/,
};

const ERROR = {
  INVALID_PASSWORD: "Invalid Password",
  INVALID_EMAIL: "Invalid Email",

  COMPARE_PASSWORD: "New password and confirm password doesnot match",
  PASSWORD:
    "Password must contain min 8 characters, at least one uppercase char, one digit and special character",
  EMAIL_REQUIRED: "Email required",
  PASSWORD_REQUIRED: "Password required",
  INVALID_MOBILE: "Invalid Mobile Number",
  INVALID_COUPON:
    "Coupon must contain min 5 characters, without any special character",
};

const CONFIG = {
  S3_URL: process.env.REACT_APP_S3_URL,
  GOOGLE_MAPS_API_KEY: process.env.REACT_APP_GOOGLE_KEY,
};

export { REGEX, ERROR, CONFIG };
